import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import timeConverter from 'components/utilities/timeConverter';

const UserDetailsHeader = ({ data }) => {
  return (
    <Card className="mb-2 p-3">
      <Row>
        <Col
          className="d-flex align-items-center justify-content-between"
          lg={12}
        >
          <Card.Header className="fw-bold w-100 fs-2">
            {data?.name} (
            {
              <a className="text-linkedin" href={`mailto:${data?.email}`}>
                {data?.email}
              </a>
            }
            )
          </Card.Header>
        </Col>
        <Col
          lg={12}
          className="mt-3 d-flex border-top align-items-center justify-content-between p-3"
        >
          <Col className="d-flex align-items-center">
            <Col
              lg={1}
              className="d-flex align-items-center justify-content-center"
            >
              {data?.status === 'confirmed' ? (
                <div
                  style={{
                    bottom: '65px',
                    margin: 0,
                    height: '10px',
                    width: '10px',
                    backgroundColor: 'rgb(55, 126, 34)',
                    borderRadius: '50%'
                  }}
                />
              ) : (
                <div
                  style={{
                    margin: 0,
                    height: '10px',
                    width: '10px',
                    backgroundColor: 'rgb(159, 169, 185)',
                    borderRadius: '50%'
                  }}
                />
              )}
            </Col>
            <Col>
              <Card.Text>Usuario activo</Card.Text>
              <Card.Subtitle>{timeConverter(data?.createdAt)}</Card.Subtitle>
            </Col>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

UserDetailsHeader.propTypes = {
  data: PropTypes.object
};

export default UserDetailsHeader;
