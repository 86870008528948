import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'react-bootstrap';
import useHistory from 'hooks/useHistory';

const Breadcrumbs = () => {
  const { currentPath, previousPath } = useHistory();

  const pathName = path => {
    let newPath = '';

    if (path?.includes('/partners-list')) {
      newPath = 'Lista de centros';
    } else if (path !== null && path?.includes('/billing-list')) {
      newPath = 'Grupos de facturación';
    } else if (path !== null && path?.includes('/containers-list')) {
      newPath = 'Lista de contenedores';
    } else if (path !== null && path?.includes('/partner-creation')) {
      newPath = 'Creación de centro';
    } else if (path !== null && path?.includes('/partner-details')) {
      newPath = 'Detalles del centro';
    } else if (path !== null && path === '/billing-group/new') {
      newPath = 'Creación de grupo de facturación';
    } else if (path !== null && path?.includes('/user-details')) {
      newPath = 'Detalles de usuario';
    } else if (path !== null && path?.includes('/billing-group')) {
      newPath = 'Detalles de grupo de facturación';
    } else if (path !== null && path?.includes('/container-creation')) {
      newPath = 'Creación de contenedor';
    } else if (path !== null && path?.includes('/container-details')) {
      newPath = 'Detalles de contenedor';
    } else if (path !== null && path?.includes('/product-groups')) {
      newPath = 'Grupos de productos';
    } else if (path !== null && path?.includes('/users-list')) {
      newPath = 'Lista de usuarios';
    } else if (path !== null && path?.includes('/edit-partner')) {
      newPath = 'Editar centro';
    } else if (path !== null && path?.includes('/product-types')) {
      newPath = 'Tipos de productos';
    } else if (path !== null && path === 'subscriptions') {
      newPath = 'Suscripciones';
    } else if (path !== null && path === 'prices') {
      newPath = 'Tarifas';
    } else if (path !== null && path === 'new-price') {
      newPath = 'Nueva tarifa';
    } else if (path !== null && path?.includes('/add-subscription')) {
      newPath = 'Nueva suscripción';
    } else if (path !== null && path?.includes('/stock-list')) {
      newPath = 'Inventarios';
    } else {
      newPath = 'Otro';
    }

    return newPath;
  };

  return (
    <Breadcrumb>
      <Breadcrumb.Item
        key="root"
        href="/partners-list/1"
        active={currentPath === '/partners-list/1'}
      >
        Home
      </Breadcrumb.Item>
      {previousPath && !previousPath.includes('/partners-list') && (
        <Breadcrumb.Item key={`previous-${previousPath}`} href={previousPath}>
          {pathName(previousPath)}
        </Breadcrumb.Item>
      )}
      {currentPath !== '/partners-list/1' && (
        <Breadcrumb.Item
          key={`current-${currentPath}`}
          href={currentPath}
          active={true}
        >
          {pathName(currentPath)}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};

Breadcrumbs.propTypes = {
  items: PropTypes.array
};

export default Breadcrumbs;
