import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Dropdown, Modal, Spinner } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PartnersTableHeader from '../components/partners-table/PartnersTable';
import useError from 'hooks/useError';
import {
  useGetSubscriptionsQuery,
  useUpdateSubscriptionByIdMutation
} from 'redux/endpoints/paymentApiV2';
import timeConverter from 'components/utilities/timeConverter';
import { subscriptionStatusBadge } from 'components/utilities/badgeSelector';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from 'components/pagination/Pagination';

export const columns = setShowModal => {
  return [
    {
      accessor: 'name',
      Header: 'Nombre del centro',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { user, userId } = rowData.row.original;
        return (
          <Link to={`/billing-group/${userId}`}>
            <Flex alignItems="center">
              <div className="flex-1">
                <h5 className="mb-0 fs--1">{user.name}</h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'price',
      Header: 'Tarifa',
      Cell: rowData => {
        const { price } = rowData.row.original;
        return (
          <Flex>
            <Card.Text>{price.name}</Card.Text>
          </Flex>
        );
      }
    },
    {
      accessor: 'productGroup',
      Header: 'Grupo de producto',
      Cell: rowData => {
        const { productGroup } = rowData.row.original;
        return <Flex>{productGroup.name}</Flex>;
      }
    },
    {
      accessor: 'startDate',
      Header: 'Fecha de inicio',
      Cell: rowData => {
        const { startDate } = rowData.row.original;
        return <Flex>{timeConverter(startDate)}</Flex>;
      }
    },
    {
      accessor: 'status',
      Header: 'Estado',
      Cell: rowData => {
        const { status } = rowData.row.original;
        return <Flex>{subscriptionStatusBadge(status)}</Flex>;
      }
    },

    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id, status } = rowData.row.original;

        if (status === 'cancelled') {
          return;
        }

        return (
          <CardDropdown iconClassName="fs--1">
            <div className="py-2">
              <Dropdown.Item href={`/edit-subscription/${id}`}>
                Editar
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger"
                onClick={() => setShowModal({ status: true, id: id })}
              >
                Cancelar suscripción
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];
};

const SubscriptionList = () => {
  const { checkError } = useError();
  const params = useParams();
  const [showModal, setShowModal] = useState({ status: false, id: null });
  const [subStatus, setSubStatus] = useState('active');
  const query = window.location.search;
  const queryValue = query.replace('?query=', '');
  const finalQuery = queryValue.split('%20').join(' ');
  const { data, error, refetch } = useGetSubscriptionsQuery(
    {
      value: finalQuery,
      status: subStatus,
      limit: 25,
      from: (params.page - 1) * 25
    },
    {
      refetchOnMountOrArgChange: true
    }
  );
  const [
    updateSubscription,
    {
      data: updateSubscriptionData,
      isLoading: updateLoading,
      error: updateSubscriptionError
    }
  ] = useUpdateSubscriptionByIdMutation();

  useEffect(() => {
    if (updateSubscriptionError) {
      checkError(updateSubscriptionError);
    }
  }, [updateSubscriptionError]);

  useEffect(() => {
    if (updateSubscriptionData && !updateLoading) {
      toast.success('Suscripción cancelada');
      setShowModal({ status: false });
      refetch();
    }
  }, [updateSubscriptionData]);

  if (error) {
    checkError(error);
  }

  function cancelSubscription(id) {
    updateSubscription({ id, status: 'cancelled' });
  }

  return (
    <>
      <AdvanceTableWrapper
        columns={columns(setShowModal)}
        data={(data && data?.data) || []}
        pagination
        perPage={25}
      >
        <Card className="mb-3">
          <Card.Header>
            <PartnersTableHeader table />
          </Card.Header>
          <Card.Body className="p-0">
            <Col
              lg={12}
              className="d-flex justify-content-end align-items-center pe-4 pb-2"
            >
              <Link
                onClick={() =>
                  subStatus === 'active'
                    ? setSubStatus('cancelled')
                    : setSubStatus('active')
                }
              >
                {subStatus === 'active'
                  ? 'Ver suscripciones canceladas'
                  : 'Ver suscripciones activas'}
              </Link>
            </Col>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Pagination data={data} />
        </Card>
      </AdvanceTableWrapper>
      <Modal show={showModal.status}>
        <Modal.Header>
          <Modal.Title>Cancelar suscripción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {updateLoading && (
            <>
              <p>Cancelando suscripción...</p>
              <Spinner animation="border" variant="primary" />
            </>
          )}
          <p>
            <strong>¡Esta acción no se puede deshacer!</strong>
          </p>
          <p>¿Está seguro que desea cancelar la suscripción?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal({ status: false })}
          >
            Cerrar
          </Button>
          <Button
            onClick={() => cancelSubscription(showModal.id)}
            variant="primary"
          >
            Cancelar suscripción
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionList;
