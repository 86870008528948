import PartnersTableHeader from 'components/partners-table/PartnersTable';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import timeConverter from 'components/utilities/timeConverter';
import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import useError from 'hooks/useError';
import { useDispatch } from 'react-redux';
import { setBillingGroups } from 'redux/reducers/billingGroupsReducer';
import Pagination from 'components/pagination/Pagination';
import { useGetStockReportListQuery } from 'redux/endpoints/stockReportsApi';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre del centro',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { partner, id } = rowData.row.original;
      return (
        <Link to={`/stock-report/${id}`}>
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{partner.name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'scanned',
    Header: 'Scan VS total',
    Cell: rowData => {
      const { scannedContainers, missingContainers } = rowData.row.original;
      return (
        <Flex>
          <Card.Text>{`${scannedContainers.length}/${
            scannedContainers.length + missingContainers.length
          }`}</Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'lost',
    Header: 'Faltantes (lost)',
    Cell: rowData => {
      const { missingContainers } = rowData.row.original;
      return <Flex>{missingContainers.length}</Flex>;
    }
  },

  {
    accessor: 'createdAt',
    Header: 'Fecha de inventario',
    Cell: rowData => {
      const { createdAt } = rowData.row.original;
      return <Flex>{timeConverter(createdAt)}</Flex>;
    }
  }

  /*   {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href={`/edit-billing/${id}`}>Editar</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  } */
];

const StockReportList = () => {
  const params = useParams();
  const query = window.location.search;
  const queryValue = query.includes('?query=')
    ? query.replace('?query=', '')
    : query.replace('?createdAt=', '');
  const finalQuery = queryValue.split('%20').join(' ');
  const { data, isLoading, error } = useGetStockReportListQuery(
    { from: (params.page - 1) * 10, value: finalQuery },
    { refetchOnMountOrArgChange: true }
  );
  const { checkError } = useError();
  const dispatch = useDispatch();
  if (error) checkError(error);
  if (data) {
    dispatch(setBillingGroups(data?.data));
  }
  return (
    <Card style={{ height: '100%' }}>
      <Card.Header className="border-bottom">
        <PartnersTableHeader table />
      </Card.Header>
      {isLoading ? (
        <Card.Body className="d-flex justify-content-center">
          <Spinner className="align-self-center" />
        </Card.Body>
      ) : (
        <Card.Body className="p-0 pb-3">
          <AdvanceTableWrapper
            columns={columns}
            data={data?.data || []}
            pagination
            perPage={25}
          >
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <Pagination data={data} />
          </AdvanceTableWrapper>
        </Card.Body>
      )}
    </Card>
  );
};

export default StockReportList;
