import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import FalconCloseButton from './FalconCloseButton';
import PropTypes from 'prop-types';

const CustomModal = ({
  setter,
  showModal,
  transferAction,
  userData,
  selectedUser,
  containers
}) => {
  const handleSubmit = () => {
    let type;
    if (userData.data) {
      if (Array.isArray(userData.data[0].type)) {
        type = userData.data[0].type[0];
      } else {
        type = userData.data[0].type;
      }
    } else {
      type = userData.type;
    }

    transferAction(type);
  };

  const handleClose = () => setter(false);

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            Transferir{' '}
            {containers.length > 1
              ? `${containers.length} envases`
              : `${containers.length} envase`}
          </Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que quieres transferir{' '}
          {containers.length > 1
            ? 'los envases'
            : `el envase "${containers[0]?.name}" `}{' '}
          de “{userData?.data ? userData?.data[0].name : userData?.name}” a "
          {selectedUser.name}"?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Transferir
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

CustomModal.propTypes = {
  setter: PropTypes.func,
  showModal: PropTypes.bool,
  transferAction: PropTypes.func,
  userData: PropTypes.object,
  selectedUser: PropTypes.object,
  containers: PropTypes.array
};

export default CustomModal;
