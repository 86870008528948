import React from 'react';
import Flex from 'components/common/Flex';
import ForgotPasswordForm from 'components/authentication/ForgotPasswordForm';
import { t } from 'i18next';

const ForgotPassword = () => (
  <>
    <Flex justifyContent="between" alignItems="center" className="mb-2">
      <h5>{t('forgotPassword')}</h5>
    </Flex>
    <ForgotPasswordForm />
  </>
);

export default ForgotPassword;
