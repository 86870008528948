import React from 'react';
import { Card } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PartnersTableHeader from '../components/partners-table/PartnersTable';
import { useGetContainerListQuery } from 'redux/endpoints/containersApi';
import { useParams } from 'react-router-dom';
import useError from 'hooks/useError';
import { columns } from 'components/containers-list/columns';
import Pagination from 'components/pagination/Pagination';

const BumerangsList = () => {
  const params = useParams();
  const { checkError } = useError();
  const query = window.location.search;
  const queryValue = query.includes('?query')
    ? query.replace('?query=', '')
    : query.replace('?type=', '');
  const finalQuery = queryValue.split('%20').join(' ');
  const { data, error } = useGetContainerListQuery(
    {
      from: (params.page - 1) * 20,
      value: finalQuery,
      limit: 20,
      field: query.includes('?query') ? 'name' : 'productTypeId',
      operator: query.includes('?query') ? 'icontains' : '='
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  if (error) {
    checkError(error);
  }
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={data?.data || []}
      pagination
      perPage={50}
    >
      <Card className="mb-3 border">
        <Card.Header>
          <PartnersTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Pagination data={data} />
      </Card>
    </AdvanceTableWrapper>
  );
};

export default BumerangsList;
