import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

const Pagination = ({ data }) => {
  const navigation = useNavigate();
  const params = useParams();
  const query = window.location.search;
  const path = window.location.pathname;
  const dynamicPath = path.substring(0, path.lastIndexOf('/'));

  return (
    <Card.Footer>
      <Row className="d-flex justify-content-center">
        <Col lg={12} className="d-flex justify-content-center">
          <Button
            disabled={Number(params.page) - 1 <= 0 ? true : false}
            size="sm"
            variant="falcon-default"
            className="button-previous me-3"
            onClick={() => {
              navigation(
                query
                  ? `${dynamicPath}/${Number(params.page) - 1}${query}`
                  : `${dynamicPath}/${Number(params.page - 1)}`
              );
            }}
          >
            {Number(params.page) > 1 ? Number(params.page) - 1 : 0}
          </Button>

          <Button
            size="sm"
            variant="falcon-default"
            className="button-center mx-2"
            disabled={true}
          >
            {Number(params.page)}
          </Button>
          <Button
            size="sm"
            disabled={
              data?.pagination.total <=
              data?.pagination.offset + data?.pagination.limit
            }
            variant="falcon-default"
            className="button-next ms-3"
            onClick={() => {
              navigation(
                query
                  ? `${dynamicPath}/${Number(params.page) + 1}${query}`
                  : `${dynamicPath}/${Number(params.page) + 1}`
              );
            }}
          >
            {Number(params.page) + 1}
          </Button>
        </Col>
      </Row>
    </Card.Footer>
  );
};

Pagination.propTypes = {
  data: PropTypes.object
};

export default Pagination;
