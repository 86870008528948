import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { aggregateDataBylentAt, getColor, rgbaColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LegendComponent,
  CanvasRenderer
]);

const tooltipFormatter = params => `
    <div>
      <p class='mb-2 text-600'>
      ${
        dayjs(params[0].axisValue).isValid()
          ? dayjs(params[0].axisValue).format('MMMM YYYY')
          : params[0].axisValue
      }
      </p>
      ${params
        .map(
          ({ seriesName, value, borderColor }) =>
            `<div class="dot d-inline-block" style="background-color: ${borderColor}"></div>
            <span class='text-600'>
              ${seriesName} : <strong>${value}</strong>
            </span>`
        )
        .join('<br />')}
    </div>`;

const getOptions = data => ({
  color: getColor('gray-100'),
  legend: {
    show: false
  },
  xAxis: {
    type: 'category',
    data: aggregateDataBylentAt(data.items).map(item => item.lentAt),

    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      color: getColor('gray-600'),
      formatter: value => {
        const date = new Date(value);
        return `${dayjs(date).format('MMM')}`;
      },
      interval: 2
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: getColor('gray-200')
      }
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    axisPointer: {
      type: 'none'
    },
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter(params) {
      return tooltipFormatter(params);
    }
  },
  series: [
    {
      name: 'Vasos',
      type: 'bar',
      barWidth: '50%',
      data: aggregateDataBylentAt(data.items).map(item => item.cupLentCount),
      itemStyle: {
        color: '#E6EFFC',
        borderRadius: [2, 2, 0, 0]
      },
      emphasis: {
        itemStyle: {
          color: rgbaColor(getColor('info'), 0.15),
          borderRadius: [2, 2, 0, 0],
          borderWidth: 1,
          borderColor: getColor('gray-300')
        }
      }
    },
    {
      name: 'Boles',
      type: 'bar',
      barWidth: '50%',
      z: -1,
      barGap: '-100%',
      data: aggregateDataBylentAt(data.items).map(item => item.bowlsLentCount),
      itemStyle: {
        borderRadius: [2, 2, 0, 0],
        color: '#61A27B'
      }
    }
  ],
  grid: { right: '3%', left: '5%', bottom: '10%', top: '5%' }
});

const LendProductChart = ({ data }) => {
  const chartRef = useRef(null);
  const handleLegendToggle = (event, name) => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
    event.target.closest('button').classList.toggle('opacity-50');
  };

  return (
    <Card className="mt-3">
      <Card.Header className="bg-light">
        <h6 className="mb-0">Progresión envases evitados por mes y producto</h6>
      </Card.Header>
      <Card.Body>
        <ReactEChartsCore
          ref={chartRef}
          echarts={echarts}
          option={getOptions(data)}
          style={{ height: '300px' }}
        />
      </Card.Body>
      <Card.Footer className="bg-light py-2">
        <Row className="g-0 flex-between-center">
          <Col xs="auto">
            <Flex className="flex-wrap gap-md-2">
              <Button
                variant="text"
                size="sm"
                className="d-flex align-items-center p-0 shadow-none"
                onClick={event => handleLegendToggle(event, 'Vasos')}
              >
                <FontAwesomeIcon
                  icon="circle"
                  className="fs--2 me-1"
                  style={{ color: '#E6EFFC' }}
                  transform="shrink-3"
                />
                <small className="text-600">Vaso</small>
              </Button>
              <Button
                variant="text"
                size="sm"
                className="d-flex align-items-center p-0 shadow-none ms-2"
                onClick={event => handleLegendToggle(event, 'Boles')}
              >
                <FontAwesomeIcon
                  icon="circle"
                  className="fs--2 me-1"
                  style={{ color: '#61A27B' }}
                  transform="shrink-3"
                />
                <small className="text-600">Bol</small>
              </Button>
            </Flex>
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};

LendProductChart.propTypes = {
  data: PropTypes.shape({
    onSaleCourse: PropTypes.array,
    regularPaidCourse: PropTypes.array
  })
};

export default LendProductChart;
