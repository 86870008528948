import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  user: null,
  lendingContainers: [],
  collectingContainers: [],
  isEditing: false,
  isScanningUser: false,
  isCollectOnly: false,
  isManualCode: false
};

const scanSummaryReducer = createSlice({
  name: 'scanSummary',
  initialState,
  reducers: {
    changeSummary: (state, action) => {
      state.user = action.payload.user;
      state.lendingContainers = action.payload.lendingContainers;
      state.collectingContainers = action.payload.collectingContainers;
      state.isEditing = action.payload.isEditing;
      state.isScanningUser = action.payload.isScanningUser;
      state.isCollectOnly = action.payload.isCollectOnly;
      state.isManualCode = action.payload.isManualCode;
    },
    resetSummary: state => {
      state.user = initialState.user;
      state.lendingContainers = initialState.lendingContainers;
      state.collectingContainers = initialState.collectingContainers;
      state.isEditing = initialState.isEditing;
      state.isScanningUser = initialState.isScanningUser;
      state.isCollectOnly = initialState.isCollectOnly;
      state.isManualCode = initialState.isManualCode;
    }
  }
});

export const { changeSummary, resetSummary } = scanSummaryReducer.actions;

export default scanSummaryReducer.reducer;
