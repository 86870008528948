import useError from 'hooks/useError';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetPlansMutation,
  useGetProductsQuery,
  useGetSubscriptionByIdQuery,
  useUpdateSubscriptionByIdMutation
} from 'redux/endpoints/paymentApiV2';

const UpdateSubscription = () => {
  const navigate = useNavigate();
  const { checkError } = useError();
  const params = useParams();
  const [subscription, setSubscription] = useState({
    plan: '',
    priceId: '',
    productGroupId: '',
    interval: 'monthly'
  });

  const { data, error } = useGetSubscriptionByIdQuery(params.id);

  const [getPlans, { data: plansData, error: plansError }] =
    useGetPlansMutation();
  const { data: productsData, error: productsError } = useGetProductsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [
    updateSubscription,
    { data: subscriptionData, error: subscriptionError }
  ] = useUpdateSubscriptionByIdMutation();

  if (error) {
    checkError(error);
  }

  if (productsError) {
    checkError(productsError);
  }

  useEffect(() => {
    if (plansError) {
      <checkError></checkError>;
    }
  }, [plansError]);

  useEffect(() => {
    if (subscriptionData) {
      toast.success('Suscripción creada correctamente');
      navigate(-1);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionError) {
      checkError(subscriptionError);
    }
  }, [subscriptionError]);

  const handleSubmit = () => {
    updateSubscription({
      id: params.id,
      productGroupId: subscription.products,
      priceId: subscription.tarifa,
      userId: params.id,
      interval: subscription.interval,
      startPeriodDate: subscription.startPeriodDate,
      endDate: subscription.endDate
    });
  };

  return (
    <Card className="p-5">
      <Row>
        <Col lg={12}>
          <Card.Title>
            Editar suscripción "{data?.price.name}" de {data?.user.name} (
            <Link className="text-linkedin">{data?.user.email}</Link>)
          </Card.Title>
        </Col>
        <Col lg={12} className="mt-4">
          <Form.Group controlId="productGroupTypeList" className="mb-3">
            <Form.Label className="me-5">Tipo de Producto</Form.Label>
            <Form.Select
              onChange={event => {
                getPlans({ value: event.target.value });
                setSubscription({
                  ...subscription,
                  products: event.target.value
                });
              }}
              size="sm"
            >
              <option>Selecciona una opción</option>
              {productsData?.map(element => (
                <option key={element.id} value={element.id}>
                  {element.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="mt-4">
          <Form.Group controlId="planType" className="mb-3">
            <Form.Label className="me-5">Tipo de suscripción</Form.Label>
            <Form.Select
              onChange={event => {
                getPlans({
                  value: subscription.products,
                  type: event.target.value
                });
                setSubscription({
                  ...subscription,
                  plan: event.target.value
                });
              }}
              size="sm"
            >
              <option>Selecciona una opción</option>
              <option value="volume">Volume</option>
              <option value="graduated">Graduated</option>
              <option value="fixed">Fixed</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="mt-4">
          <Form.Group controlId="priceType" className="mb-3">
            <Form.Label className="me-5">Tipo de tarifa</Form.Label>
            <Form.Select
              onChange={event => {
                setSubscription({
                  ...subscription,
                  tarifa: event.target.value
                });
              }}
              size="sm"
            >
              <option>Selecciona una opción</option>
              {plansData?.map(element => (
                <option key={element.id} value={element.id}>
                  {element.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="mt-4">
          <Form.Group controlId="interval" className="mb-3">
            <Form.Label className="me-5">Intervalo de facturación</Form.Label>
            <Form.Select
              onChange={event => {
                setSubscription({
                  ...subscription,
                  interval: event.target.value
                });
              }}
              size="sm"
            >
              <option>Selecciona una opción</option>
              <option value="monthly">Mensual</option>
              <option value="yearly">Anual</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col className="d-flex l justify-content-between mb-4">
          <Form.Group style={{ width: '200px' }}>
            <Col>
              <label className="form-label" htmlFor="datepicker">
                Fecha de inicio de periodo
              </label>
              <input
                onChange={event => {
                  setSubscription({
                    ...subscription,
                    startPeriodDate: event.target.value
                  });
                }}
                className="form-control datetimepicker"
                id="datepicker"
                type="date"
                placeholder="d/m/y"
                data-options='{"disableMobile":true}'
              />
            </Col>
          </Form.Group>
          <Form.Group style={{ width: '200px' }}>
            <Col>
              <label className="form-label" htmlFor="datepicker">
                Finalización
              </label>
              <input
                onChange={event => {
                  setSubscription({
                    ...subscription,
                    endDate: event.target.value
                  });
                }}
                className="form-control datetimepicker"
                id="datepicker"
                type="date"
                placeholder="d/m/y"
                data-options='{"disableMobile":true}'
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Col>
        <Button
          disabled={
            subscription.plan === '' ||
            subscription.tarifa === '' ||
            subscription.products === ''
          }
          className="me-4"
          variant="primary"
          onClick={handleSubmit}
        >
          Guardar
        </Button>
      </Col>
    </Card>
  );
};

export default UpdateSubscription;
