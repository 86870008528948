import React, { useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { exactTimeConverter } from 'components/utilities/timeConverter';
import { Link } from 'react-router-dom';
import { svgSelectorDetailed } from 'components/utilities/svgSelector';
import PropTypes from 'prop-types';

const DetailsMovements = ({ movementData, setOffset }) => {
  const [showSpan1, setShowSpan1] = useState(null);

  const handleClick = object => {
    setShowSpan1(object);
    showSpan1 !== null && setShowSpan1(null);
  };
  return (
    <Card className="mb-3 p-3">
      <Card.Header>Logs</Card.Header>
      <Card.Body className="p-0">
        {movementData?.data?.map((element, index) => {
          return (
            <Col key={index}>
              <Col
                onClick={() => handleClick(element)}
                lg={12}
                className="border-bottom d-flex justify-content-between"
              >
                <Link>
                  <Card.Text className="my-0 mx-3">
                    {element.status}{' '}
                    {`${element.containersByProductType.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.count,
                      0
                    )}_uds`}
                  </Card.Text>
                </Link>{' '}
                <Card.Text className="my-0 mx-3">
                  {exactTimeConverter(element.createdAt)}
                </Card.Text>
              </Col>
              {showSpan1 && showSpan1?.requestId === element.requestId && (
                <span className="ms-4" key={index}>
                  <Col lg={12} className>
                    {element.containersByProductType.map((movement, index) => {
                      return (
                        <Card.Text
                          className="border-bottom fw-bold m-3"
                          key={index}
                        >
                          {svgSelectorDetailed(movement.code)} {movement.code}:{' '}
                          {movement.count}
                        </Card.Text>
                      );
                    })}
                  </Col>
                </span>
              )}
            </Col>
          );
        })}
      </Card.Body>
      <Card.Footer className="d-flex flex-wrap justify-content-center">
        <Col lg={12} className="d-flex justify-content-center">
          <Card.Text className="fs-0">
            {movementData?.pagination.length + movementData?.pagination.offset}{' '}
            de {movementData?.pagination.total} movimientos
          </Card.Text>
        </Col>
        <Card.Link
          style={{ cursor: 'pointer' }}
          className="text-linkedin"
          onClick={() =>
            setOffset(
              movementData?.pagination.offset - movementData?.pagination.limit
            )
          }
        >
          Cargar anteriores
        </Card.Link>
        <Card.Link
          style={{ cursor: 'pointer' }}
          className="text-linkedin"
          onClick={() =>
            setOffset(
              movementData?.pagination.offset + movementData?.pagination.limit
            )
          }
        >
          Cargar siguientes
        </Card.Link>
      </Card.Footer>
    </Card>
  );
};

DetailsMovements.propTypes = {
  movementData: PropTypes.object,
  setOffset: PropTypes.func
};

export default DetailsMovements;
