import { faBowlRice } from '@fortawesome/free-solid-svg-icons';

export const dashboardRoutes = {
  label: 'Partners',
  labelDisable: true,
  children: [
    {
      name: 'Clientes',
      active: true,
      icon: 'shapes',
      children: [
        {
          name: 'Lista de grupos',
          active: true,
          exact: true,
          to: '/billing-list/1'
        },
        {
          name: 'Lista de centros',
          active: true,
          exact: true,
          to: '/partners-list/1'
        }
      ]
    },
    {
      name: 'Usuarios App',
      active: true,
      icon: 'user',
      to: '/users-list/1'
    },
    {
      name: 'Productos',
      active: true,
      icon: faBowlRice,
      children: [
        {
          name: 'Envases',
          active: true,
          exact: true,
          to: '/containers-list/1'
        },
        {
          name: 'Crear',
          active: true,
          exact: true,
          to: '/containers-creation'
        },
        {
          name: 'Product groups',
          active: true,
          exact: true,
          to: '/product-groups'
        },
        {
          name: 'Product types',
          active: true,
          exact: true,
          to: '/product-types'
        }
      ]
    },
    {
      name: 'Operaciones',
      active: true,
      icon: 'fa-square-poll-vertical',
      children: [
        {
          name: 'Asignar envases',
          active: true,

          to: '/assign-containers'
        },
        {
          name: 'Inventarios',
          active: true,
          exact: true,
          to: '/stock-list/1'
        }
      ]
    },
    {
      name: 'Ventas',
      active: true,
      icon: 'tags',
      children: [
        {
          name: 'Lista de suscripciones',
          active: true,
          exact: true,
          to: '/subscriptions/1'
        },
        {
          name: 'Lista de tarifas',
          to: '/prices',
          active: true,
          exact: true
        },
        {
          name: 'Crear tarifas',
          active: true,
          exact: true,
          to: '/new-price'
        }
      ]
    },
    {
      name: 'Facturación',
      active: true,
      icon: 'file-lines'
      /*       children: [
        {
          name: 'Lista de grupos',
          active: true,
          exact: true,
          to: '/billing-list'
        }
      ] */
    }
  ]
};

export default [dashboardRoutes];
