import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  billingGroups: []
};

const billingGroupsReducer = createSlice({
  name: 'billingGroups',
  initialState,
  reducers: {
    setBillingGroups: (state, action) => {
      state.billingGroups = action.payload;
    },
    clearBillingGroups: state => {
      state.billingGroups = [];
    }
  }
});

export const { setBillingGroups, clearBillingGroups } =
  billingGroupsReducer.actions;
export default billingGroupsReducer.reducer;
