import React from 'react';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';

const rootElement = document.getElementById('main');
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Main>
        <App />
      </Main>
    </PersistGate>
  </Provider>
);
