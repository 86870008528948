import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import FalconCloseButton from 'components/common/FalconCloseButton';

const ProductModal = ({
  setter,
  showModal,
  createProductGroup,
  createProductType,
  productGroups
}) => {
  const location = window.location.pathname;
  const handleClose = () => setter(false);
  const [formData, setFormData] = useState(
    location.includes('/product-groups')
      ? {
          id: uuidv4(),
          name: '',
          code: ''
        }
      : {
          id: uuidv4(),
          name: '',
          code: '',
          groupId: '',
          price: 0,
          currency: 'EUR',
          footprint: 0,
          size: '',
          gtin: ''
        }
  );

  const handleSubmit = event => {
    event.preventDefault();
    if (location.includes('/product-groups')) {
      createProductGroup(formData);
    } else {
      createProductType(formData);
    }
  };

  const handleChange = event => {
    if (event.target.name === 'footprint' || event.target.name === 'price') {
      setFormData({
        ...formData,
        [event.target.name]: parseInt(event.target.value)
      });
      return;
    }
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            {location.includes('/product-groups')
              ? 'Crear nuevo grupo de producto'
              : 'Crear nuevo tipo de producto'}
          </Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {location.includes('/product-groups') ? (
            <Form>
              <Form.Group controlId="formGroupName">
                <Form.Label>Nombre del grupo</Form.Label>
                <Form.Control
                  name="name"
                  onChange={handleChange}
                  type="text"
                  placeholder="name"
                />
              </Form.Group>
              <Form.Group controlId="formGroupCode">
                <Form.Label>Código para el grupo</Form.Label>
                <Form.Control
                  name="code"
                  onChange={handleChange}
                  type="text"
                  placeholder="code"
                />
              </Form.Group>
            </Form>
          ) : (
            <Form>
              <Form.Group controlId="formTypeName">
                <Form.Label>Nombre del tipo</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  type="text"
                  name="name"
                  placeholder="Bowl 500 Green"
                />
              </Form.Group>
              <Form.Group controlId="formTypeCode">
                <Form.Label>Código para el tipo</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  type="text"
                  name="code"
                  placeholder="Bowl500Green"
                />
              </Form.Group>
              <Form.Group controlId="formTypeGtin">
                <Form.Label>GTIN</Form.Label>
                <Form.Control
                  minLength={13}
                  maxLength={13}
                  onChange={handleChange}
                  type="text"
                  name="gtin"
                />
              </Form.Group>
              <Form.Group controlId="formTypeGroupId">
                <Form.Label>Footprint</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  type="number"
                  name="footprint"
                  placeholder="30"
                />
              </Form.Group>
              <Form.Group controlId="productGroupTypeList" className="mb-3">
                <Form.Label className="me-5">Grupo de producto</Form.Label>
                <Form.Select
                  name="groupId"
                  onChange={e =>
                    setFormData({ ...formData, groupId: e.target.value })
                  }
                  style={{ width: '180px' }}
                  size="sm"
                >
                  {productGroups?.map((group, index) => (
                    <option key={index} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label>Precio</Form.Label>
                <Form.Control
                  name="price"
                  onChange={handleChange}
                  min={100}
                  step={100}
                  type="number"
                  placeholder="600"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Tamaño</Form.Label>
                <Form.Control
                  name="size"
                  onChange={handleChange}
                  type="text"
                  placeholder="500ml"
                />
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ProductModal.propTypes = {
  setter: PropTypes.func,
  showModal: PropTypes.bool,
  createProductGroup: PropTypes.func,
  createProductType: PropTypes.func,
  productGroups: PropTypes.array
};

export default ProductModal;
