import {
  useGetSubscriptionsQuery,
  useUpdateSubscriptionByIdMutation
} from 'redux/endpoints/paymentApiV2';
import useError from './useError';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const useSubscriptions = () => {
  function getIdFromDifferentPaths() {
    let id;

    if (window.location.pathname.includes('/billing-group/')) {
      id = window.location.pathname.replace('/billing-group/', '');
    } else if (window.location.pathname.includes('/add-subscription')) {
      id = window.location.pathname.replace('/add-subscription/', '');
    }
    return id;
  }

  const id = getIdFromDifferentPaths();
  const { checkError } = useError();
  const {
    data: subscriptions,
    error: subscriptionError,
    refetch
  } = useGetSubscriptionsQuery(
    { value: id, limit: 200 },
    { refetchOnMountOrArgChange: true }
  );
  const [
    updateSubscription,
    { data: updateSubscriptionData, isLoading, error: updateSubscriptionError }
  ] = useUpdateSubscriptionByIdMutation();

  useEffect(() => {
    if (updateSubscriptionData && !isLoading) {
      toast.success('Suscripción actualizada correctamente');
      refetch();
    }
  }, [updateSubscriptionData]);

  useEffect(() => {
    if (updateSubscriptionError) {
      checkError(updateSubscriptionError);
    }
  }, [subscriptionError]);

  if (subscriptionError) {
    console.log(subscriptionError);
    checkError(subscriptionError);
  }

  return { subscriptions, updateSubscription };
};

export default useSubscriptions;
