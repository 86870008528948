import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEditRestaurantMutation } from 'redux/endpoints/restaurantApi';
import useError from './useError';

const useEditPartner = id => {
  const navigation = useNavigate();
  const { checkError } = useError();
  const [editPartner, { data, isLoading, error }] = useEditRestaurantMutation();

  useEffect(() => {
    if (data && !isLoading) {
      toast.success('Partner editado correctamente');
      navigation(`/partner-details/${id}`);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (error) {
      checkError(error);
    }
  }, [error]);

  return { editPartner, data, error, isLoading };
};

export default useEditPartner;
