import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from 'env/env';

export const productTypesApi = createApi({
  reducerPath: 'productTypesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}productTypes`,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState().persistedReducer.user;
      headers.set('authorization', `Bearer ${accessToken}`);
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    getProductTypes: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: '',
          params: {
            select:
              'id,name,group,size,picture,code,footprint,price,groupId,gtin'
          }
        };
      },
      transformResponse: response => response.data
    }),
    createProductType: builder.mutation({
      query: body => {
        return {
          method: 'POST',
          url: '',
          body
        };
      },
      transformResponse: () => 'No data response'
    }),
    updateProductType: builder.mutation({
      query: ({ id, body }) => {
        return {
          method: 'PUT',
          url: `/${id}`,
          body
        };
      },
      transformResponse: () => 'No data response'
    })
  })
});

export const {
  useGetProductTypesQuery,
  useCreateProductTypeMutation,
  useUpdateProductTypeMutation
} = productTypesApi;
