import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';

const UpdateModal = ({
  setter,
  data,
  showModal,
  updateProductType,
  productGroups
}) => {
  const handleClose = () => setter(false);
  const initialFormData = {
    name: '',
    code: '',
    footprint: 0,
    groupId: '',
    price: 0,
    size: '',
    gtin: '',
    currency: 'EUR'
  };

  const [formData, setFormData] = useState(initialFormData);
  const handleSubmit = event => {
    event.preventDefault();
    updateProductType({ id: data?.id, body: formData });
  };

  const handleChange = event => {
    if (event.target.name === 'footprint' || event.target.name === 'price') {
      setFormData({
        ...formData,
        id: data?.id,
        [event.target.name]: parseInt(event.target.value)
      });
      return;
    }
    setFormData({
      ...formData,
      id: data?.id,
      [event.target.name]: event.target.value
    });
  };

  useEffect(() => {
    if (data) {
      setFormData({
        name: data?.name,
        code: data?.code,
        footprint: data?.footprint,
        id: data?.id,
        groupId: data?.groupId,
        price: data?.price,
        size: data?.size,
        gtin: data?.gtin,
        currency: 'EUR'
      });
    }
  }, [data]);

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Actualizar tipo de producto {data?.name}</Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTypeName">
              <Form.Label>'Nombre del tipo</Form.Label>
              <Form.Control
                onChange={handleChange}
                type="text"
                name="name"
                value={formData?.name}
                placeholder="Bowl 500 Green"
              />
            </Form.Group>
            <Form.Group controlId="formTypeCode">
              <Form.Label>Código para el tipo</Form.Label>
              <Form.Control
                onChange={handleChange}
                type="text"
                name="code"
                value={formData?.code}
                placeholder="Bowl500Green"
              />
            </Form.Group>
            <Form.Group controlId="formTypeGtin">
              <Form.Label>GTIN</Form.Label>
              <Form.Control
                minLength={13}
                maxLength={13}
                onChange={handleChange}
                type="text"
                name="gtin"
                value={formData?.gtin}
              />
            </Form.Group>
            <Form.Group controlId="formTypeGroupId">
              <Form.Label>Footprint</Form.Label>
              <Form.Control
                onChange={handleChange}
                type="number"
                name="footprint"
                value={formData?.footprint}
                placeholder="30"
              />
            </Form.Group>
            <Form.Group controlId="productGroupTypeList" className="mb-3">
              <Form.Label className="me-5">Grupo de producto</Form.Label>
              <Form.Select
                name="groupId"
                onChange={e =>
                  setFormData({ ...formData, groupId: e.target.value })
                }
                style={{ width: '180px' }}
                size="sm"
              >
                {productGroups?.map((group, index) => (
                  <option key={index} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Precio</Form.Label>
              <Form.Control
                name="price"
                onChange={handleChange}
                min={100}
                step={100}
                type="number"
                value={formData?.price}
                placeholder="600"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tamaño</Form.Label>
              <Form.Control
                name="size"
                onChange={handleChange}
                type="text"
                value={formData?.size}
                placeholder="500ml"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>

          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

UpdateModal.propTypes = {
  setter: PropTypes.func,
  data: PropTypes.object,
  showModal: PropTypes.bool,
  updateProductType: PropTypes.func,
  productGroups: PropTypes.array
};

export default UpdateModal;
