import SubscriptionDates from 'components/subscriptions/SubscriptionDates';
import useError from 'hooks/useError';
import useSubscriptions from 'hooks/useSubscriptions';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useAddSubscriptionMutation,
  useGetPlansMutation,
  useGetProductsQuery
} from 'redux/endpoints/paymentApiV2';
import {
  useGetUserQueryQuery,
  useGetUsersListQueryQuery
} from 'redux/endpoints/userApi';
import { v4 as uuidv4 } from 'uuid';

const AddSubscription = () => {
  const navigate = useNavigate();
  const { subscriptions: billingGroupSubs } = useSubscriptions();

  const { checkError } = useError();
  const params = useParams();
  const [query, setQuery] = useState('');
  const [partnerSelected, setPartnerSelected] = useState(undefined);
  const [inputValue, setInputValue] = useState('');
  const [subscription, setSubscription] = useState({
    plan: '',
    priceId: '',
    productGroupId: '',
    interval: 'monthly',
    userId: params?.id,
    startDate: '',
    startPeriodDate: '',
    endDate: ''
  });
  const { data, error } = useGetUserQueryQuery(params.id);
  const { data: partnersData, error: partnersError } =
    useGetUsersListQueryQuery(
      {
        value: query,
        type: 'partner',
        billingGroupId: params.id,
        limit: 200
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !query
      }
    );

  const [getPlans, { data: plansData, error: plansError }] =
    useGetPlansMutation();
  const { data: productsData, error: productsError } = useGetProductsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [
    addSubscription,
    { data: subscriptionData, error: subscriptionError }
  ] = useAddSubscriptionMutation();

  if (error) {
    checkError(error);
  }

  if (partnersError) {
    checkError(partnersError);
  }

  if (productsError) {
    checkError(productsError);
  }

  useEffect(() => {
    if (plansError) {
      checkError(plansError);
    }
  }, [plansError]);

  useEffect(() => {
    if (subscriptionData) {
      toast.success('Suscripción creada correctamente');
      navigate(-1);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionError) {
      checkError(subscriptionError);
    }
  }, [subscriptionError]);

  const handleDateChange = event => {
    const date = new Date(event.target.value);
    const dateFormatted = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
    setSubscription({
      ...subscription,
      startDate: dateFormatted,
      startPeriodDate: dateFormatted
    });
  };

  useEffect(() => {
    if (partnerSelected) {
      setSubscription({
        ...subscription,
        partnerId: partnerSelected?.id
      });
    }
  }, [partnerSelected]);

  const handleSubmit = () => {
    const priceToCompare = plansData?.find(
      element => element.id === subscription.priceId
    ); //current subscription to compare.
    //check if active subscription with same product group and type volume or graduated exists.

    const activeSubs = billingGroupSubs?.data
      ?.filter(filteredSub => filteredSub.status === 'active')
      .some(
        subToCheck =>
          subToCheck.productGroupId === priceToCompare.productGroupId &&
          priceToCompare.type !== 'fixed'
      );

    if (activeSubs) {
      return toast.error(
        `Ya existe una suscripción activa con el grupo de producto ${priceToCompare.productGroup.name}`
      );
    }
    //create new object without plans to use in addSubscription mutation.
    // eslint-disable-next-line no-unused-vars
    const { plans, ...parsedSub } = subscription;
    addSubscription({
      id: uuidv4(),
      ...parsedSub
    });
  };

  return (
    <Card className="p-5">
      <Row>
        <Col lg={12}>
          <Card.Title>
            Asignar suscripción a {data?.name} (
            <Link className="text-linkedin">{data?.email}</Link>)
          </Card.Title>
        </Col>
        <Col lg={12} className="mt-4">
          <Form.Group controlId="productGroupTypeList" className="mb-3">
            <Form.Label className="me-5">Tipo de Producto</Form.Label>
            <Form.Select
              onChange={event => {
                getPlans({ value: event.target.value });
                setSubscription({
                  ...subscription,
                  productGroupId: event.target.value
                });
              }}
              size="sm"
            >
              <option>Selecciona una opción</option>
              {productsData?.map(element => (
                <option key={element.id} value={element.id}>
                  {element.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="mt-4">
          <Form.Group controlId="planType" className="mb-3">
            <Form.Label className="me-5">Tipo de suscripción</Form.Label>
            <Form.Select
              onChange={event => {
                getPlans({
                  value: subscription.productGroupId,
                  type: event.target.value
                });
                setSubscription({
                  ...subscription,
                  plan: event.target.value
                });
              }}
              size="sm"
            >
              <option>Selecciona una opción</option>
              <option value="volume">Volume</option>
              <option value="graduated">Graduated</option>
              <option value="fixed">Fixed</option>
            </Form.Select>
          </Form.Group>
        </Col>
        {subscription.plan === 'fixed' && (
          <Col lg={12} className="mt-4">
            <Form.Group controlId="userId" className="mb-3">
              <Form.Label className="me-5">
                Selecciona partner a quien quieres vincular la suscripción
              </Form.Label>
              <Form.Control
                type="search"
                placeholder={
                  partnerSelected ? partnerSelected?.name : 'Buscar...'
                }
                value={inputValue}
                size="sm"
                aria-label="Search"
                className="rounded search-input ps-4"
                onChange={({ target }) => {
                  setQuery(target.value);
                  setInputValue(target.value);
                }}
              />
            </Form.Group>
            {partnerSelected && (
              <Form.Text className="text-success">
                Partner seleccionado: {partnerSelected?.name}
              </Form.Text>
            )}
            {partnersData && query !== '' && (
              <span>
                {partnersData?.data?.map(element => (
                  <div className="border-bottom" key={element?.id}>
                    <span
                      onClick={() => {
                        setPartnerSelected(element);
                        setQuery('');
                        setInputValue('');
                      }}
                    >
                      {element.name}
                    </span>
                    <br />
                  </div>
                ))}
              </span>
            )}
          </Col>
        )}
        <Col lg={12} className="mt-4">
          <Form.Group controlId="priceType" className="mb-3">
            <Form.Label className="me-5">Tipo de tarifa</Form.Label>
            <Form.Select
              onChange={event => {
                setSubscription({
                  ...subscription,
                  priceId: event.target.value
                });
              }}
              size="sm"
            >
              <>
                <option>Selecciona una opción</option>
                {plansData?.map(element => (
                  <option key={element.id} value={element.id}>
                    {element.name}
                  </option>
                ))}
              </>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} className="mt-4">
          <Form.Group controlId="interval" className="mb-3">
            <Form.Label className="me-5">Intervalo de facturación</Form.Label>
            <Form.Select
              defaultValue={subscription.plan === 'fixed' ? 'yearly' : ''}
              onChange={event => {
                setSubscription({
                  ...subscription,
                  interval: event.target.value
                });
              }}
              size="sm"
            >
              <>
                <option>Selecciona una opción</option>
                {subscription.plan !== 'fixed' && (
                  <option value="monthly">Mensual</option>
                )}
                <option value="yearly">Anual</option>
              </>
            </Form.Select>
          </Form.Group>
        </Col>
        <SubscriptionDates
          handleDateChange={handleDateChange}
          setSubscription={setSubscription}
          subscription={subscription}
          isSubscriptionFixed={subscription.plan === 'fixed' ? true : false}
        />
      </Row>
      <Col>
        <Button
          disabled={
            subscription.plan === '' ||
            subscription.priceId === '' ||
            subscription.products === '' ||
            subscription.startDate === '' ||
            subscription.interval === '' ||
            (subscription.plan === 'fixed' && partnerSelected === undefined)
          }
          className="me-4"
          variant="primary"
          onClick={handleSubmit}
        >
          Guardar
        </Button>
      </Col>
    </Card>
  );
};

export default AddSubscription;
