import Flex from 'components/common/Flex';
import {
  paymentStatusBadge,
  userBadgetType
} from 'components/utilities/badgeSelector';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const userColumn = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return (
        <Link to={`/user-details/${id}`}>
          <Flex alignItems="center">
            <div
              style={{ maxWidth: '10vw', overflow: 'hidden' }}
              className="flex-1"
            >
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: rowData => {
      const { email } = rowData.row.original;
      return (
        <Flex>
          <Card.Text style={{ maxWidth: '15vw', overflow: 'hidden' }}>
            {email}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'type',
    Header: 'Type',
    Cell: rowData => {
      const { type } = rowData.row.original;
      return (
        <Flex>
          <Card.Text style={{ maxWidth: '15vw', overflow: 'hidden' }}>
            {userBadgetType(type)}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'containersAssigned',
    Header: 'Envases',
    Cell: rowData => {
      const { containersAssigned } = rowData.row.original;
      return (
        <Flex>
          <Card.Text className="fs-0.5 fw-bolder" style={{ width: '10vw' }}>
            {containersAssigned}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'paymentStatus',
    Header: 'Payment Status',
    Cell: rowData => {
      const { paymentStatus } = rowData.row.original;
      return <Flex>{paymentStatusBadge(paymentStatus)}</Flex>;
    }
  }
];
