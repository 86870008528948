import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ENV } from 'env/env';

const App = () => {
  const stripePromise = loadStripe(ENV.PUBLIC_STRIPE_KEY);
  return (
    <Elements stripe={stripePromise}>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </Elements>
  );
};

export default App;
