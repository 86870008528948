import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomModal from 'components/common/CustomModal';
import Flex from 'components/common/Flex';
import { userBadgetType } from 'components/utilities/badgeSelector';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useGetSingleContainerQuery } from 'redux/endpoints/containersApi';
import {
  useAssignContainerMutation,
  useCollectContainerMutation,
  useLendContainerMutation,
  useRetrieveContainerMutation,
  useTransferContainersBetweenPartnersMutation
} from 'redux/endpoints/scanApi';
import {
  useGetUserMutation,
  useGetUsersListQueryQuery
} from 'redux/endpoints/userApi';
import useError from 'hooks/useError';

const ContainerTransfer = () => {
  const navigation = useNavigate();
  const id = window.location.pathname.replace('/container-transfer/', '');
  const { checkError } = useError();
  const [openModal, setOpenModal] = useState(false);
  const [type, setType] = useState(null);
  const [searchUser, setSearchUser] = useState('');
  const [userSelected, setUserSelected] = useState();
  const [usersOffset, setUsersOffset] = useState(0);
  const [showSpan, setShowSpan] = useState(false);
  const { data, error } = useGetSingleContainerQuery(id);
  const { data: partnersData, error: partnersError } =
    useGetUsersListQueryQuery({
      from: usersOffset,
      limit: 20,
      type: type,
      value: searchUser
    });
  const [getCurrentOwner, { data: currentOwner, error: singleUserError }] =
    useGetUserMutation();
  const [
    collectContainer,
    { data: collectData, isLoading: collectIsLoading, error: collectError }
  ] = useCollectContainerMutation();
  const [
    lendContainer,
    { data: lendData, isLoading: lendIsLoading, error: lendError }
  ] = useLendContainerMutation();
  const [
    assignContainer,
    { data: assignData, isLoading: assignIsLoading, error: assignError }
  ] = useAssignContainerMutation();
  const [
    retrieveContainer,
    { data: retrieveData, isLoading: retrieveIsLoading, error: retrieveError }
  ] = useRetrieveContainerMutation();
  const [
    transferContainer,
    { data: transferData, isLoading: transferIsLoading, error: transferError }
  ] = useTransferContainersBetweenPartnersMutation();
  const container = data?.data;
  const uuid = uuidv4();

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name, type } = rowData.row.original;
        const user = rowData.row.original;
        return (
          <Link href="#">
            <Flex
              alignItems="center"
              onClick={() => {
                if (
                  (currentOwner?.type === 'customer' ||
                    currentOwner?.type === 'admin') &&
                  type !== 'partner'
                ) {
                  setShowSpan(true);
                } else {
                  setUserSelected(user);
                  setShowSpan(false);
                }
              }}
            >
              <div className="flex-1">
                <h5 className="mb-0 fs--1">{name}</h5>
              </div>
            </Flex>
          </Link>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div
              style={{ maxWidth: '200px', overflow: 'hidden' }}
              className="flex-1"
            >
              <h5 className="mb-0 fs--1">{email}</h5>
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { type } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{userBadgetType(type)}</h5>
            </div>
          </Flex>
        );
      }
    }
  ];

  const caseSelector = userType => {
    if (userType === 'customer') {
      collectContainer({
        returnId: uuid,
        collectorId: userSelected?.id,
        containerId: container?.id
      });
    }
    if (userType === 'admin') {
      assignContainer({
        requestId: uuid,
        operatorId: currentOwner?.id,
        partnerId: userSelected?.id,
        containerId: container?.id,
        status: 'refill'
      });
    }

    if (userType === 'partner') {
      if (userSelected?.type === 'customer') {
        lendContainer({
          requestId: uuid,
          lenderId: currentOwner?.id,
          lendeeId: userSelected?.id,
          containerId: container?.id,
          inputMethod: 'api'
        });
      }
      if (userSelected?.type === 'admin') {
        retrieveContainer({
          requestId: uuid,
          operatorId: userSelected?.id,
          partnerId: currentOwner?.id,
          containerId: container?.id,
          status: 'retrieval'
        });
      }
      if (userSelected?.type === 'partner') {
        transferContainer({
          requestId: uuid,
          partnerToId: userSelected?.id,
          partnerFromId: currentOwner?.id,
          containerIdList: [container?.id]
        });
      }
    }
  };

  if (partnersError) {
    checkError(partnersError);
  }

  //Obtain single user data
  useEffect(() => {
    if (data) {
      getCurrentOwner(container?.currentOwnerId);
    }
  }, [data]);
  useEffect(() => {
    if (singleUserError) {
      checkError(singleUserError);
    }
  }, [singleUserError]);

  useEffect(() => {
    if (collectData || assignData || retrieveData || lendData || transferData) {
      toast.success('Envase transferido correctamente', {
        position: 'top-center'
      });
      if (
        !collectIsLoading ||
        !assignIsLoading ||
        !retrieveIsLoading ||
        !lendIsLoading ||
        !transferIsLoading
      ) {
        navigation(`/container-details/${container?.id}`);
      }
    }
  }, [
    collectData,
    assignData,
    retrieveData,
    lendData,
    lendIsLoading,
    collectIsLoading,
    retrieveIsLoading,
    assignIsLoading,
    transferData,
    transferIsLoading
  ]);

  useEffect(() => {
    if (collectError) {
      checkError(collectError);
    }
    if (assignError) {
      checkError(assignError);
    }
    if (retrieveError) {
      checkError(retrieveError);
    }
    if (lendError) {
      checkError(lendError);
    }
    if (transferError) {
      checkError(transferError);
    }
  }, [collectError, assignError, retrieveError, lendError, transferError]);

  if (error) {
    checkError(error);
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>Transferir envase "{container?.name}"</Card.Title>
          <Button
            variant="primary"
            disabled={showSpan || !userSelected?.name}
            onClick={() => setOpenModal(true)}
          >
            Transferir
          </Button>
        </Card.Header>
        <Card.Body className="p-3 bg-light">
          <Form>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Dueño/a actual</Form.Label>
              <Form.Control
                readOnly
                onChange={() => {}}
                type="text"
                name="name"
                value={container?.currentOwner.name}
              />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Destino</Form.Label>
              <Form.Control
                readOnly
                onChange={e => {
                  setUserSelected(e.target.value);
                }}
                value={userSelected?.name}
                type="text"
                name="name"
                placeholder="¿Adónde quieres transferir el envase?"
              />
              {showSpan && currentOwner?.type !== 'partner' && (
                <Form.Text className="text-danger">
                  Debes seleccionar un partner
                </Form.Text>
              )}
              {showSpan && currentOwner?.type === 'partner' && (
                <Form.Text className="text-danger">
                  Debes seleccionar un usuario o admin
                </Form.Text>
              )}
            </Form.Group>
          </Form>
          <Card.Title>
            Usuarios ({partnersData ? partnersData?.pagination.total : 0})
          </Card.Title>
          <Card>
            <AdvanceTableWrapper
              columns={columns}
              data={partnersData?.data || []}
              sortable
              pagination
              perPage={20}
            >
              <Card.Header className="d-flex justify-content-between">
                <Col>
                  <Button
                    disabled={partnersData?.pagination.offset === 0}
                    variant="primary"
                    onClick={() => setUsersOffset(usersOffset - 20)}
                  >
                    Anteriores
                  </Button>
                  <Button
                    disabled={
                      partnersData?.pagination.offset + 20 >
                      partnersData?.pagination.total
                    }
                    variant="primary"
                    onClick={() => setUsersOffset(usersOffset + 20)}
                  >
                    Siguientes
                  </Button>
                </Col>
                <Col className="d-flex justify-content-between">
                  <Col lg={5} style={{ position: 'relative' }}>
                    <Form>
                      <Form.Control
                        type="search"
                        placeholder="Search..."
                        size="sm"
                        aria-label="Search"
                        className="rounded search-input ps-4"
                        onChange={({ target }) => {
                          setSearchUser(target.value);
                        }}
                      />
                      <FontAwesomeIcon
                        style={{ top: '15px', left: '-5px' }}
                        icon="search"
                        className="fs--1 text-400 text-400 position-absolute translate-middle-y ms-3"
                      />
                    </Form>
                  </Col>
                  <Col>
                    <Form.Select
                      onChange={e => setType(e.target.value)}
                      style={{ width: '180px' }}
                      size="sm"
                    >
                      <option value="">Todos</option>
                      <option value="customer">Customer</option>
                      <option value="partner">Partner</option>
                      <option value="admin">Admin</option>
                    </Form.Select>
                  </Col>
                </Col>
              </Card.Header>
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
            </AdvanceTableWrapper>
          </Card>
        </Card.Body>
      </Card>
      {userSelected && openModal && (
        <CustomModal
          containers={[container]}
          selectedUser={userSelected}
          setter={setOpenModal}
          showModal={openModal}
          transferAction={caseSelector}
          userData={currentOwner}
        />
      )}
    </>
  );
};

export default ContainerTransfer;
