import { useEffect } from 'react';
import { useUploadPicturesMutation } from 'redux/endpoints/restaurantApi';
import useError from './useError';

const usePictures = (
  setPhotosFromGoogle,
  photosFromGoogle,
  setFormData,
  formData
) => {
  const { checkError } = useError();
  const [
    uploadPictures,
    { data: picturesData, isLoading: picturesIsLoading, error: picturesError }
  ] = useUploadPicturesMutation();
  useEffect(() => {
    if (picturesData && !picturesIsLoading) {
      setPhotosFromGoogle([...photosFromGoogle, ...picturesData.data]);
      setFormData({
        ...formData,
        photoUrl: [...picturesData.data, ...formData.photoUrl]
      });
    }
  }, [picturesData, picturesIsLoading]);

  useEffect(() => {
    if (picturesError) {
      checkError(picturesError);
    }
  }, [picturesError]);

  return { uploadPictures, picturesIsLoading };
};

export default usePictures;
