import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { exactTimeConverter } from 'components/utilities/timeConverter';
import PropTypes from 'prop-types';
import useError from 'hooks/useError';
import { useLendsListQuery } from 'redux/endpoints/scanApi';
import { lendStatusBadge } from 'components/utilities/badgeSelector';

const UserMovements = ({ userId }) => {
  const { checkError } = useError();
  const [offset, setOffset] = useState(0);

  const { data: movementData, error: movementError } = useLendsListQuery(
    { value: userId, from: offset },
    { refetchOnMountOrArgChange: true }
  );

  if (movementError) {
    checkError(movementError);
  }

  return (
    <Card className="mb-3 p-3">
      <Card.Header>
        <Card.Title>Logs</Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <Row>
          {movementData?.data &&
            movementData?.data?.map((element, index) => {
              return (
                <Col
                  key={index}
                  lg={12}
                  className="border-bottom d-flex justify-content-between"
                >
                  <Card.Text className="my-0 mx-3">
                    {lendStatusBadge(element.status)}
                    Container{' '}
                    <strong className="text-linkedin">
                      {element.container.name}
                    </strong>
                    : - Movement from{' '}
                    {element.status === 'active'
                      ? element?.lender.name
                      : element?.lendee.name}{' '}
                    to{' '}
                    {element.status === 'active'
                      ? element?.lendee.name
                      : element?.lender.name}
                  </Card.Text>{' '}
                  <Card.Text className="my-0 mx-3">
                    {exactTimeConverter(element.createdAt)}
                  </Card.Text>
                </Col>
              );
            })}
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex flex-wrap justify-content-center">
        <Col lg={12} className="d-flex justify-content-center">
          <Card.Text className="fs-0">
            {movementData?.pagination.length + movementData?.pagination.offset}{' '}
            de {movementData?.pagination.total} movimientos
          </Card.Text>
        </Col>
        {movementData?.pagination.offset > 0 && (
          <Card.Link
            style={{ cursor: 'pointer' }}
            className="text-linkedin"
            onClick={() =>
              setOffset(
                movementData?.pagination.offset - movementData?.pagination.limit
              )
            }
          >
            Cargar anteriores
          </Card.Link>
        )}
        {movementData?.pagination.offset + movementData?.pagination.limit <
          movementData?.pagination.total && (
          <Card.Link
            style={{ cursor: 'pointer' }}
            className="text-linkedin"
            onClick={() => {
              setOffset(
                movementData?.pagination.offset + movementData?.pagination.limit
              );
            }}
          >
            Cargar siguientes
          </Card.Link>
        )}
      </Card.Footer>
    </Card>
  );
};

UserMovements.propTypes = {
  userId: PropTypes.string
};

export default UserMovements;
