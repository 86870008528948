import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from 'env/env';

export const restaurantApi = createApi({
  reducerPath: 'restaurantApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().persistedReducer.user.accessToken;
      headers.set('authorization', `Bearer ${token}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    addRestaurant: builder.mutation({
      query: body => ({
        url: 'partners',
        method: 'POST',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    getRestaurantsV2: builder.query({
      query: params => {
        return {
          url: `partners`,
          method: 'GET',
          params: {
            ...(params.value &&
              params.value !== '' && {
                ['filters[0][field]']: 'query',
                ['filters[0][operator]']: 'icontains',
                ['filters[0][value]']: params.value
              }),

            limit: params.limit || 10,
            offset: params.from,
            dir: 'ASC',
            order: 'name'
          }
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getSingleRestaurant: builder.query({
      query: id => ({
        url: `partners/${id}`,
        method: 'GET'
      }),
      transformResponse: response => response.data
    }),
    getSingleRestaurantMutation: builder.mutation({
      query: id => ({
        url: `partners/${id}`,
        method: 'GET'
      }),
      transformResponse: response => response.data
    }),
    editRestaurant: builder.mutation({
      query: body => ({
        url: `partners/${body.id}`,
        method: 'PATCH',
        body
      }),
      transformResponse: () => 'No content response'
    }),
    uploadPictures: builder.mutation({
      query: body => ({
        url: '/images/uploadUrl',
        method: 'POST',
        body: {
          url: body.url
        }
      })
    }),
    uploadPicturesToS3: builder.mutation({
      query: body => ({
        url: '/images/uploadFile',
        method: 'POST',
        headers: {
          'Content-type': 'multipart/form-data',
          'Content-length': body.file.size
        },
        body: body.file
      })
    }),
    deletePartner: builder.mutation({
      query: id => ({
        url: `partners/${id}`,
        method: 'DELETE',
        body: {}
      }),
      transformResponse: () => 'No content response'
    })
  })
});

export const {
  useAddRestaurantMutation,
  useGetSingleRestaurantQuery,
  useEditRestaurantMutation,
  useUploadPicturesMutation,
  useGetRestaurantsV2Query,
  useUploadPicturesToS3Mutation,
  useGetSingleRestaurantMutationMutation,
  useDeletePartnerMutation
} = restaurantApi;
