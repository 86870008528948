import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import badgeSelector, {
  userBadgetType
} from 'components/utilities/badgeSelector';
import { svgSelectorDetailed } from 'components/utilities/svgSelector';
import { exactTimeConverter } from 'components/utilities/timeConverter';
import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return (
        <Link to={`/container-details/${id}`}>
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'productType',
    Header: 'Product Type',
    Cell: rowData => {
      const { productType } = rowData.row.original;
      return (
        <Flex>
          <Card.Text>
            {svgSelectorDetailed(productType.code)} {productType.name}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: rowData => {
      const { status } = rowData.row.original;
      return <Flex>{badgeSelector(status)}</Flex>;
    }
  },
  {
    accessor: 'currentOwner',
    Header: 'Dueño actual',
    Cell: rowData => {
      const { currentOwner } = rowData.row.original;
      return <Flex>{currentOwner.name}</Flex>;
    }
  },
  {
    accessor: 'updatedAt',
    Header: 'Último movimiento',
    Cell: rowData => {
      const { updatedAt } = rowData.row.original;
      return <Flex>{exactTimeConverter(updatedAt)}</Flex>;
    }
  },
  {
    accessor: 'owner_type',
    Header: 'Owner Type',
    Cell: rowData => {
      const { currentOwner } = rowData.row.original;
      return <Flex>{userBadgetType(currentOwner.type)}</Flex>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const data = rowData.row.original;
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href={`/container-transfer/${data.id}`}>
              Transferir
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];
