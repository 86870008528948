import { useEffect } from 'react';
import useError from './useError';
import {
  useAssociateBillingGroupMutation,
  useCreateBillingGroupMutation,
  useDeleteBillingGroupMutation,
  useGetSingleBillingGroupQuery
} from 'redux/endpoints/billingGroupsApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const useBillingGroups = ({ refetch, setAssociatedCenters, setSearch }) => {
  const id = window.location.pathname.replace('/billing-group/', '');
  const { checkError } = useError();
  const navigate = useNavigate();
  const [
    createBillingGroup,
    { data: createData, isLoading: createIsLoading, error: createError }
  ] = useCreateBillingGroupMutation();

  const [deleteBillingGroup, { data: deleteData, error: deleteError }] =
    useDeleteBillingGroupMutation();

  const [linkToBillingGroup, { data: linkData, error: linkError }] =
    useAssociateBillingGroupMutation();

  const { data, isLoading, error } = useGetSingleBillingGroupQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: window.location.pathname === '/billing-group/new'
  });

  if (error) {
    checkError(error);
  }

  useEffect(() => {
    if (createError) {
      checkError(createError);
    }

    if (createData && !createIsLoading) {
      toast.success('Grupo de facturación creado correctamente');
      navigate('/billing-list/1');
    }
  }, [createError, createData]);

  useEffect(() => {
    if (deleteError) {
      checkError(deleteError);
    }
  }, [deleteError]);

  useEffect(() => {
    if (deleteData) {
      toast.success('Grupo de facturación eliminado correctamente');
      navigate('/billing-list');
    }
  }, [deleteData]);

  useEffect(() => {
    if (linkError) {
      checkError(linkError);
    }
  }, [linkError]);

  useEffect(() => {
    if (linkData && refetch) {
      toast.success('Centros asociados correctamente');
      refetch();
      setAssociatedCenters(false);
      setSearch('');
    }
  }, [linkData]);

  return {
    createBillingGroup,
    deleteBillingGroup,
    linkToBillingGroup,
    data,
    isLoading
  };
};

export default useBillingGroups;
