import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ENV } from 'env/env';

export const containersApi = createApi({
  reducerPath: 'containersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().persistedReducer.user.accessToken;
      headers.set('authorization', `Bearer ${token}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');
      return headers;
    }
  }),
  endpoints: builder => ({
    getContainerList: builder.query({
      query: params => {
        return {
          url: 'containers',
          method: 'GET',
          params: {
            ...(params.field &&
              params.value !== '' && {
                ['filters[0][field]']: params.field,
                ['filters[0][operator]']: params.operator,
                ['filters[0][value]']: params.value
              }),
            dir: 'ASC',
            order: 'name',
            limit: params.limit || 10,
            offset: params.from
          }
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getFilteredAdminContainerList: builder.query({
      query: params => {
        return {
          url: 'containers',
          method: 'GET',
          params: {
            ...(params.field &&
              params.value !== '' && {
                ['filters[0][field]']: params.field,
                ['filters[0][operator]']: params.operator,
                ['filters[0][value]']: params.value
              }),
            ...(params.productTypeId && {
              ['filters[1][field]']: 'productTypeId',
              ['filters[1][operator]']: '=',
              ['filters[1][value]']: params.productTypeId
            }),

            dir: 'ASC',
            order: 'name',
            limit: params.limit || 10,
            offset: params.from
          }
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getMovementHistory: builder.mutation({
      query: params => {
        return {
          url: 'movements',
          method: 'GET',
          params: {
            ...(params.field &&
              params.value !== '' && {
                ['filters[0][field]']: params.field,
                ['filters[0][operator]']: params.operator,
                ['filters[0][value]']: params.value
              })
          },
          dir: params.dir
        };
      },
      transformResponse: response => ({
        data: response.data
      })
    }),
    getMovementGroups: builder.query({
      query: params => {
        return {
          url: 'movementGroups',
          method: 'GET',
          params: {
            ...(params.field &&
              params.value !== '' && {
                ['filters[0][field]']: params.field,
                ['filters[0][operator]']: params.operator,
                ['filters[0][value]']: params.value
              }),
            dir: 'desc',
            limit: params.limit || 5,
            offset: params.from
          }
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getSingleContainer: builder.query({
      query: id => {
        return {
          url: `containers/${id}`,
          method: 'GET'
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getContainerJourney: builder.query({
      query: params => {
        return {
          url: `containerJourneys`,
          method: 'GET',
          params: {
            ...{
              ['filters[0][field]']: 'containerId',
              ['filters[0][operator]']: '=',
              ['filters[0][value]']: params.id
            },
            dir: 'desc',
            limit: params.limit || 5,
            offset: params.from
          }
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    createContainers: builder.mutation({
      query: body => {
        return {
          url: 'containers/bulk',
          method: 'POST',
          body: body
        };
      },
      transformResponse: () => 'No response data'
    })
  })
});

export const {
  useGetContainerListQuery,
  useGetFilteredAdminContainerListQuery,
  useGetMovementHistoryMutation,
  useGetSingleContainerQuery,
  useGetMovementGroupsQuery,
  useCreateContainersMutation,
  useGetContainerJourneyQuery
} = containersApi;

//api/v2/logistics/container/journey/:container_id
