import React from 'react';
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CardDropdown from 'components/common/CardDropdown';

const DetailsInfo = ({ data, id, deletePartner }) => {
  const navigate = useNavigate();
  const businessType = data?.type.toString();
  const productType = data?.productGroupTypeList.toString();

  return (
    <>
      <Card className="mb-2">
        <Col
          className="d-flex align-items-center justify-content-center"
          lg={12}
        >
          <Card.Header
            className="p-3 d-flex align-items-center justify-content-between"
            style={{ width: '100%' }}
          >
            <Card.Title className="fw-bold w-100 fs-2 mt-3 ms-3">
              Detalles
            </Card.Title>
            <Col className="d-flex flex-row align-items-center justify-content-end">
              <Button
                className="me-4"
                variant="primary"
                onClick={() => navigate(`/edit-partner/${id}`)}
              >
                Editar
              </Button>
              <CardDropdown iconClassName="fs--1">
                <div className="py-2">
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => deletePartner(id)}
                  >
                    Eliminar
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </Col>
          </Card.Header>
        </Col>
        <Card.Body
          className="mt-3 d-flex border-top align-items-center justify-content-between p-3"
          style={{ backgroundColor: 'rgb(249,	250, 253)' }}
        >
          <Row className="p-3">
            <Card.Title className="mb-3">Información de la cuenta</Card.Title>
            <Col lg={5} className="">
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Tipo de negocio:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {businessType?.replaceAll(',', ', ')}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Tipo de envase:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {productType?.replaceAll(',', ', ')}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Imágenes:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.photoUrl.length > 0
                      ? data?.photoUrl.length + ' imágenes cargadas'
                      : 'No'}
                  </Card.Text>
                </Col>
              </Col>
            </Col>
            <Col lg={7}>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">URL Delivery:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.delivery.Glovo && (
                      <a
                        className="text-linkedin"
                        href={`${data?.delivery.Glovo}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Glovo
                      </a>
                    )}
                    {data?.delivery['Uber eats'] && (
                      <a
                        className="text-linkedin"
                        href={`${data?.delivery['Uber eats']}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Uber Eats
                      </a>
                    )}
                    {data?.delivery['Just eat'] && (
                      <a
                        className="text-linkedin"
                        href={`${data?.delivery['Just eat']}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Just Eat
                      </a>
                    )}
                    {data?.delivery['Delivery propio'] && (
                      <a
                        className="text-linkedin"
                        href={`${data?.delivery['Delivery propio']}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Delivery Propio
                      </a>
                    )}
                    {data?.delivery['Delivery Propio'] && (
                      <a
                        className="text-linkedin"
                        href={`${data?.delivery['Delivery Propio']}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Delivery Propio
                      </a>
                    )}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">Dirección:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    <a
                      className="text-linkedin"
                      href={`http://maps.google.com/?q=${data?.address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {data?.address}
                    </a>
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">Teléfono:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    <a className="text-linkedin" href={`tel:${data?.phone}`}>
                      {data?.phone}
                    </a>
                  </Card.Text>
                </Col>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

DetailsInfo.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  userId: PropTypes.string,
  deletePartner: PropTypes.func
};

export default DetailsInfo;
