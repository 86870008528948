import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import NavbarTopBillingGroupDashboard from 'components/navbar/top/NavBarTopBillingGroupDashboard';
import { t } from 'i18next';
import { useResetPasswordBillingGroupMutation } from 'redux/endpoints/authApi';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';

const BillingGroupProfile = () => {
  const user = useSelector(state => state.persistedReducer.user);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [resetPassword, { data, isLoading, error }] =
    useResetPasswordBillingGroupMutation();
  const { checkError } = useError();
  const [formData, setFormData] = useState({
    email: user.email,
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (data && !isLoading) {
      setShowEditPassword(false);
      toast.success(t('passwordChanged'));
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (error) {
      checkError(error);
    }
  }, [error]);
  const handleSubmit = e => {
    e.preventDefault();
    console.log(formData);
    resetPassword(formData);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  return (
    <>
      <NavbarTopBillingGroupDashboard />
      <div className="container">
        <Card className="my-3">
          <Card.Header className={'fw-bold w-100 fs-2 p-0'}>
            {user && (
              <>
                <Col lg={12} className="px-4 py-3">
                  <Card.Title className="text-primary">
                    {user.name} (
                    {
                      <a
                        className="text-linkedin"
                        href={`mailto:${user.email}`}
                      >
                        {user.email}
                      </a>
                    }
                    )
                  </Card.Title>
                </Col>
                <Col
                  lg={12}
                  className="mt-2 border-top px-4 py-3 position-relative"
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '20px',
                      left: '16px',
                      marginRight: 5,
                      height: '10px',
                      width: '10px',
                      backgroundColor: 'rgb(55, 126, 34)',
                      borderRadius: '50%'
                    }}
                  />
                  <Card.Text className="fs--1 fw-light d-flex flex-row position-relative">
                    Cuenta creada el
                  </Card.Text>
                  <Card.Subtitle className="fw-light">
                    {user.createdAt}
                  </Card.Subtitle>
                </Col>
              </>
            )}
          </Card.Header>
        </Card>
        <Card className="mt-3">
          <Card.Header className="d-flex flex-row justify-content-between">
            <Card.Title className="mt-2">Detalles del grupo</Card.Title>
            <Col className="d-flex flex-row align-items-center justify-content-end">
              <Button
                className="me-3"
                size="sm"
                variant="primary"
                onClick={() => setShowEditPassword(!showEditPassword)}
              >
                {t('changePassword')}
              </Button>
            </Col>
          </Card.Header>
          <Card.Body
            className="p-2 border-top"
            style={{ backgroundColor: 'rgb(249,	250, 253)' }}
          >
            <Row className="p-3">
              <Col lg={12} className="d-flex flex-row">
                <Col lg={5}>
                  <Card.Title className="mb-3">Contacto</Card.Title>
                </Col>
                {/*  <Col className="ps-3">
                  <Card.Title>Preferencias</Card.Title>
                </Col> */}
              </Col>
              <Col lg={5} className="">
                <Col className="d-flex flex-direction-row justify-content-between">
                  <Col lg={4}>
                    <Card.Text className="m-1">Nombre:</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text className="m-1">{user.name}</Card.Text>
                  </Col>
                </Col>
                <Col className="d-flex flex-direction-row justify-content-between">
                  <Col lg={4}>
                    <Card.Text className="m-1">Teléfono:</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text className="m-1">
                      {user.phone ? user.phone : 'No proporcionado'}
                    </Card.Text>
                  </Col>
                </Col>
                <Col className="d-flex flex-direction-row justify-content-between">
                  <Col lg={4}>
                    <Card.Text className="m-1">Email:</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text className="m-1">{user.email}</Card.Text>
                  </Col>
                </Col>
              </Col>
              {showEditPassword && (
                <Col lg={5} className="">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('password')}</Form.Label>
                      <Form.Control
                        placeholder={t('password')}
                        value={formData.password}
                        name={'password'}
                        onChange={handleFieldChange}
                        type="password"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('repeatPassword')}</Form.Label>
                      <Form.Control
                        placeholder={t('repeatPassword')}
                        value={formData.confirmPassword}
                        name={'confirmPassword'}
                        onChange={handleFieldChange}
                        type="password"
                      />
                    </Form.Group>
                    <Form.Group>
                      <Button
                        type="submit"
                        onClick={handleSubmit}
                        color="primary"
                        className="mt-3"
                        disabled={
                          !formData.password ||
                          !formData.password ||
                          formData.password !== formData.confirmPassword
                        }
                      >
                        {t('send')}
                      </Button>
                    </Form.Group>
                  </Form>
                </Col>
              )}
              {/*  <Col lg={7}>
                <Col className="d-flex flex-direction-row justify-content-between">
                  <Col lg={3}>
                    <Card.Text className="m-1">Factura:</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text className="m-1">{user.billingMode}</Card.Text>
                  </Col>
                </Col>
                <Col className="d-flex flex-direction-row justify-content-between">
                  <Col lg={3}>
                    <Card.Text className="m-1">Proforma:</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text className="m-1">
                      {user.proformaInvoice ? 'Sí' : 'No'}
                    </Card.Text>
                  </Col>
                </Col>
                <Col className="d-flex flex-direction-row justify-content-between">
                  <Col lg={3}>
                    <Card.Text className="m-1">Plazo de vencimiento:</Card.Text>
                  </Col>
                  <Col>
                    <Card.Text className="m-1">-</Card.Text>
                  </Col>
                </Col>
              </Col> */}
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default BillingGroupProfile;
