import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationES from './localization/es/translation.json';
import translationEN from './localization/en/translation.json';
import translationCA from './localization/cat/translation.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: translationES
  },
  en: {
    translation: translationEN
  },
  ca: {
    translation: translationCA
  }
};
export const language = navigator.language.substring(0, 2);
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
