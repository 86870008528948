import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logged: false,
  token: null,
  remember: false
};

const loginReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    doLogin: (state, action) => {
      state.logged = true;
      state.token = action.payload.token;
      state.remember = action.payload.remember;
    },
    doLogout: state => {
      state.logged = false;
      state.token = null;
    }
  }
});

export const { doLogin, doLogout } = loginReducer.actions;

export default loginReducer.reducer;
