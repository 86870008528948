import LocationInput from 'components/common/LocationInput';
import MultiSelect from 'components/common/MultiSelect';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {
  useAddRestaurantMutation,
  useUploadPicturesMutation
} from 'redux/endpoints/restaurantApi';
import useError from 'hooks/useError';
import { useGetBillingGroupsListQuery } from 'redux/endpoints/billingGroupsApi';

const defaultValues = {
  id: '',
  name: '',
  email: '',
  password: '',
  address: '',
  lat: 0,
  lng: 0,
  phone: '',
  website: '',
  placeId: '',
  hours: [''],
  active: true,
  displayed: false,
  photoUrl: [''],
  rating: 1,
  discount: false,
  type: [],
  productGroupTypeList: []
};

const productType = [
  { value: 'Bowl', label: 'Bowl' },
  { value: 'Cup', label: 'Cup' },
  { value: 'Areas', label: 'Areas' },
  { value: 'Esteve', label: 'Esteve' }
];

const businessType = [
  { value: 'restaurant', label: 'Restaurante' },
  { value: 'delivery_service', label: 'Servicio a domicilio' },
  { value: 'supermarket', label: 'Supermercado' },
  { value: 'canteen', label: 'Cantina' },
  { value: 'cafeteria', label: 'Cafetería' }
];

const deliveries = [
  { value: 'glovo', label: 'Glovo' },
  { value: 'uber_eats', label: 'Uber Eats' },
  { value: 'just_eat', label: 'Just Eat' },
  { value: 'own_delivery', label: 'Delivery Propio' }
];
// eslint-disable-next-line no-undef
const Spain = new google.maps.LatLng(40.416775, -3.70379);
// eslint-disable-next-line no-undef
const map = new google.maps.Map(document.createElement('div'), {
  center: Spain,
  zoom: 15
});
// eslint-disable-next-line no-undef
const service = new google.maps.places.PlacesService(map);

const PartnerCreation = () => {
  const { token } = useSelector(state => state.persistedReducer.user);
  const { control } = useForm({
    defaultValues
  });
  const { data: billingList, error: billingListError } =
    useGetBillingGroupsListQuery(
      { limit: 1000 },
      {
        refetchOnMountOrArgChange: true
      }
    );
  const [formData, setFormData] = useState(defaultValues);
  const [typesSelected, setTypesSelected] = useState([]);
  const [productGroupSelected, setProductGroupSelected] = useState([]);
  const [deliverySelected, setDeliverySelected] = useState([]);
  const { checkError } = useError();
  // eslint-disable-next-line no-unused-vars
  const [placeId, setPlaceId] = useState('');
  const [loadedPhotosFromGoogle, setLoadedPhotosFromGoogle] = useState(false);
  const [photosFromGoogle, setPhotosFromGoogle] = useState([]);
  const navigation = useNavigate();
  const [addRestaurant, { data, isLoading, error }] =
    useAddRestaurantMutation();

  const [
    uploadPictures,
    { data: picturesData, isLoading: picturesIsLoading, error: picturesError }
  ] = useUploadPicturesMutation();

  const convertToBase64 = file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };
  const handleFileUpload = async e => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    const format = file.type.slice(6);
    uploadPictures({
      restaurantName: formData.name ? formData.name : 'restaurant_picture',
      base64: base64,
      format: format,
      token: token
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    addRestaurant({ ...formData, id: uuidv4() });
  };

  if (billingListError) {
    checkError(billingListError);
  }

  useEffect(() => {
    if (picturesData && !picturesIsLoading) {
      setPhotosFromGoogle([...photosFromGoogle, ...picturesData.data]);
      setFormData({
        ...formData,
        photoUrl: picturesData?.data
      });
    }
  }, [picturesData, picturesIsLoading]);

  useEffect(() => {
    if (data && !isLoading) {
      toast.success('Partner añadido');
      navigation('/partners-list/1');
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (error) {
      checkError(error);
    }

    if (picturesError) {
      checkError(picturesError);
    }
  }, [error, picturesError]);

  useEffect(() => {
    if (formData.address !== '' && formData.placeId !== '') {
      findPlace(formData.placeId);
    }
  }, [formData.address]);

  useEffect(() => {
    handleSelect(typesSelected, productGroupSelected);
  }, [typesSelected, productGroupSelected]);

  const handleSelect = (typeArray, productTypeArray) => {
    const typesArray = [];
    const productArray = [];
    typeArray.forEach(item => {
      typesArray.push(item.value);
    });
    productTypeArray.forEach(item => {
      productArray.push(item.value);
    });
    setFormData({
      ...formData,
      type: typesArray,
      productGroupTypeList: productArray
    });
    return productArray;
  };

  const handleChange = event => {
    if (event.target.name === 'photoUrl') {
      const photoData = handleFileUpload(event);
      setFormData({
        ...formData,
        [event.target.name]: photoData
      });
    } else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      });
    }
  };

  const handleCheckboxChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked
    });
  };

  const findPlace = placeId => {
    const request = {
      language: 'en',
      placeId: placeId,
      fields: [
        'name',
        'rating',
        'formatted_phone_number',
        'opening_hours',
        'website',
        'price_level',
        'photo'
      ]
    };
    service.getDetails(request, async (place, status) => {
      // eslint-disable-next-line no-undef
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        const photosArray = [];
        if (place.photos) {
          place.photos.forEach(image => {
            photosArray.push(image.getUrl());
          });
          uploadPictures({ url: photosArray });
          setLoadedPhotosFromGoogle(true);
        } else {
          photosArray.push(
            'https://assets.website-files.com/6364ce2b4443c417576dd013/63726128b957fe4bb77fa540_Manduca_Restaurantes%202.webp'
          );
          uploadPictures({ url: photosArray });
        }
        setFormData({
          ...formData,
          placeId,
          website: place.website && place.website,
          rating: place.rating && place.rating,
          hours: place.opening_hours && place.opening_hours.weekday_text,
          phone: formData.phone
            ? formData.phone
            : place.formatted_phone_number && place.formatted_phone_number
        });
      } else {
        toast.error('No se ha podido guardar la dirección');
      }
    });
  };

  return (
    <Card>
      <Card.Header className="mt-4 ms-5" as="h5">
        Crear nuevo centro
      </Card.Header>
      <Card.Body className="overflow-hidden p-lg-6">
        <Form className="align-items-center justify-content-between">
          <Col lg={12} className="ps-lg-4 my-0 text-lg-left">
            <Col lg={12} className="d-flex flex-row">
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    required
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder="Nombre"
                  />
                </Form.Group>
              </Col>
              <Col className="ms-1">
                <Form.Group className="mb-3 " controlId="formGroupBilling">
                  <Form.Label>Grupo</Form.Label>
                  {billingList?.data && (
                    <Form.Select
                      onChange={({ target }) =>
                        setFormData({
                          ...formData,
                          billingGroupId: target.value
                        })
                      }
                    >
                      <option>Selecciona grupo</option>
                      {billingList?.data.map(billing => (
                        <option key={billing.id} value={billing.id}>
                          {billing.name}
                        </option>
                      ))}
                    </Form.Select>
                  )}
                </Form.Group>
              </Col>
              <Col className="ms-1">
                <Form.Group className="mb-3 " controlId="formGroupAccount">
                  <Form.Label>Cuenta</Form.Label>
                  <Form.Select>
                    <option>Grupo 1</option>
                    <option>Grupo 2</option>
                    <option>Grupo 3</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Col>

            <Col lg={12} className="d-flex flex-row">
              <Col>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    name="email"
                    onChange={handleChange}
                    type="email"
                    placeholder="Email"
                  />
                </Form.Group>
              </Col>
              <Col className="flex-grow-1 ms-1">
                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    required
                    name="password"
                    onChange={handleChange}
                    type="password"
                    placeholder="Contraseña"
                  />
                </Form.Group>
              </Col>
            </Col>

            <Col lg={12} className="d-flex flex-row">
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="formGroupDelivery">
                  <Form.Label>Links Plataforma Delivery</Form.Label>
                  <Controller
                    name="delivery"
                    render={({ field, ref }) => {
                      return (
                        <MultiSelect
                          {...field}
                          ref={ref}
                          name="delivery"
                          onChange={setDeliverySelected}
                          closeMenuOnSelect={false}
                          value={deliverySelected}
                          isMulti
                          options={deliveries}
                          placeholder="Selecciona delivery..."
                        />
                      );
                    }}
                    control={control}
                  />
                </Form.Group>
              </Col>
              <Col className="flex-grow-1 ms-1">
                <Form.Group className="mb-3" controlId="formGroupPhone">
                  <Form.Label>Teléfono</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    name="phone"
                    type="phone"
                    placeholder={formData.phone ? formData.phone : 'Teléfono'}
                  />
                </Form.Group>
              </Col>
              <Col className="flex-grow-1 ms-1">
                <Form.Group className="mb-3" controlId="formGroupWeb">
                  <Form.Label>Web</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    name="website"
                    type="text"
                    placeholder={formData.website ? formData.website : 'Web'}
                  />
                </Form.Group>
              </Col>
            </Col>

            {deliverySelected.map(delivery => {
              if (delivery.value === 'own_delivery') {
                return (
                  <Form.Group
                    key={`${delivery.value}`}
                    className="mb-3"
                    controlId="formGroupDelivery"
                  >
                    <Form.Label>Link a Delivery Propio</Form.Label>
                    <Form.Control
                      onChange={e =>
                        setFormData({
                          ...formData,
                          delivery: {
                            ...formData.delivery,
                            ['Delivery propio']: e.target.value
                          }
                        })
                      }
                      name="delivery"
                      type="text"
                    />
                  </Form.Group>
                );
              }
              if (delivery.value === 'glovo') {
                return (
                  <Form.Group
                    key={`${delivery.value}`}
                    className="mb-3"
                    controlId="formGroupDelivery"
                  >
                    <Form.Label>Link a Glovo</Form.Label>
                    <Form.Control
                      onChange={e =>
                        setFormData({
                          ...formData,
                          delivery: {
                            ...formData.delivery,
                            Glovo: e.target.value
                          }
                        })
                      }
                      name="delivery"
                      type="text"
                    />
                  </Form.Group>
                );
              }
              if (delivery.value === 'uber_eats') {
                return (
                  <Form.Group
                    key={`${delivery.value}`}
                    className="mb-3"
                    controlId="formGroupDelivery"
                  >
                    <Form.Label>Link a Uber Eats</Form.Label>
                    <Form.Control
                      onChange={e =>
                        setFormData({
                          ...formData,
                          delivery: {
                            ...formData.delivery,
                            ['Uber Eats']: e.target.value
                          }
                        })
                      }
                      name="delivery"
                      type="text"
                    />
                  </Form.Group>
                );
              }
              if (delivery.value === 'just_eat') {
                return (
                  <Form.Group
                    key={`${delivery.value}`}
                    className="mb-3"
                    controlId="formGroupDelivery"
                  >
                    <Form.Label>Link a Just Eat</Form.Label>
                    <Form.Control
                      onChange={e =>
                        setFormData({
                          ...formData,
                          delivery: {
                            ...formData.delivery,
                            ['Just Eat']: e.target.value
                          }
                        })
                      }
                      name="delivery"
                      type="text"
                    />
                  </Form.Group>
                );
              }
              return null;
            })}

            <Col lg={12} className="d-flex flex-row">
              <Col className="me-1">
                <Form.Group controlId="productGroupTypeList" className="mb-3">
                  <Form.Label className="me-5">Tipo de producto</Form.Label>
                  <Controller
                    name="productGroupTypeList"
                    render={({ field, ref }) => (
                      <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={setProductGroupSelected}
                        closeMenuOnSelect={false}
                        value={productGroupSelected}
                        isMulti
                        options={productType}
                        placeholder="Selecciona tipo de producto..."
                      />
                    )}
                    control={control}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="businessType" className="mb-3">
                  <Form.Label className="me-5">Tipo de negocio</Form.Label>
                  <Controller
                    name="businessType"
                    render={({ field, ref }) => (
                      <MultiSelect
                        {...field}
                        ref={ref}
                        onChange={setTypesSelected}
                        value={typesSelected}
                        closeMenuOnSelect={false}
                        isMulti
                        options={businessType}
                        placeholder="Selecciona tipo de negocio..."
                      />
                    )}
                    control={control}
                  />
                </Form.Group>
              </Col>
            </Col>
            <LocationInput
              setPlaceId={setPlaceId}
              completeForm={formData}
              setCompleteForm={setFormData}
            />

            <Form.Group className="mb-1" controlId="formGroupCheckboxes">
              <Form.Check
                onClick={handleCheckboxChange}
                inline
                type="switch"
                name="displayed"
                id="displayed"
                className="m-0"
              />
              <Form.Label className="me-5">Mostrar en el mapa</Form.Label>
              <Form.Check
                inline
                onClick={handleCheckboxChange}
                type="switch"
                name="active"
                id="active"
                className="m-0"
                defaultChecked
              />
              <Form.Label className="me-5">Activo</Form.Label>
              <Form.Check
                inline
                onClick={handleCheckboxChange}
                type="switch"
                name="discount"
                id="discount"
                className="m-0"
              />
              <Form.Label className="me-5">Descuento</Form.Label>
            </Form.Group>

            <Form.Group controlId="imageUpload" className="mb-3">
              <Form.Label>Cargar imágenes</Form.Label>
              <Form.Control
                name="photoUrl"
                onChange={handleFileUpload}
                type="file"
              />
              {picturesIsLoading && (
                <Spinner animation="border" variant="primary" />
              )}
              {photosFromGoogle.length > 0 && (
                <>
                  <>
                    <Card
                      className="d-flex flex-row m-3"
                      style={{ overflowX: 'auto' }}
                    >
                      <style>
                        {`
                          ::-webkit-scrollbar {
                            width: 6px;
                            height: 6px;
                          }
                          ::-webkit-scrollbar-thumb {
                            background-color: rgba(0,0,0,.2);
                            border-radius: 6px;
                          }
                          ::-webkit-scrollbar-thumb:hover {
                            background-color: rgba(0,0,0,.5);
                          }
                        `}
                      </style>
                      {formData.photoUrl.map((photo, index) => (
                        <Card.Body
                          key={index}
                          className="align-items-center, position-relative"
                        >
                          <div
                            onClick={() => {
                              setFormData({
                                ...formData,
                                photoUrl: formData.photoUrl.filter(
                                  (photo, i) => i !== index
                                )
                              });
                              setPhotosFromGoogle(formData.photoUrl);
                            }}
                            style={{
                              left: '135px',
                              top: '25px',
                              backgroundColor: 'white',
                              width: '30px',
                              height: '30px',
                              cursor: 'pointer'
                            }}
                            className="position-absolute align-items-center justify-content-center d-flex rounded-circle border"
                          >
                            <FontAwesomeIcon icon="close" />
                          </div>
                          <Card.Img
                            className=""
                            key={index}
                            style={{
                              height: 150,
                              width: 150,
                              objectFit: 'cover'
                            }}
                            src={photo}
                            alt={`image_${index}`}
                          ></Card.Img>
                        </Card.Body>
                      ))}
                    </Card>
                    <span>
                      {formData.photoUrl.length === 1 &&
                      formData.photoUrl[0] !== ''
                        ? `Se ha cargado ${formData.photoUrl.length} foto${' '}
                    ${loadedPhotosFromGoogle ? 'desde Google' : ''}`
                        : `Se han cargado ${
                            formData.photoUrl.length
                          } fotos${' '}
                    ${loadedPhotosFromGoogle ? 'desde Google' : ''}`}
                    </span>
                  </>
                </>
              )}
            </Form.Group>

            <Button
              onClick={e => handleSubmit(e)}
              className="mb-6 mt-3"
              disabled={
                formData.name === '' ||
                formData.email === '' ||
                formData.password === '' ||
                formData.address === '' ||
                formData.type.length === 0 ||
                formData.productGroupTypeList.length === 0
              }
              variant="primary"
              type="submit"
            >
              Guardar
            </Button>
          </Col>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default PartnerCreation;
