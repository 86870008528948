import React from 'react';
import { ReactComponent as BowlBlue } from '../../assets/img/bumerang-assets/bowl-blue.svg';
import { ReactComponent as BowlGreen } from '../../assets/img/bumerang-assets/bowl-green.svg';
import { ReactComponent as Cup } from '../../assets/img/bumerang-assets/cup.svg';
import { ReactComponent as Areas } from '../../assets/img/bumerang-assets/areas.svg';

const svgSelector = type => {
  const processedType = type.toLowerCase();
  switch (processedType) {
    case 'bowl_green':
      return (
        <BowlGreen width={23} height={23} style={{ marginRight: '5px' }} />
      );
    case 'areas_750':
      return <Areas width={23} height={23} style={{ marginRight: '5px' }} />;
    case 'cup':
      return <Cup width={23} height={23} style={{ marginRight: '5px' }} />;
    case 'bowl_blue':
      return <BowlBlue width={23} height={23} style={{ marginRight: '5px' }} />;
    case 'areas_1000':
      return <Areas width={23} height={23} style={{ marginRight: '5px' }} />;
  }
};

export const svgSelectorDetailed = type => {
  const processedType = type.toLowerCase();
  switch (processedType) {
    case 'bowl500mepal':
      return <BowlGreen width={23} height={23} />;
    case 'bowl750green':
      return <BowlGreen width={23} height={23} />;
    case 'bowl1250green':
      return <BowlGreen width={23} height={23} />;
    case 'bowl500green':
      return <BowlGreen width={23} height={23} />;
    case 'bowl1050duogreen':
      return <BowlGreen width={23} height={23} />;
    case 'areas750':
      return <Areas width={23} height={23} />;
    case 'areas1000':
      return <Areas width={23} height={23} />;
    case 'cup250':
      return <Cup width={23} height={23} />;
    case 'cup350':
      return <Cup width={23} height={23} />;
    case 'cup450':
      return <Cup width={23} height={23} />;
    case 'cup250puig':
      return <Cup width={23} height={23} />;
    case 'cup250keepin':
      return <Cup width={23} height={23} />;

    case 'bowl750keepin':
      return <BowlBlue width={23} height={23} />;
    case 'bowl1250keepin':
      return <BowlBlue width={23} height={23} />;
    case 'bowlesteve750':
      return <BowlBlue width={23} height={23} />;
    case 'bowl1250mepal':
      return <BowlBlue width={23} height={23} />;
    case 'bowl1250keepincat':
      return <BowlBlue width={23} height={23} />;
    case 'bowl750keepincat':
      return <BowlBlue width={23} height={23} />;

    case 'bowl750mepal':
      return <BowlBlue width={23} height={23} />;
  }
};

export default svgSelector;
