import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import timeConverter from 'components/utilities/timeConverter';
import useError from 'hooks/useError';
import React from 'react';
import { Accordion, Button, Card, Col, Dropdown } from 'react-bootstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { useNavigate } from 'react-router-dom';
import { useGetPricesQuery } from 'redux/endpoints/pricesApi';

const priceColumns = [
  {
    accessor: 'price',
    Header: 'Precio',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },

    Cell: rowData => {
      const { name, tiers } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1 d-flex flex-column">
            <h5 className="mb-0 fs--1">{name}</h5>
            <p className="m-0">
              Empieza en {(tiers[0].amount / 100).toFixed(2)} €
            </p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    headerProps: { className: 'pe-1' },

    Cell: rowData => {
      const { subscriptions } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1 m-0">
            <h5 className="mb-0 fs--1">{subscriptions}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'createdAt',
    Header: 'Fecha de creación',
    headerProps: { className: 'pe-1' },

    Cell: rowData => {
      const { createdAt } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1 m-0">
            <h5 className="mb-0 fs--1">{timeConverter(createdAt)}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'none2',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href={`/edit-price/${id}`}>Editar</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const planName = rowData.row.original[0].productGroup.name;
      const updatedDate = compareDates(rowData.row.original);
      const navigate = useNavigate();
      return (
        <Accordion className="">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <strong>Tarifas {planName}</strong>
            </Accordion.Header>
            <Accordion.Body className="p-3">
              <Flex className="p-0 d-flex flex-column">
                <Col lg={12} className="p-0 border-bottom">
                  <section className="d-flex flex-column align-items-center justify-content-between p-0 mb-2">
                    <div
                      style={{ width: '100%' }}
                      className="d-flex flex-column justify-content-start mb-3"
                    >
                      <p className="m-0">Actualizado</p>
                      <p>{timeConverter(updatedDate.updatedAt)}</p>
                    </div>
                    <header
                      style={{ width: '100%' }}
                      className="d-flex flex-row justify-content-between align-items-center"
                    >
                      <h5 className="m-0">Detalles</h5>
                    </header>
                  </section>
                </Col>
                <Col lg={12} className="p-0">
                  <div
                    style={{ width: '15%' }}
                    className="d-flex justify-content-between flex-row mt-3"
                  ></div>
                </Col>
                <Col lg={12} className="p-0 border-bottom">
                  <section
                    style={{ width: '100%' }}
                    className="d-flex flex-column align-items-center justify-content-between p-0 mb-2"
                  >
                    <header
                      style={{ width: '100%' }}
                      className="d-flex flex-row justify-content-between align-items-center"
                    >
                      <h5 className="m-0">Tarifa</h5>
                      <Button
                        style={{ width: '100px' }}
                        variant="primary"
                        onClick={() => navigate('/new-price')}
                      >
                        + Añadir
                      </Button>
                    </header>
                  </section>
                </Col>
                <Col lg={12} className="p-0 mb-4">
                  <AdvanceTableWrapper
                    columns={priceColumns}
                    data={rowData.row.original || []}
                    pagination
                    perPage={5}
                  >
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-4 pb-4'
                      }}
                    />
                    <AdvanceTableFooter table navButtons />
                  </AdvanceTableWrapper>
                </Col>
              </Flex>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      );
    }
  }
];

function compareDates(arr) {
  let mostRecent = arr[0];
  arr.forEach(date => {
    if (date.updatedAt > mostRecent.updatedAt) {
      mostRecent = date;
    }
  });
  return mostRecent;
}

const orderPrices = prices => {
  const pricesByGroup = {};

  prices?.forEach(price => {
    if (!pricesByGroup[price.productGroup.name]) {
      pricesByGroup[price.productGroup.name] = [];
    }
    pricesByGroup[price.productGroup.name].push(price);
  });

  const arrayWithGroups = Object.values(pricesByGroup);
  return arrayWithGroups;
};

const PriceList = () => {
  const { data: prices, error: pricesError } = useGetPricesQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const { checkError } = useError();

  if (pricesError) checkError(pricesError);
  let example = orderPrices(prices && prices);

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={example || []}
      pagination
      perPage={20}
    >
      <Col lg={12}>
        <Card className="mb-3">
          <CardHeader>
            <Card.Title tag="h5">Tarifas</Card.Title>
          </CardHeader>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <AdvanceTableFooter table navButtons />
          </Card.Body>
        </Card>
      </Col>
    </AdvanceTableWrapper>
  );
};

export default PriceList;
