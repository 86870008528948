import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useEditRestaurantMutation,
  useGetSingleRestaurantQuery
} from 'redux/endpoints/restaurantApi';
import { toast } from 'react-toastify';
import DetailsHeader from 'components/partner-details/DetailsHeader';
import DetailsInfo from 'components/partner-details/DetailsInfo';
import DetailsStock from 'components/partner-details/DetailsStock';
import DetailsMovements from 'components/partner-details/DetailsMovements';
import {
  useDeleteUserMutation,
  useGetUsersListMutationMutation
} from 'redux/endpoints/userApi';
import { useDeletePartnerMutation } from 'redux/endpoints/restaurantApi';
import useError from 'hooks/useError';
import { useNavigate } from 'react-router-dom';
import { useGetMovementGroupsQuery } from 'redux/endpoints/containersApi';

const PartnerDetails = () => {
  const { checkError } = useError();
  const navigate = useNavigate();
  const id = window.location.pathname;
  const partnerId = id.replace('/partner-details/', '');
  const [offset, setOffset] = useState(0);
  const { data, isLoading, error } = useGetSingleRestaurantQuery(partnerId, {
    refetchOnMountOrArgChange: true
  });
  const [
    editPartner,
    { data: editData, isLoading: editIsLoading, error: editError }
  ] = useEditRestaurantMutation();

  const [deletePartner, { data: deleteData, error: deleteError }] =
    useDeletePartnerMutation();

  const [deleteUser, { data: deleteUserData, error: deleteUserError }] =
    useDeleteUserMutation();

  const [
    getContainersFromUser,
    { data: userContainers, error: userContainersError }
  ] = useGetUsersListMutationMutation();

  const { data: movementData, error: movementError } =
    useGetMovementGroupsQuery(
      { field: 'partnerId', operator: '=', value: data?.userId, from: offset },
      { refetchOnMountOrArgChange: true, skip: !data?.userId }
    );

  if (movementError) {
    checkError(movementError);
  }

  useEffect(() => {
    if (data) {
      getContainersFromUser({
        id: data?.id,
        select: 'id,name,containersAssignedByProductType'
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      checkError(error);
    }

    if (editError) {
      checkError(editError);
    }

    if (userContainersError) {
      checkError(userContainersError);
    }

    if (deleteError) {
      checkError(deleteError);
    }

    if (deleteUserError) {
      checkError(deleteUserError);
    }
  }, [error, editError, userContainersError, deleteError, deleteUserError]);

  useEffect(() => {
    if (editData && !editIsLoading) {
      toast.success('Partner editado correctamente');
    }
  }, [editData, editIsLoading]);

  useEffect(() => {
    if (deleteData && deleteUserData) {
      toast.success('Partner eliminado correctamente');
      navigate('/partners-list/1');
    }
  }, [deleteData]);

  const canThePartnerBeDeleted = () => {
    if (userContainers?.data[0].containersAssignedByProductType.length > 0) {
      if (
        userContainers?.data[0].containersAssignedByProductType?.some(
          containers => containers?.count > 0
        )
      ) {
        return toast.error(
          'No se puede eliminar un partner con stock asignado'
        );
      }
    }

    deletePartner(partnerId);
    deleteUser(partnerId);
  };

  return (
    <>
      {isLoading && (
        <div className="d-flex align-center justify-content-center">
          <div className="spinner-border" role="status" />
        </div>
      )}

      {data && (
        <>
          <DetailsHeader data={data} editData={editPartner} />
          <DetailsInfo
            data={data}
            id={data?.id}
            userId={data?.userId}
            deletePartner={canThePartnerBeDeleted}
          />
          <DetailsStock
            id={data?.id}
            containersAssigned={userContainers?.data[0]}
          />
          <DetailsMovements movementData={movementData} setOffset={setOffset} />
        </>
      )}
    </>
  );
};

PartnerDetails.propTypes = {
  partnerId: PropTypes.string
};
export default PartnerDetails;
