import useBillingGroups from 'hooks/useBillingGroups';
import React, { useState } from 'react';
import { Button, Card, Col, Form, FormCheck } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

const CreateBillingGroup = () => {
  const { createBillingGroup } = useBillingGroups({
    refetch: null,
    setSearch: null,
    setAssociatedCenters: null
  });
  const [isInvalid, setIsInvalid] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    email: '',
    phone: '',
    password: '',
    billingCycle: 30,
    billingMode: '',
    proformaInvoice: false
  });

  const handleFormChange = ({ target }) => {
    if (target.name === 'billingCycle') {
      setFormData({
        ...formData,
        [target.name]: parseInt(target.value)
      });
    } else {
      setFormData({ ...formData, [target.name]: target.value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const {
      name,
      email,
      phone,
      password,
      billingCycle,
      billingMode,
      proformaInvoice
    } = formData;
    setIsInvalid(
      name === '' ||
        email === '' ||
        phone === '' ||
        phone.length < 9 ||
        password === '' ||
        billingCycle === 0 ||
        billingMode === ''
    );
    const id = uuid();
    const newBillingGroup = {
      id,
      name,
      email,
      phone,
      password,
      billingCycle,
      billingMode,
      proformaInvoice
    };
    createBillingGroup(newBillingGroup);
  };

  return (
    <Form className="p-2" onSubmit={handleSubmit}>
      <Card>
        <Card.Header>
          <Card.Title className="py-2 text-primary" as="h3">
            Contacto
          </Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Col lg={12} className="d-flex flex-row justify-content-between">
            <Col lg={6} className="d-flex flex-column me-1">
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="name"
                  placeholder="Nombre"
                  isInvalid={isInvalid && formData.name === ''}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} className="d-flex flex-column">
              <Form.Group controlId="formContactPhone">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  required
                  name="phone"
                  min={9}
                  isInvalid={isInvalid && formData.phone.length < 9}
                  type="text"
                  placeholder="Teléfono"
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
          </Col>

          <Col lg={12} className="d-flex flex-row justify-content-between">
            <Col lg={6} className="d-flex flex-column me-1">
              <Form.Group controlId="formContactEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  name="email"
                  type="email"
                  placeholder="email"
                  isInvalid={isInvalid && formData.email === ''}
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
            <Col lg={6} className="d-flex flex-column">
              <Form.Group controlId="formContactPassword">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  required
                  name="password"
                  type="password"
                  isInvalid={isInvalid && formData.password === ''}
                  placeholder="Contraseña"
                  onChange={handleFormChange}
                />
              </Form.Group>
            </Col>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header className="pb-0">
          <Card.Title className="py-2 text-primary fs-2" as="h3">
            Preferencias de facturación
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group
            className="mb-3 d-flex flex-row"
            controlId="proformaInvoice"
          >
            <FormCheck
              className="align-self-center me-2"
              type="switch"
              defaultChecked={formData.proformaInvoice}
              onChange={e =>
                setFormData({ ...formData, proformaInvoice: e.target.checked })
              }
              name="proformaInvoice"
            />
            <Form.Label className="text-primary m-0 ms-1">
              Factura proforma
            </Form.Label>
          </Form.Group>
          <Col lg={6} className="ms-4">
            <Card.Text className="text-primary">
              Se enviará una pre factura para que el centro correspondiente
              añada el número de pedido
            </Card.Text>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Body>
          <Form.Group className="mb-3 d-flex flex-row" controlId="grouped">
            <FormCheck
              className="align-self-center me-2"
              type="radio"
              defaultChecked={formData.billingMode === 'grouped'}
              onChange={() =>
                setFormData({
                  ...formData,
                  billingMode: 'grouped'
                })
              }
              name="billingMode"
            />
            <Form.Label className="text-primary m-0 ms-1">
              Facturas conjuntas a la Cuenta de facturación
            </Form.Label>
          </Form.Group>
          <Col lg={6} className="ms-4">
            <Card.Text className="text-primary mb-3">
              Se enviará una factura al mail indicado en el apartado Detalles
              con el desglose de usos de cada unos de los centros de coste
              asociados.
            </Card.Text>
          </Col>
          <Form.Group className="mb-3 d-flex flex-row" controlId="billingMode">
            <FormCheck
              className="align-self-center me-2"
              type="radio"
              defaultChecked={formData.billingMode === 'individual'}
              onChange={() =>
                setFormData({
                  ...formData,
                  billingMode: 'individual'
                })
              }
              name="billingMode"
            />
            <Form.Label className="text-primary m-0 ms-1">
              Facturas individuales
            </Form.Label>
          </Form.Group>
          <Col lg={6} className="ms-4">
            <Card.Text className="text-primary">
              Se enviará una factura a cada uno de los centros. Únicamente
              aparecerán los usos correspondientes al centro.
            </Card.Text>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header className="pb-0">
          <Card.Title className="py-2 text-primary fs-2" as="h3">
            Ciclo de facturación
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group controlId="billingCycle">
            <Form.Label className="text-primary m-0 ms-1 mb-2">
              Plazo de vencimiento
            </Form.Label>
            <Col lg={4}>
              <Form.Select
                name="billingCycle"
                placeholder="Vencimiento a 30 días"
                onChange={handleFormChange}
              >
                <option value={30}>Vencimiento a 30 días</option>
                <option value={60}>Vencimiento a 60 días</option>
                <option value={90}>Vencimiento a 90 días</option>
              </Form.Select>
            </Col>
          </Form.Group>
        </Card.Body>
        <Card.Footer>
          <Button
            size="lg"
            style={{ width: '150px' }}
            variant="primary"
            type="submit"
            disabled={
              formData.name === '' ||
              formData.phone === '' ||
              formData.email === '' ||
              formData.password === '' ||
              formData.billingCycle === 0 ||
              formData.billingMode === ''
            }
          >
            Crear
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default CreateBillingGroup;
