import useLogin from 'hooks/useLogin';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LoginForm = hasLabel => {
  const { userLogin } = useLogin();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleSubmit = e => {
    e.preventDefault();
    userLogin(formData);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('emailAddress')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('emailAddress') : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('password')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('password') : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link className="fs--1 mb-0" to={`/forgot-password`}>
            {t('forgotPassword')}
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          onClick={handleSubmit}
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          {t('send')}
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
