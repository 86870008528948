import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import timeConverter from 'components/utilities/timeConverter';
import badgeSelector from 'components/utilities/badgeSelector';
import { useNavigate } from 'react-router-dom';

const ContainerDetailsHeader = ({ data }) => {
  const navigation = useNavigate();
  return (
    <Card className="mb-2 p-3">
      <Row>
        <Col
          className="d-flex align-items-center justify-content-between"
          lg={12}
        >
          <Card.Header className="fw-bold w-100 ">
            <Row>
              <Col className="m-0  d-flex justify-content-between">
                <Card.Text>Detalles del envase {data?.name}</Card.Text>
                <Button
                  variant="primary"
                  onClick={() => navigation(`/container-transfer/${data?.id}`)}
                >
                  Transferir
                </Button>
              </Col>
            </Row>
            <Col className="m-0 fs-2">
              <Card.Text>
                {data?.name} ({data?.productType.name})
              </Card.Text>
            </Col>
          </Card.Header>
          <Card.Body className="p-0"></Card.Body>
        </Col>
        <Col lg={12} className="mt-3 border-top align-items-center p-3">
          <Col lg={5} className="ms-3 d-flex flex-row">
            <Col>
              <Card.Text className="m-0">Estado del envase</Card.Text>
            </Col>
            <Col>
              <Card.Text>{badgeSelector(data?.status)}</Card.Text>
            </Col>
          </Col>
          <Col className="ms-3">
            <Card.Text>{timeConverter(data?.createdAt)}</Card.Text>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

ContainerDetailsHeader.propTypes = {
  data: PropTypes.object,
  journeyData: PropTypes.object
};

export default ContainerDetailsHeader;
