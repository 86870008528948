import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import UserDetailsHeader from 'components/user-details/UserDetailsHeader';
import {
  useGetUserQueryQuery,
  useGetUsersListMutationMutation
} from 'redux/endpoints/userApi';
import UserDetailsInfo from 'components/user-details/UserDetailsInfo';
import UserDetailsStock from 'components/user-details/UserDetailsStock';
import useError from 'hooks/useError';
import UserMovements from 'components/user-details/UserMovements';

const UserDetails = () => {
  const id = window.location.pathname;
  const { checkError } = useError();
  const userId = id.replace('/user-details/', '');

  const {
    data: userData,
    isLoading,
    error: userError
  } = useGetUserQueryQuery(userId, { refetchOnMountOrArgChange: true });
  const [
    getContainersFromUser,
    { data: userContainers, error: userContainersError }
  ] = useGetUsersListMutationMutation();

  useEffect(() => {
    if (userData) {
      getContainersFromUser({
        id: userId,
        select: 'id,name,containersAssignedByProductType'
      });
    }
  }, [userData]);

  useEffect(() => {
    if (userError) {
      checkError(userError);
    }

    if (userContainersError) {
      checkError(userContainersError);
    }
  }, [userError, userContainersError]);

  return (
    <>
      {isLoading && (
        <div className="d-flex align-center justify-content-center">
          <div className="spinner-border" role="status" />
        </div>
      )}
      <UserDetailsHeader data={userData} />
      <UserDetailsInfo data={userData} />
      {userData?.id && (
        <UserDetailsStock
          id={userData?.id}
          containersAssigned={
            userContainers?.data[0].containersAssignedByProductType
          }
        />
      )}
      <UserMovements userId={userId} />
    </>
  );
};

UserDetails.propTypes = {
  partnerId: PropTypes.string
};
export default UserDetails;
