import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import { persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import logger from 'redux-logger';
import loginReducer from './reducers/loginReducer';
import { authApi } from './endpoints/authApi';
import { userApi } from './endpoints/userApi';
import userReducer from './reducers/userReducer';
import { restaurantApi } from './endpoints/restaurantApi';
import restaurantReducer from './reducers/restaurantReducer';
import containerReducer from './reducers/containerReducer';
import { containersApi } from './endpoints/containersApi';
import { scanApi } from './endpoints/scanApi';
import scanReducer from './reducers/scanReducer';
import productTypeReducer from './reducers/productTypeReducer';
import { productTypesApi } from './endpoints/productTypesApi';
import { paymentApiV2 } from './endpoints/paymentApiV2';
import productGroupsReducer from './reducers/productGroupsReducer';
import { productGroupsApi } from './endpoints/productGroups';
import { pricesApi } from './endpoints/pricesApi';
import priceReducer from './reducers/priceReducer';
import { billingGroupsApi } from './endpoints/billingGroupsApi';
import billingGroupsReducer from './reducers/billingGroupsReducer';
import stockReportsReducer from './reducers/stockReportsReducer';
import { stockReportsApi } from './endpoints/stockReportsApi';
/* import { logisticsApi } from "./endpoints/logisticsApi"; */

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  whitelist: [
    'popup',
    'login',
    'user',
    'container',
    'product',
    'camera',
    'productTypes',
    'productGroups',
    'price',
    'billingGroups',
    'stockReports'
  ],
  blacklist: [
    'popupApi',
    'authApi',
    'userApi',
    'restaurantApi',
    'logisticsApi',
    'scanApi',
    'productTypesApi',
    'paymentApiV2',
    'productGroupsApi',
    'pricesApi',
    'billingGroupsApi',
    'stockReportsApi'
  ]
};

const rootReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  restaurant: restaurantReducer,
  container: containerReducer,
  scanSummary: scanReducer,
  productTypes: productTypeReducer,
  productGroups: productGroupsReducer,
  price: priceReducer,
  billingGroups: billingGroupsReducer,
  stockReports: stockReportsReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: {
    persistedReducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [restaurantApi.reducerPath]: restaurantApi.reducer,
    [containersApi.reducerPath]: containersApi.reducer,
    [scanApi.reducerPath]: scanApi.reducer,
    [productTypesApi.reducerPath]: productTypesApi.reducer,
    [paymentApiV2.reducerPath]: paymentApiV2.reducer,
    [productGroupsApi.reducerPath]: productGroupsApi.reducer,
    [pricesApi.reducerPath]: pricesApi.reducer,
    [billingGroupsApi.reducerPath]: billingGroupsApi.reducer,
    [stockReportsApi.reducerPath]: stockReportsApi.reducer
    /*     [logisticsApi.reducerPath]: logisticsApi.reducer,
     */
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(
      // logger,
      authApi.middleware,
      restaurantApi.middleware,
      containersApi.middleware,
      userApi.middleware,
      scanApi.middleware,
      productTypesApi.middleware,
      paymentApiV2.middleware,
      productGroupsApi.middleware,
      pricesApi.middleware,
      billingGroupsApi.middleware,
      stockReportsApi.middleware
      /*       
      logisticsApi.middleware,
       */
    )
});

setupListeners(store.dispatch);

export let persistor = persistStore(store);
