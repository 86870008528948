import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { useRequestPasswordBillingGroupMutation } from 'redux/endpoints/authApi';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';

const ForgotPasswordForm = ({ hasLabel }) => {
  const [email, setEmail] = useState('');
  const [requestPassword, { data, error, isLoading }] =
    useRequestPasswordBillingGroupMutation();
  const { checkError } = useError();

  useEffect(() => {
    if (data && !isLoading) {
      toast.success(t('emailSent'));
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      checkError(error);
    }
  }, [error]);

  const handleSubmit = e => {
    e.preventDefault();
    requestPassword({ emails: [email] });
  };

  const handleFieldChange = e => {
    setEmail(e.target.value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('emailAddress')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? t('emailAddress') : ''}
          value={email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Link className="fs--1 mb-0" to={`/`}>
            {t('backToLogin')}
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          onClick={handleSubmit}
          color="primary"
          className="mt-3 w-100"
          disabled={!email}
        >
          {t('resetPassword')}
        </Button>
      </Form.Group>
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

ForgotPasswordForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default ForgotPasswordForm;
