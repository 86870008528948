import React, { useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useGetProductTypesQuery } from 'redux/endpoints/productTypesApi';
import { v4 as uuidv4 } from 'uuid';
import { useCreateContainersMutation } from 'redux/endpoints/containersApi';
import useError from 'hooks/useError';

const ProductInputSelect = ({
  handleSelectChange,
  index,
  handleContainersAmount
}) => {
  const { data, error } = useGetProductTypesQuery();
  const { checkError } = useError();

  if (error) {
    checkError(error);
  }

  return (
    <Col
      lg={12}
      className="d-flex flex-direction-row justify-content-around mb-4"
    >
      <Col lg={5}>
        <Form.Group>
          <Form.Label>Tipo de producto</Form.Label>
          {data && (
            <Form.Select onChange={e => handleSelectChange(e, index)}>
              <option key="none0">Selecciona uno</option>
              {data?.map((element, index) => (
                <option key={index} value={element.id}>
                  {element.code}
                </option>
              ))}
            </Form.Select>
          )}
        </Form.Group>
      </Col>
      <Col lg={5}>
        <Form.Group>
          <Form.Label>Número de etiquetas</Form.Label>
          <Form.Control
            name="quantity"
            type="number"
            min="0"
            onChange={e => handleContainersAmount(e, index)}
          />
        </Form.Group>
      </Col>
    </Col>
  );
};

ProductInputSelect.propTypes = {
  handleSelectChange: PropTypes.func,
  index: PropTypes.number,
  handleContainersAmount: PropTypes.func
};

const ContainersCreation = () => {
  const user = useSelector(state => state.persistedReducer.user);

  const bodyObject = {
    currentOwnerId: user.id,
    requestId: uuidv4(),
    productTypeId: '',
    containerList: []
  };
  const { checkError } = useError();
  const [inputsData, setInputsData] = useState([bodyObject]);
  const [createContainers, { data, error, reset }] =
    useCreateContainersMutation();

  const handleSelectChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputsData];
    list[index]['productTypeId'] = value;
    setInputsData(list);
  };

  const handleContainersAmount = (e, index) => {
    const { value } = e.target;
    const list = [...inputsData];
    list[index]['containerList'] = [];
    for (let i = 0; i < value; i++) {
      list[index]['containerList'].push({ containerId: uuidv4() });
    }
    setInputsData(list);
  };

  const handleSubmit = e => {
    e.preventDefault();
    inputsData.filter(
      element =>
        element.productTypeId === '' || element.containerList.length === 0
    ).length > 0
      ? toast.error('Debes completar todos los campos')
      : inputsData.forEach(element => {
          createContainers(element);
        });
  };

  if (error) {
    checkError(error);
  }

  if (data) {
    toast.success('Envases creados correctamente');
    reset();
  }

  return (
    <Card>
      <Card.Header>
        <Card.Title>Crear envase</Card.Title>
      </Card.Header>
      <Card.Body className="">
        <Form onSubmit={handleSubmit}>
          {inputsData.map((input, index) => (
            <div key={index}>
              <ProductInputSelect
                handleContainersAmount={handleContainersAmount}
                handleSelectChange={handleSelectChange}
                index={index}
              />
            </div>
          ))}

          <Col lg={12} className="d-flex flex-direction-row flex-wrap">
            <Col lg={12} className=" ms-4 mb-4">
              <Card.Link
                style={{ cursor: 'pointer' }}
                className="text-linkedin ms-1"
                onClick={() =>
                  setInputsData([...inputsData, { ...bodyObject }])
                }
              >
                Añadir otro
              </Card.Link>
            </Col>
            <Col lg={12} className="ms-4">
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Col>
          </Col>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ContainersCreation;
