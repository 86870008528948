import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  scannedContainers: []
};

const containerReducer = createSlice({
  name: 'containers',
  initialState,
  reducers: {
    addContainer: (state, action) => {
      if (
        !state.scannedContainers.some(
          container => container.id === action.payload.id
        )
      ) {
        state.scannedContainers = [...state.scannedContainers, action.payload];
      }
    },
    removeContainer: (state, action) => {
      state.scannedContainers = state.scannedContainers.filter(
        container => container.id !== action.payload
      );
    },
    clearContainers: state => {
      state.scannedContainers = [];
    }
  }
});

export const { addContainer, removeContainer, clearContainers } =
  containerReducer.actions;

export default containerReducer.reducer;
