import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TooltipBadge from 'components/common/TooltipBadge';
import { t } from 'i18next';

const PartnerStats = ({ data }) => {
  const totalContainersLent = data.reduce(
    (acc, curr) => acc + +curr.lendCount,
    0
  );
  const totalActiveUsers = data.reduce((acc, curr) => acc + +curr.userCount, 0);
  const totalBowlsLent = data.reduce(
    (acc, curr) => acc + +curr.bowlsLentCount,
    0
  );
  const totalCupsLent = data.reduce((acc, curr) => acc + +curr.cupLentCount, 0);
  const totalWasteAvoided = (
    (totalBowlsLent * 20.8 + totalCupsLent * 18) /
    1000
  ).toFixed(2);
  const totalCo2Avoided = (
    (totalBowlsLent * 20 + totalCupsLent * 17) /
    1000
  ).toFixed(2);

  const stats = [
    {
      title: 'Envases evitados',
      value: totalContainersLent
    },
    {
      title: 'Residuos evitados',
      value: `${totalWasteAvoided} Kg`
    },
    {
      title: 'CO2 evitado',
      value: `${totalCo2Avoided} Kg`
    },
    {
      title: 'Usuarios activos',
      value: totalActiveUsers
    }
  ];
  const tooltipText = [
    t('tooltip1'),
    t('tooltip2'),
    t('tooltip2'),
    t('tooltip3')
  ];

  return (
    <Card>
      <Card.Body className="py-5 py-sm-3">
        <Row className="g-5 g-sm-0">
          {stats.map((stat, index) => (
            <Col sm={3} key={stat.title}>
              <div
                className={classNames({
                  'border-sm-end border-300': index !== 3
                })}
              >
                <div className="text-center">
                  <h6 className="text-700">
                    {stat.title}
                    <TooltipBadge
                      tooltip={tooltipText[index]}
                      icon="question-circle"
                    />
                  </h6>

                  <h3 className="fw-normal text-700">{stat.value}</h3>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

PartnerStats.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      billingGroupId: PropTypes.string.isRequired,
      partnerId: PropTypes.string.isRequired,
      partnerName: PropTypes.string.isRequired,
      lentAt: PropTypes.string.isRequired,
      userCount: PropTypes.string.isRequired,
      lendCount: PropTypes.string.isRequired,
      totalLendsPercentDifferenceWithLastMonth: PropTypes.string.isRequired,
      bowlsLentCount: PropTypes.string.isRequired,
      bowlLentPercentDifferenceWithLastMonth: PropTypes.string.isRequired,
      cupLentCount: PropTypes.string.isRequired,
      cupLentPercentDifferenceWithLastMonth: PropTypes.string.isRequired,
      partners: PropTypes.number.isRequired
    })
  )
};

export default PartnerStats;
