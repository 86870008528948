import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ContainerDetailsHeader from 'components/container-details/ContainerDetailsHeader';
import {
  useGetContainerJourneyQuery,
  useGetSingleContainerQuery
} from 'redux/endpoints/containersApi';
import ContainerDetailsInfo from 'components/container-details/ContainerDetailsInfo';
import ContainerDetailsMovements from 'components/container-details/ContainerDetailsMovements';
import useError from 'hooks/useError';

const ContainerDetails = () => {
  const { checkError } = useError();
  const id = location.pathname.replace('/container-details/', '');
  const [offset, setOffset] = useState(0);

  const { data, isLoading, error } = useGetSingleContainerQuery(id, {
    refetchOnMountOrArgChange: true
  });
  const { data: journeyData, error: journeyError } =
    useGetContainerJourneyQuery(
      { id: id, from: offset },
      {
        refetchOnMountOrArgChange: true
      }
    );

  if (error) {
    checkError(error);
  }
  if (journeyError) {
    checkError(journeyError);
  }

  return (
    <>
      {isLoading && (
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      {data && (
        <>
          <ContainerDetailsHeader data={data?.data} />
          <ContainerDetailsInfo
            data={data?.data}
            journey={journeyData && journeyData}
          />

          {journeyData && (
            <ContainerDetailsMovements
              journeyData={journeyData}
              setOffset={setOffset}
            />
          )}
        </>
      )}
    </>
  );
};

ContainerDetails.propTypes = {
  partnerId: PropTypes.string
};
export default ContainerDetails;
