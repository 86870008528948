import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productTypes: []
};

const productTypeReducer = createSlice({
  name: 'productTypes',
  initialState,
  reducers: {
    saveProductTypes: (state, action) => {
      state.productTypes = action.payload;
    }
  }
});

export const { saveProductTypes } = productTypeReducer.actions;
export default productTypeReducer.reducer;
