import React, { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRouteAdmin = props => {
  const user = useSelector(state => state.persistedReducer.user);
  const navigate = useNavigate();

  if (!user) return <Navigate to="/" />;

  useEffect(() => {
    if (user.type !== 'admin') {
      navigate(-1);
    }
  }, [user]);

  if (user.type === 'admin') {
    return <Outlet {...props} />;
  } else if (user.type !== 'admin') {
    navigate(-1);
  }
};

export default ProtectedRouteAdmin;
