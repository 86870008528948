import Flex from 'components/common/Flex';
import { svgSelectorDetailed } from 'components/utilities/svgSelector';
import React from 'react';

export const columns = [
  {
    accessor: 'envases',
    Header: 'Envases',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">
              {svgSelectorDetailed(rowData.row.original.code)}{' '}
              {rowData.row.original.code}
            </h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'stock',
    Header: 'Stock',
    Cell: rowData => {
      return <a href="#">{rowData.row.original.count}</a>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    }
  }
];
