import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { useGetContainerListQuery } from 'redux/endpoints/containersApi';
import useError from 'hooks/useError';
import { columns } from 'components/container-details/columns';
import { detailsStockColumn } from 'components/container-details/detailsStockColumn';

const DetailsStock = ({ id, containersAssigned }) => {
  const navigation = useNavigate();
  const { checkError } = useError();
  const [showStock, setShowStock] = useState(false);
  const { data: containersData, error: containersError } =
    useGetContainerListQuery(
      {
        field: 'currentOwnerId',
        operator: '=',
        value: id,
        from: 0,
        limit: 1000
      },
      { refetchOnMountOrArgChange: true }
    );

  if (containersError) {
    checkError(containersError);
  }

  return (
    <>
      {containersAssigned?.containersAssignedByProductType?.length > 0 ? (
        <AdvanceTableWrapper
          columns={showStock ? detailsStockColumn : columns}
          data={
            showStock
              ? containersData?.data || []
              : containersAssigned?.containersAssignedByProductType || []
          }
          pagination
          perPage={10}
        >
          <Card className="mb-3">
            <Card.Body className="p-0">
              <Card.Header className="d-flex justify-content-between align-center">
                <Card.Title>Stock del centro</Card.Title>
                {showStock ? (
                  <Button
                    className="me-4"
                    style={{ width: '250px' }}
                    variant="primary"
                    onClick={() => navigation(`/bulk-transfer/${id}`)}
                  >
                    Transferir
                  </Button>
                ) : (
                  <Button
                    className="me-4"
                    style={{ width: '250px' }}
                    variant="primary"
                    onClick={() => setShowStock(true)}
                  >
                    Ver stock
                  </Button>
                )}
              </Card.Header>
              {showStock ? (
                <>
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                  <Card.Footer className="mt-3">
                    <AdvanceTableFooter
                      rowCount={containersData?.pagination.total}
                      table
                      rowInfo
                      navButtons
                    />
                  </Card.Footer>
                </>
              ) : (
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card className="mb-3 d-flex">
          <Card.Header>
            <Card.Title>Sin envases asignados</Card.Title>
          </Card.Header>
        </Card>
      )}
    </>
  );
};

DetailsStock.propTypes = {
  id: PropTypes.string,
  containersAssigned: PropTypes.object
};

export default DetailsStock;
