import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  price: []
};

const price = createSlice({
  name: 'price',
  initialState,
  reducers: {
    savePrice: (state, action) => {
      state.price = action.payload;
    }
  }
});

export const { savePrice } = price.actions;
export default price.reducer;
