import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from 'env/env';
export const paymentApiV2 = createApi({
  reducerPath: 'paymentApiV2',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState().persistedReducer.user;
      headers.set('authorization', `Bearer ${accessToken}`);
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    getProducts: builder.query({
      query: () => ({
        url: 'productGroups',
        method: 'GET'
      }),
      transformResponse: response => response.data
    }),
    getPlans: builder.mutation({
      query: params => ({
        url: `/prices`,
        method: 'GET',
        params: {
          ...(params.value &&
            params.value !== '' && {
              ['filters[0][field]']: 'productGroupId',
              ['filters[0][operator]']: '=',
              ['filters[0][value]']: params.value
            }),
          ...(params.type &&
            params.type !== '' && {
              ['filters[1][field]']: 'type',
              ['filters[1][operator]']: '=',
              ['filters[1][value]']: params.type,
              dir: 'ASC',
              order: 'name'
            })
        }
      }),
      transformResponse: response => response.data
    }),
    getSubscriptionById: builder.query({
      query: subscriptionId => ({
        url: `subscriptions/${subscriptionId}`,
        method: 'GET'
      }),
      transformResponse: response => response.data
    }),
    getSubscriptions: builder.query({
      query: params => ({
        url: 'subscriptions',
        method: 'GET',
        params: {
          ...(params.value &&
            params.value !== '' && {
              ['filters[0][field]']: 'userId',
              ['filters[0][operator]']: '=',
              ['filters[0][value]']: params.value
            }),
          ...(params.status &&
            params.status !== '' && {
              ['filters[0][field]']: 'status',
              ['filters[0][operator]']: '=',
              ['filters[0][value]']: params.status
            }),
          limit: params.limit,
          offset: params.from
        }
      }),
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    updateSubscriptionById: builder.mutation({
      query: body => ({
        url: `subscriptions/${body.id}`,
        method: 'PUT',
        body
      }),
      transformResponse: () => 'No reponse data'
    }),
    addSubscription: builder.mutation({
      query: body => ({
        url: `subscriptions`,
        method: 'POST',
        body: {
          id: body.id,
          productGroupId: body.productGroupId,
          priceId: body.priceId,
          userId: body.userId,
          interval: body.interval,
          startDate: body.startDate,
          startPeriodDate: body.startPeriodDate,
          endDate: body.endDate,
          partnerId: body.partnerId
        }
      }),
      transformResponse: () => 'No response data'
    })
  })
});

export const {
  useGetPlansMutation,
  useGetProductsQuery,
  useGetSubscriptionsQuery,
  useAddSubscriptionMutation,
  useGetSubscriptionByIdQuery,
  useUpdateSubscriptionByIdMutation
} = paymentApiV2;
