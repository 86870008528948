import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productGroups: []
};

const productGroupsReducer = createSlice({
  name: 'productGroups',
  initialState,
  reducers: {
    saveProductGroups: (state, action) => {
      state.productGroups = action.payload;
    }
  }
});

export const { saveProductGroups } = productGroupsReducer.actions;
export default productGroupsReducer.reducer;
