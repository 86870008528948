const timeConverter = time => {
  const newDate = new Date(time);
  const day =
    newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
  const month =
    newDate.getMonth() + 1 < 10
      ? '0' + (newDate.getMonth() + 1)
      : newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const finalDate = day + '/' + month + '/' + year;
  return finalDate;
};

export const exactTimeConverter = time => {
  const newDate = new Date(time);
  const day =
    newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
  const month =
    newDate.getMonth() + 1 < 10
      ? '0' + (newDate.getMonth() + 1)
      : newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  const hour =
    newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
  const minutes =
    newDate.getMinutes() < 10
      ? '0' + newDate.getMinutes()
      : newDate.getMinutes();
  const finalDate = day + '/' + month + '/' + year + ' ' + hour + ':' + minutes;
  return finalDate;
};
//2023-01-10T09:13:04.836Z
export default timeConverter;
