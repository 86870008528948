import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useHistory = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [previousPath, setPreviousPath] = useState(null);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setPreviousPath(currentPath);
      setCurrentPath(location.pathname);
    }
  }, [location.pathname, currentPath]);

  return { currentPath, previousPath };
};

export default useHistory;
