import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from 'env/env';

export const scanApi = createApi({
  reducerPath: 'scanApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENV.ENDPOINT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().persistedReducer.user.accessToken;
      headers.set('authorization', `Bearer ${token}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    collectContainer: builder.mutation({
      query: body => ({
        url: 'lends',
        method: 'PUT',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    collectContainerInBulk: builder.mutation({
      query: body => ({
        url: 'lends/bulk',
        method: 'PUT',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    lendContainer: builder.mutation({
      query: body => ({
        url: 'lends',
        method: 'POST',
        body
      }),
      transformResponse: () => 'No content success'
    }),

    lendInBulk: builder.mutation({
      query: body => ({
        url: 'lends/bulk',
        method: 'POST',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    assignContainer: builder.mutation({
      query: body => ({
        url: 'movements',
        method: 'POST',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    retrieveContainer: builder.mutation({
      query: body => ({
        url: 'movements',
        method: 'POST',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    movementsInBulk: builder.mutation({
      query: body => ({
        url: 'movements/bulk',
        method: 'POST',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    getContainersFromId: builder.mutation({
      query: arg => {
        return {
          url: 'v2/product/container',
          method: 'GET',
          params: {
            user_id: arg.user_id,
            query: arg.query,
            from: arg.from,
            limit: 1000
          }
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    transferContainersBetweenPartners: builder.mutation({
      query: body => ({
        url: '/movements/transfer/bulk',
        method: 'POST',
        body
      }),
      transformResponse: () => 'No content success'
    }),
    lendsList: builder.query({
      query: params => ({
        url: 'lends',
        method: 'GET',
        params: {
          ...(params.value &&
            params.value !== '' && {
              ['filters[0][field]']: 'lendeeId',
              ['filters[0][operator]']: '=',
              ['filters[0][value]']: params.value
            }),

          limit: params.limit || 5,
          offset: params.from,
          dir: 'desc',
          order: 'createdAt'
        }
      }),
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    })
  })
});

export const {
  useCollectContainerMutation,
  useLendContainerMutation,
  useAssignContainerMutation,
  useRetrieveContainerMutation,
  useGetContainersFromIdMutation,
  useLendInBulkMutation,
  useMovementsInBulkMutation,
  useCollectContainerInBulkMutation,
  useTransferContainersBetweenPartnersMutation,
  useLendsListQuery
} = scanApi;
