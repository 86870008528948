import React from 'react';
import { Link, useHref, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { doLogout } from 'redux/reducers/loginReducer';
import bumerangAvatar from '../../../assets/img/bumerang-assets/avatar-bumerang.svg';
import { t } from 'i18next';

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.persistedReducer.user);
  const currentPath = useHref();

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={bumerangAvatar} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {currentPath === '/profile' ? (
            <Dropdown.Item onClick={() => navigate(`/billing-group-dashboard`)}>
              {t('dashboard')}
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              onClick={() =>
                user.type === 'billing_group'
                  ? navigate(`/profile`)
                  : navigate(`/user-details/${user?.id}`)
              }
            >
              {t('profile')}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => dispatch(doLogout())}
            as={Link}
            to="/login"
          >
            {t('logout')}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
