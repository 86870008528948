import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '../../env/env';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}auth/`,
    prepareHeaders: headers => {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    userLogin: builder.mutation({
      query(body) {
        return {
          url: 'login',
          method: 'POST',
          body
        };
      },
      transformResponse: response => response.data
    }),
    resetPasswordBillingGroup: builder.mutation({
      query: data => ({
        url: `resetPassword/billingGroup`,
        method: 'PUT',
        body: data
      }),
      transformResponse: () => 'No content response'
    }),
    requestPasswordBillingGroup: builder.mutation({
      query: data => ({
        url: `/launch/billingGroupDashboard`,
        method: 'POST',
        body: data
      }),
      transformResponse: () => 'No content response'
    })
  })
});

export const {
  useUserLoginMutation,
  useResetPasswordBillingGroupMutation,
  useRequestPasswordBillingGroupMutation
} = authApi;
