import ProductModal from 'components/product/ProductModal';
import useError from 'hooks/useError';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  useCreateProductGroupMutation,
  useGetProductGroupsQuery
} from 'redux/endpoints/productGroups';

const ProductGroup = () => {
  const { checkError } = useError();
  const [showModal, setShowModal] = useState(false);
  const [
    createProductGroup,
    { data: productGroupData, error: productGroupError }
  ] = useCreateProductGroupMutation();

  const { data, error, refetch } = useGetProductGroupsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  if (error) {
    checkError(error);
  }

  useEffect(() => {
    if (productGroupData) {
      toast.success('Grupo creado con éxito');
      setShowModal(false);
      refetch();
    }
  }, [productGroupData]);

  useEffect(() => {
    if (productGroupError) {
      checkError(productGroupError);
    }
  }, [productGroupError]);

  return (
    <>
      <Card className="p-3">
        <Card.Header className="d-flex flex-row justify-content-between">
          <Card.Title as="h5">Product Group</Card.Title>
          <Button onClick={() => setShowModal(true)} variant="primary">
            Crear grupo
          </Button>
        </Card.Header>
        <Card.Body className="d-flex flex-wrap row justify-content-between">
          {data?.map(productGroup => (
            <Card
              style={{ backgroundColor: 'rgb(249,	250, 253)' }}
              key={productGroup.id}
              className="m-3 col-md-3"
            >
              <Card.Title className="ps-4 pt-4" as="h6">
                {productGroup.name}
              </Card.Title>
              <hr />
              <Card.Body className="d-flex flex-column">
                <Card.Text>
                  Código: <strong>{productGroup.code}</strong>
                </Card.Text>
                <Card.Text>
                  Nombre de grupo: <strong>{productGroup.name}</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
      </Card>
      <ProductModal
        showModal={showModal}
        setter={setShowModal}
        productGroups={data}
        createProductGroup={createProductGroup}
      />
    </>
  );
};

export default ProductGroup;
