import timeConverter from 'components/utilities/timeConverter';
import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useGetUserQueryQuery } from 'redux/endpoints/userApi';

const DetailsHeader = ({ data, editData }) => {
  const handleCheckboxChange = event => {
    editData({ ...data, [event.target.name]: event.target.checked });
  };

  const { data: userData, error: userError } = useGetUserQueryQuery(
    data?.userId,
    { refetchOnMountOrArgChange: true }
  );
  if (userError) {
    console.log(userError);
  }

  return (
    <Card className="mb-2 p-3">
      <Row>
        <Col
          className="d-flex align-items-center justify-content-between"
          lg={12}
        >
          <Card.Header className="fw-bold w-100 fs-2">
            {data?.name} (
            {
              <a className="text-linkedin" href={`mailto:${data?.email}`}>
                {data?.email}
              </a>
            }
            )
          </Card.Header>
          <Card.Body className="p-0"></Card.Body>
        </Col>
        <Col
          lg={12}
          className="mt-3 d-flex border-top align-items-center justify-content-between p-3"
        >
          <Col className="d-flex align-items-center">
            <Col
              lg={1}
              className="d-flex align-items-center justify-content-center"
            >
              {data?.active ? (
                <div
                  style={{
                    bottom: '65px',
                    margin: 0,
                    height: '10px',
                    width: '10px',
                    backgroundColor: 'rgb(55, 126, 34)',
                    borderRadius: '50%'
                  }}
                />
              ) : (
                <div
                  style={{
                    margin: 0,
                    height: '10px',
                    width: '10px',
                    backgroundColor: 'rgb(159, 169, 185)',
                    borderRadius: '50%'
                  }}
                />
              )}
            </Col>
            <Col>
              <Card.Text>Partner creado el</Card.Text>
              <Card.Subtitle>{timeConverter(data?.createdAt)}</Card.Subtitle>
            </Col>
          </Col>
          <Col className="d-flex align-items-center">
            <Col
              lg={1}
              className="d-flex align-items-center justify-content-center m-2"
            >
              {userData?.paymentStatus === 'configured' ? (
                <div
                  style={{
                    bottom: '65px',
                    margin: 0,
                    height: '10px',
                    width: '10px',
                    backgroundColor: 'rgb(55, 126, 34)',
                    borderRadius: '50%'
                  }}
                />
              ) : (
                <div
                  style={{
                    margin: 0,
                    height: '10px',
                    width: '10px',
                    backgroundColor: 'rgb(159, 169, 185)',
                    borderRadius: '50%'
                  }}
                />
              )}
            </Col>
            <Col>
              <Card.Text>
                Método de pago{' '}
                {userData?.paymentStatus === 'configured'
                  ? 'activo'
                  : 'inactivo'}
              </Card.Text>
            </Col>
          </Col>
          <Col lg={7}>
            <Form.Group className="ms-4 d-flex" controlId="formGroupCheckboxes">
              <Col className="d-flex flex-column">
                <Col className="d-flex">
                  <Form.Check
                    onClick={handleCheckboxChange}
                    inline
                    defaultChecked={data?.displayed}
                    type="switch"
                    name="displayed"
                    value="displayed"
                    id="displayed"
                    className="m-0"
                  />
                  <Form.Label className="me-5">Mostrar en el mapa</Form.Label>
                </Col>
                <Col className="d-flex">
                  <Form.Check
                    inline
                    defaultChecked={data?.active}
                    onClick={handleCheckboxChange}
                    type="switch"
                    value="active"
                    name="active"
                    id="active"
                    className="m-0"
                  />
                  <Form.Label className="me-5">Activo</Form.Label>
                </Col>
              </Col>
              <Col className="d-flex flex-column">
                <Col className="d-flex">
                  <Form.Check
                    inline
                    onClick={handleCheckboxChange}
                    value="discount"
                    defaultChecked={data?.discount}
                    type="switch"
                    name="discount"
                    id="discount"
                    className="m-0"
                  />
                  <Form.Label className="me-5">Descuento</Form.Label>
                </Col>
                <Col className="d-flex">
                  <Form.Check
                    inline
                    onClick={handleCheckboxChange}
                    value="guestLending"
                    defaultChecked={data?.guestLending}
                    type="switch"
                    name="guestLending"
                    id="guestLending"
                    className="m-0"
                  />
                  <Form.Label className="me-5">Guest Lending</Form.Label>
                </Col>
              </Col>
            </Form.Group>
          </Col>
        </Col>
      </Row>
    </Card>
  );
};

DetailsHeader.propTypes = {
  data: PropTypes.object,
  editData: PropTypes.func
};

export default DetailsHeader;
