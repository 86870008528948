import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ENV } from 'env/env';

export const billingGroupsApi = createApi({
  reducerPath: 'billingGroupsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().persistedReducer.user.accessToken;
      headers.set('authorization', `Bearer ${token}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    getBillingGroupsList: builder.query({
      query: params => {
        return {
          url: 'billingGroups',
          method: 'GET',
          params: {
            ...(params.value &&
              params.value !== '' && {
                ['filters[0][field]']: 'query',
                ['filters[0][operator]']: 'icontains',
                ['filters[0][value]']: params.value
              }),

            limit: params.limit || 10,
            offset: params.from,
            dir: 'ASC',
            order: 'name'
          }
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getSingleBillingGroup: builder.query({
      query: id => {
        return {
          url: `billingGroups/${id}`,
          method: 'GET'
        };
      },
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    createBillingGroup: builder.mutation({
      query: data => ({
        url: 'billingGroups',
        method: 'POST',
        body: data
      }),
      transformResponse: () => 'No content response'
    }),
    editBillingGroup: builder.mutation({
      query: data => ({
        url: `billingGroups/${data.id}`,
        method: 'PATCH',
        body: data.body
      }),
      transformResponse: () => 'No content response'
    }),
    deleteBillingGroup: builder.mutation({
      query: id => ({
        url: `billingGroups/${id}`,
        method: 'DELETE',
        body: {}
      }),
      transformResponse: () => 'No content response'
    }),
    associateBillingGroup: builder.mutation({
      query: data => ({
        url: 'partners/linkToBillingGroup',
        method: 'POST',
        body: data.body
      }),
      transformResponse: () => 'No content response'
    }),
    getBillingGroupDashboard: builder.query({
      query: date => ({
        url: 'billingGroupsDashboard/me',
        params: {
          [`filters[0][field]`]: 'lentAt',
          [`filters[0][operator]`]: '>=',
          [`filters[0][value]`]: date.from,
          [`filters[1][field]`]: 'lentAt',
          [`filters[1][operator]`]: '<=',
          [`filters[1][value]`]: date.to
        }
      })
    }),
    getBillingGroupDashboardUnfiltered: builder.query({
      query: () => ({
        url: 'billingGroupsDashboard/me'
      })
    })
  })
});

export const {
  useGetBillingGroupsListQuery,
  useCreateBillingGroupMutation,
  useGetSingleBillingGroupQuery,
  useEditBillingGroupMutation,
  useDeleteBillingGroupMutation,
  useAssociateBillingGroupMutation,
  useGetBillingGroupDashboardQuery,
  useGetBillingGroupDashboardUnfilteredQuery
} = billingGroupsApi;
