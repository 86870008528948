import React from 'react';
import { Col, Row } from 'react-bootstrap';
import packageJson from '../../../package.json';

const Footer = () => {
  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            {new Date().getFullYear()} &copy;{' '}
            <a href="https://youbumerang.com">Bumerang</a>
          </p>
        </Col>
        <Col sm="auto">
          <p className="mb-0 text-600">v{packageJson.version}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
