//<span class="badge rounded-pill badge-soft-success">Success</span>
import React from 'react';
import { Badge } from 'react-bootstrap';
const badgeSelector = type => {
  switch (type) {
    case 'available':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="available"
          text="available-text"
          className="me-2"
        >
          Disponible
        </Badge>
      );
    case 'collected':
      return (
        <Badge style={{ width: '90px' }} pill bg="secondary" className="me-2">
          Recogido ⟳
        </Badge>
      );
    case 'lent':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="lent"
          text="lent-text"
          className="me-2"
        >
          Entregado →
        </Badge>
      );
  }
};

export const userBadgetType = type => {
  switch (type) {
    case 'partner':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="restaurant"
          text="restaurant-text"
          className="me-2"
        >
          Partner
        </Badge>
      );
    case 'admin':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="admin"
          text="admin-text"
          className="me-2"
        >
          Admin
        </Badge>
      );
    case 'customer':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="customer"
          text="customer-text"
          className="me-2"
        >
          Customer
        </Badge>
      );
  }
};

export const businessTypeBadge = type => {
  let parsedType;
  if (Array.isArray(type)) {
    parsedType = type[0];
  } else {
    parsedType = type;
  }

  switch (parsedType) {
    case 'restaurant':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="restaurant"
          text="restaurant-text"
          className="me-2"
        >
          Restaurant
        </Badge>
      );
    case 'partner':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="restaurant"
          text="restaurant-text"
          className="me-2"
        >
          Partner
        </Badge>
      );
    case 'delivery_service':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="delivery"
          text="delivery-text"
          className="me-2"
        >
          Delivery
        </Badge>
      );
    case 'canteen':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="canteen"
          text="canteen-text"
          className="me-2"
        >
          Cantina
        </Badge>
      );
    case 'cafeteria':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="cafeteria"
          text="cafeteria-text"
          className="me-2"
        >
          Cafetería
        </Badge>
      );
    case 'supermarket':
      return (
        <Badge
          pill
          style={{ width: '90px' }}
          bg="supermarket"
          text="supermarket-text"
          className="me-2"
        >
          Supermercado
        </Badge>
      );
  }
};

export const subscriptionStatusBadge = status => {
  switch (status) {
    case 'active':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Activa
        </Badge>
      );
    case 'cancelled':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="pending"
          text="pending-text"
          className="me-2"
        >
          Cancelada
        </Badge>
      );
    case 'lost':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="pending"
          text="pending-text"
          className="me-2"
        >
          Lost
        </Badge>
      );
    default:
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Activa
        </Badge>
      );
  }
};

export const lendStatusBadge = status => {
  switch (status) {
    case 'active':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="pending"
          text="pending-text"
          className="me-2"
        >
          Activo
        </Badge>
      );
    case 'charged':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="pending"
          text="pending-text"
          className="me-2"
        >
          Cobrado
        </Badge>
      );

    case 'collected':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Devuelto
        </Badge>
      );
    case 'in_progress':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          En progreso
        </Badge>
      );
    case 'not_refundable':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          No reembolsable
        </Badge>
      );
    case 'refunded':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Reembolsado
        </Badge>
      );
    case 'charge_failed':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Cobro fallido
        </Badge>
      );
    case 'refund_failed':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Reembolso fallido
        </Badge>
      );
    case 'pending_refund':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Reembolso pendiente
        </Badge>
      );
    case 'deposit':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Depósito
        </Badge>
      );
    default:
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          {status}
        </Badge>
      );
  }
};

export const paymentStatusBadge = type => {
  switch (type) {
    case 'configured':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Configurado
        </Badge>
      );
    case 'pending':
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="pending"
          text="pending-text"
          className="me-2"
        >
          Pendiente
        </Badge>
      );
    default:
      return (
        <Badge
          style={{ width: '90px' }}
          pill
          bg="configured"
          text="configured-text"
          className="me-2"
        >
          Configurado
        </Badge>
      );
  }
};

export default badgeSelector;
