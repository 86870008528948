import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  stockReports: []
};

const stockReportsReducer = createSlice({
  name: 'stockReports',
  initialState,
  reducers: {
    setStockReports: (state, action) => {
      state.stockReports = action.payload;
    },
    clearStockReports: state => {
      state.stockReports = [];
    }
  }
});

export const { setStockReports, clearStockReports } =
  stockReportsReducer.actions;
export default stockReportsReducer.reducer;
