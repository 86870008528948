import Flex from 'components/common/Flex';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import badgeSelector, {
  businessTypeBadge,
  userBadgetType
} from './badgeSelector';
import { svgSelectorDetailed } from './svgSelector';

export const containerColumnsBulkTransfer = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return (
        <Link to={`/container-details/${id}`}>
          <Flex alignItems="center">
            <div className="flex-1" style={{ width: '250px' }}>
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'product_type',
    Header: 'Product Type',
    Cell: rowData => {
      const { productType } = rowData.row.original;
      return (
        <Flex>
          <Card.Text>
            {svgSelectorDetailed(productType?.code)} {productType?.name}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: rowData => {
      const { status } = rowData.row.original;
      return <Flex>{badgeSelector(status)}</Flex>;
    }
  }
];

export const adminColumnsBulkTransfer = (setterUser, location) => [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name } = rowData.row.original;
      const user = rowData.row.original;
      return (
        <Link href="#">
          <Flex
            alignItems="center"
            onClick={() => {
              setterUser(user);
            }}
          >
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { email } = rowData.row.original;
      return (
        <Link href="#">
          <Flex>
            <div
              style={{ maxWidth: '250px', overflow: 'hidden' }}
              className="flex-1"
            >
              <h5 className="mb-0 fs--1">{email}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'type',
    Header: 'Tipo de negocio',
    Cell: rowData => {
      const { type } = rowData.row.original;
      let parsedType;
      if (Array.isArray(type)) {
        parsedType = type[0];
      } else {
        parsedType = type;
      }

      return location ? (
        <Flex>{userBadgetType(parsedType)}</Flex>
      ) : (
        <Flex>{businessTypeBadge(parsedType)}</Flex>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    }
  }
];
