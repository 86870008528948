import useError from 'hooks/useError';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetProductsQuery } from 'redux/endpoints/paymentApiV2';
import {
  useGetPriceByIdQuery,
  useUpdatePriceMutation
} from 'redux/endpoints/pricesApi';
import { v4 as uuidv4 } from 'uuid';

const pricesType = [
  { key: 'fixed', value: 'Fija' },
  { key: 'volume', value: 'Por uso' },
  { key: 'graduated', value: 'Por tramos' }
];

const EditPrice = () => {
  let defaultValues = {
    name: '',
    productGroupId: '',
    currency: 'EUR',
    type: '',
    tiers: [],
    tax: 21
  };

  const tierValue = {
    upTo: null,
    amount: 0
  };
  const [allowMoreTiers, setAllowMoreTiers] = useState(false);
  const navigate = useNavigate();
  const id = window.location.pathname.replace('/edit-price/', '');

  const { checkError } = useError();
  const { data: productsData, error: productsError } = useGetProductsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: priceData,
    isLoading,
    error: priceError
  } = useGetPriceByIdQuery(id, {
    refetchOnMountOrArgChange: true
  });
  const [formData, setFormData] = useState(
    priceData ? { ...priceData } : defaultValues
  );

  const [updatePrice, { data: newPriceData, error: newPriceError }] =
    useUpdatePriceMutation();

  if (productsError) {
    checkError(productsError);
  }

  if (priceError) {
    checkError(priceError);
  }

  const handleFormData = e => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };

    if (name === 'type') {
      if (value !== 'graduated') {
        newFormData.tiers = [newFormData.tiers[0]];
        setAllowMoreTiers(false);
        resetTiers();
      } else if (value === 'graduated') {
        setAllowMoreTiers(true);
      }
    }

    setFormData({
      ...newFormData,
      id: uuidv4(),
      [name]: value
    });
  };

  const resetTiers = () => {
    setFormData({ ...formData, tiers: [tierValue] });
  };

  const resetTiersOnChange = () => {
    if (priceData && formData?.type !== '') {
      setFormData({ ...priceData });
    }
  };

  const addNewTier = () => {
    const newTier = { ...tierValue, upTo: 0, amount: 0 };
    const newFormData = { ...formData };
    newFormData.tiers.push(newTier);
    setFormData(newFormData);
  };

  const handleTierChange = (index, fieldName, value) => {
    const newTiers = [...formData.tiers];
    const newTier = { ...newTiers[index] };

    if (fieldName === 'amount') {
      newTier[fieldName] = Number.isNaN(Number(value))
        ? 0
        : Math.floor(Number(value) * 100);
    }

    if (fieldName === 'upTo') {
      const upToValue = Number(value);
      newTier[fieldName] = Number.isNaN(upToValue) ? null : upToValue;
    }

    newTiers[index] = newTier;
    setFormData({ ...formData, tiers: newTiers });
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    const lastTierIndex = formData.tiers.length - 1;
    if (lastTierIndex >= 0 && formData.tiers[lastTierIndex].upTo !== null) {
      setFormData(prevData => {
        const tiers = [...prevData.tiers];
        tiers[lastTierIndex].upTo = null;
        return { ...prevData, tiers };
      });
    }

    updatePrice({ body: formData, id: priceData?.id });
  };
  /* 
  const areTiersValid = () => {
    const tiers = formData.tiers;
    if (formData.type === 'graduated') {
      for (const tier of tiers) {
        if (
          typeof tier.upTo !== 'number' ||
          isNaN(tier.upTo) ||
          tier.upTo < 0
        ) {
          return false;
        }
        if (
          typeof tier.amount !== 'number' ||
          isNaN(tier.amount) ||
          tier.amount < 0
        ) {
          return false;
        }
      }
    }
    return true;
  };
 */
  useEffect(() => {
    resetTiersOnChange();
  }, [priceData]);

  useEffect(() => {
    if (newPriceData) {
      toast.success('Tarifa creada correctamente', { position: 'top-center' });
      navigate('/prices');
    }
  }, [newPriceData]);

  useEffect(() => {
    if (newPriceError) {
      checkError(newPriceError);
    }
  }, [newPriceError]);

  useEffect(() => {
    if (priceData && !isLoading) {
      if (priceData?.type === 'graduated') {
        setAllowMoreTiers(true);
      }
      setFormData({ ...priceData });
    }
  }, [priceData, isLoading]);

  return (
    <Card>
      <Card.Header className="mt-4 ms-5" as="h5">
        {isLoading ? 'Cargando...' : `Editar Tarifa ${priceData?.name}`}
        {isLoading && <Spinner animation="border" variant="primary" />}
      </Card.Header>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col className="ps-lg-4 my-0 text-lg-left">
            <Form>
              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  required
                  value={formData?.name}
                  name="name"
                  onChange={handleFormData}
                  type="text"
                  placeholder="Nombre"
                />
              </Form.Group>

              <Form.Group controlId="productGroupId" className="mb-3">
                <Form.Label className="me-5">Grupo de producto</Form.Label>
                <Form.Select
                  name="productGroupId"
                  value={formData?.productGroupId}
                  onChange={handleFormData}
                >
                  <option value="">Selecciona un grupo de producto</option>
                  {productsData?.map(element => (
                    <option key={element.id} value={element.id}>
                      {element.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupCurrency">
                <Form.Label>Divisa</Form.Label>
                <Form.Control
                  disabled
                  onChange={handleFormData}
                  contentEditable={false}
                  name="currency"
                  type="text"
                  placeholder="Euros €"
                />
              </Form.Group>
              <Form.Group controlId="formGroupType" className="mb-3">
                <Form.Label className="me-5">Tipo de tarifa</Form.Label>
                <Form.Select
                  name="type"
                  value={formData?.type}
                  onChange={handleFormData}
                >
                  <option>Selecciona un tipo de tarifa</option>
                  {pricesType.map((element, key) => (
                    <option key={key} value={element.key}>
                      {element.value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              {formData?.tiers.map((tierItem, index) => (
                <React.Fragment key={index}>
                  {allowMoreTiers && (
                    <Form.Group
                      className="mb-3"
                      controlId={`formGroupUpTo${index}`}
                    >
                      <Form.Label>Tramo hasta</Form.Label>
                      <Form.Control
                        required
                        name="upTo"
                        min={0}
                        value={
                          formData?.tiers[index]?.upTo === null
                            ? 0
                            : formData?.tiers[index]?.upTo
                        }
                        onChange={e =>
                          handleTierChange(index, 'upTo', e.target.value)
                        }
                        type="number"
                        placeholder="Escribe el tramo"
                      />
                    </Form.Group>
                  )}

                  <Form.Group
                    className="mb-3"
                    controlId={`formGroupAmount${index}`}
                  >
                    <Form.Label>Precio</Form.Label>
                    <Form.Control
                      required
                      name="amount"
                      min={0}
                      value={formData?.tiers[index]?.amount / 100}
                      onChange={e =>
                        handleTierChange(index, 'amount', e.target.value)
                      }
                      type="number"
                      placeholder="Escribe la cantidad"
                    />
                  </Form.Group>
                </React.Fragment>
              ))}
              {allowMoreTiers && (
                <Link className="text-linkedin" onClick={addNewTier}>
                  <Card.Text>Añadir cantidad para siguiente tramo</Card.Text>
                </Link>
              )}

              <Form.Group className="mb-3" controlId="formGroupTaxes">
                <Form.Label>Impuestos</Form.Label>
                <Form.Control
                  disabled
                  onChange={handleFormData}
                  contentEditable={false}
                  name="tax"
                  type="number"
                  placeholder="21%"
                />
              </Form.Group>

              <Button
                onClick={handleFormSubmit}
                className="mb-6 mt-3"
                variant="primary"
                type="submit"
              >
                Guardar
              </Button>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EditPrice;
