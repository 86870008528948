import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { exactTimeConverter } from 'components/utilities/timeConverter';

const ContainerDetailsMovements = ({ setOffset, journeyData }) => {
  return (
    <Card className="mb-3 p-3">
      <Card.Header>
        <Card.Title>Logs</Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <Row>
          {journeyData?.data.map((element, index) => {
            return (
              <Col
                key={index}
                lg={12}
                className="border-bottom d-flex justify-content-between"
              >
                <Card.Text className="my-0 mx-3">
                  Movement from {element?.giver.name} to{' '}
                  {element?.receiver.name}
                </Card.Text>{' '}
                <Card.Text className="my-0 mx-3">
                  {exactTimeConverter(element.createdAt)}
                </Card.Text>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
      <Card.Footer className="d-flex flex-wrap justify-content-center">
        <Col lg={12} className="d-flex justify-content-center">
          <Card.Text className="fs-0">
            {journeyData?.pagination.length + journeyData?.pagination.offset} de{' '}
            {journeyData?.pagination.total} movimientos
          </Card.Text>
        </Col>
        <Card.Link
          style={{ cursor: 'pointer' }}
          className="text-linkedin"
          onClick={() =>
            setOffset(
              journeyData?.pagination.offset - journeyData?.pagination.limit
            )
          }
        >
          Cargar anteriores
        </Card.Link>
        <Card.Link
          style={{ cursor: 'pointer' }}
          className="text-linkedin"
          onClick={() => {
            setOffset(
              journeyData?.pagination.offset + journeyData?.pagination.limit
            );
          }}
        >
          Cargar siguientes
        </Card.Link>
      </Card.Footer>
    </Card>
  );
};

ContainerDetailsMovements.propTypes = {
  setOffset: PropTypes.func,
  journeyData: PropTypes.object
};

export default ContainerDetailsMovements;
