import React, { useRef } from 'react';
import { Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import Logo from 'components/common/Logo';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';

const NavbarTopBillingGroupDashboard = () => {
  return (
    <Navbar className="navbar-top navbar-expand-lg navbar-light bg-white shadow-sm px-5">
      <NavbarTopElements />
    </Navbar>
  );
};

const NavbarTopElements = () => {
  const burgerMenuRef = useRef();
  return (
    <>
      <Navbar.Toggle
        ref={burgerMenuRef}
        className={classNames('toggle-icon-wrapper me-md-3 me-2')}
        as="div"
      ></Navbar.Toggle>
      <Logo at="navbar-top" width={40} id="topLogo" />
      <TopNavRightSideNavItem />
    </>
  );
};

export default NavbarTopBillingGroupDashboard;
