import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

const LocationInput = ({ setCompleteForm, completeForm, placeholder }) => {
  const [addr, setAddr] = useState('');

  const handleSelect = async address => {
    await geocodeByAddress(address).then(results => {
      setAddr(results[0].formatted_address);
      getLatLng(results[0])
        .then(latLng => {
          setCompleteForm({
            ...completeForm,
            lat: latLng.lat,
            lng: latLng.lng,
            placeId: results[0].place_id,
            address: results[0].formatted_address
          });
        })
        .catch(error => console.error('Error', error));
    });
  };
  return (
    <PlacesAutocomplete value={addr} onChange={setAddr} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div key={'main'}>
          <Form.Group className="mb-3" controlId="formGroupAddress">
            <Form.Label>Buscar en Google Places</Form.Label>
            <Form.Control
              {...getInputProps({})}
              type="text"
              placeholder={placeholder}
            />
          </Form.Group>

          <div
            key={'list'}
            style={{
              width: '49vw',
              backgroundColor: 'white',
              zIndex: 1
            }}
            className="autocomplete-dropdown-container"
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              return (
                <div
                  style={{
                    marginTop: '5px',
                    marginBottom: '5px',
                    borderBottom: '1px solid gray'
                  }}
                  key={`item${index}`}
                  {...getSuggestionItemProps(suggestion)}
                >
                  <span
                    style={{
                      fontSize: 17,
                      maxWidth: 600,
                      marginBottom: 5
                    }}
                  >
                    {suggestion.description}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

LocationInput.propTypes = {
  setCompleteForm: PropTypes.func,
  completeForm: PropTypes.object,
  setAddress: PropTypes.func,
  placeholder: PropTypes.string
};
export default LocationInput;
