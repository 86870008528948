import PartnersTableHeader from 'components/partners-table/PartnersTable';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import timeConverter from 'components/utilities/timeConverter';
import React from 'react';
import { Button, Card, Dropdown, Image, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import emptyState from '../assets/img/bumerang-assets/21.svg';
import { useGetBillingGroupsListQuery } from 'redux/endpoints/billingGroupsApi';
import useError from 'hooks/useError';
import { useDispatch } from 'react-redux';
import { setBillingGroups } from 'redux/reducers/billingGroupsReducer';
import Pagination from 'components/pagination/Pagination';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre del grupo',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return (
        <Link to={`/billing-group/${id}`}>
          <Flex alignItems="center">
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Teléfono',
    Cell: rowData => {
      const { phone } = rowData.row.original;
      return (
        <Flex>
          <Card.Text>{phone ? phone : 'No proporcionado'}</Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: rowData => {
      const { email } = rowData.row.original;
      return <Flex>{email}</Flex>;
    }
  },
  {
    accessor: 'centers',
    Header: 'Centros',
    Cell: rowData => {
      const { partners } = rowData.row.original;
      return <Flex>{partners ? partners : 0}</Flex>;
    }
  },
  {
    accessor: 'createdAt',
    Header: 'Fecha de creación',
    Cell: rowData => {
      const { createdAt } = rowData.row.original;
      return <Flex>{timeConverter(createdAt)}</Flex>;
    }
  },

  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href={`/edit-billing/${id}`}>Editar</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const BillingList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const query = window.location.search;
  const queryValue = query.replace('?query=', '');
  const finalQuery = queryValue.split('%20').join(' ');
  const { data, isLoading, error } = useGetBillingGroupsListQuery(
    { from: (params.page - 1) * 10, value: finalQuery },
    { refetchOnMountOrArgChange: true }
  );
  const { checkError } = useError();
  const dispatch = useDispatch();
  if (error) checkError(error);
  if (data) {
    dispatch(setBillingGroups(data?.data));
  }

  return (
    <Card style={{ height: '100%' }}>
      <Card.Header className="border-bottom">
        <PartnersTableHeader table />
      </Card.Header>
      {(data?.data && data?.data.length > 0) || isLoading ? (
        <>
          {isLoading ? (
            <Card.Body className="d-flex justify-content-center">
              <Spinner className="align-self-center" />
            </Card.Body>
          ) : (
            <Card.Body className="p-0 pb-3">
              <AdvanceTableWrapper
                columns={columns}
                data={data?.data || []}
                pagination
                perPage={25}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <Pagination data={data} />
              </AdvanceTableWrapper>
            </Card.Body>
          )}
        </>
      ) : (
        <Card.Body className="d-flex align-items-center justify-content-center">
          <div className="d-flex flex-row justify-content-center align-items-center p-5">
            <Image src={emptyState} />
            <div className="p-2">
              <Card.Title as="h4">Todavía no has añadido nada.</Card.Title>
              <Card.Text>Añade el primer grupo a tu lista</Card.Text>
              <Button
                variant="primary"
                size="sm"
                style={{ height: '35px', width: '150px' }}
                className="text-center me-3"
                onClick={() => {
                  navigate('/billing-group/new');
                }}
              >
                Empezar
              </Button>
            </div>
          </div>
        </Card.Body>
      )}
    </Card>
  );
};

export default BillingList;
