import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import timeConverter from 'components/utilities/timeConverter';

const UserDetailsInfo = ({ data }) => {
  return (
    <Card className="mb-2 p-3">
      <Row>
        <Col
          className="d-flex align-items-center justify-content-between"
          lg={12}
        >
          <Card.Header className="fw-bold w-100 fs-2">Detalles</Card.Header>
          <Card.Body className="p-0"></Card.Body>
        </Col>
        <Col
          lg={12}
          className="mt-3 d-flex border-top align-items-center justify-content-between p-3"
        >
          <Row className="p-3">
            <Card.Title className="mb-3">Información de la cuenta</Card.Title>
            <Col lg={6} className="">
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={6}>
                  <Card.Text className="m-1">Tipo de usuario:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">{data?.type}</Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={6}>
                  <Card.Text className="m-1">Estado método de pago:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">{data?.paymentStatus}</Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={6}>
                  <Card.Text className="m-1">Tarjeta añadida:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.whenWasTheCardAdded
                      ? timeConverter(data?.whenWasTheCardAdded)
                      : 'No se ha añadido tarjeta'}
                  </Card.Text>
                </Col>
              </Col>
            </Col>
            <Col lg={6}>
              <Col className="d-flex flex-direction-row justify-content-start">
                <Col lg={3}>
                  <Card.Text className="m-1">Email:</Card.Text>
                </Col>
                <Card.Text className="m-1">{data?.email}</Card.Text>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">Newsletter:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.newsletter ? 'Activadas' : 'Desactivadas'}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">Stripe id:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    <a
                      className="text-linkedin"
                      target="_blank"
                      href={`https://dashboard.stripe.com/customers/${data?.stripeId}`}
                      rel="noreferrer"
                    >
                      {data?.stripeId}
                    </a>
                  </Card.Text>
                </Col>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

UserDetailsInfo.propTypes = {
  data: PropTypes.object
};

export default UserDetailsInfo;
