import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chargeable: null,
  createdAt: null,
  email: null,
  id: null,
  name: null,
  marketing: undefined,
  paymentStatus: null,
  provider: null,
  status: null,
  stripeId: null,
  accessToken: null,
  type: null,
  updatedAt: null,
  username: null
};

const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveCurrentUser: (state, action) => {
      state.chargeable = action.payload.chargeable;
      state.createdAt = action.payload.createdAt;
      state.email = action.payload.email;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.marketing = action.payload.marketing;
      state.paymentStatus = action.payload.paymentStatus;
      state.provider = action.payload.provider;
      state.status = action.payload.status;
      state.stripeId = action.payload.stripeId;
      state.accessToken = action.payload.accessToken;
      state.type = action.payload.type;
      state.updatedAt = action.payload.updatedAt;
      state.username = action.payload.username;
    },
    cleanUser: state => {
      state.chargeable = null;
      state.createdAt = null;
      state.email = null;
      state.id = null;
      state.name = null;
      state.marketing = undefined;
      state.paymentStatus = null;
      state.provider = null;
      state.status = null;
      state.stripeId = null;
      state.accessToken = null;
      state.type = null;
      state.updatedAt = null;
      state.username = null;
    }
  }
});

export const { saveCurrentUser, cleanUser } = userReducer.actions;

export default userReducer.reducer;
