import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  userId: null,
  name: null,
  password: null,
  email: null,
  address: null,
  lat: null,
  lng: null,
  phone: null,
  website: null,
  placeid: null,
  description: null,
  active: false,
  displayed: false,
  hours: null,
  photoUrl: null,
  rating: null,
  discount: false,
  delivery: null,
  type: null,
  productGroupTypeList: null,
  createdAt: null,
  updatedAt: null,
  containersAssigned: null
};

const restaurantReducer = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    getSinglePartner: (state, action) => {
      state.id = action.payload.id;
      state.userId = action.payload.userId;
      state.name = action.payload.name;
      state.password = action.payload.password;
      state.email = action.payload.email;
      state.address = action.payload.address;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
      state.phone = action.payload.phone;
      state.website = action.payload.website;
      state.placeid = action.payload.placeid;
      state.description = action.payload.description;
      state.active = action.payload.active;
      state.displayed = action.payload.displayed;
      state.hours = action.payload.hours;
      state.photoUrl = action.payload.photoUrl;
      state.rating = action.payload.rating;
      state.discount = action.payload.discount;
      state.delivery = action.payload.delivery;
      state.type = action.payload.type;
      state.productGroupTypeList = action.payload.productGroupTypeList;
      state.createdAt = action.payload.createdAt;
      state.updatedAt = action.payload.updatedAt;
      state.containersAssigned = action.payload.containersAssigned;
    }
  }
});

export const { getSinglePartner } = restaurantReducer.actions;
export default restaurantReducer.reducer;
