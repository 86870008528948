import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import TransferTableHeader from 'components/container-transfer/TransferTableHeader';
import {
  containerColumnsBulkTransfer,
  userColumnsBulkTransfer
} from 'components/utilities/columnData';
import useError from 'hooks/useError';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { useGetContainerListQuery } from 'redux/endpoints/containersApi';
import { useGetRestaurantsV2Query } from 'redux/endpoints/restaurantApi';
import { useGetUsersListQueryQuery } from 'redux/endpoints/userApi';

const UserBulkTransfer = () => {
  const [offset, setOffset] = useState(0);
  const { checkError } = useError();
  const id = window.location.pathname.replace('/user-bulk/', '');
  const [userSelected, setUserSelected] = useState();
  const [partnerSelected, setPartnerSelected] = useState('');
  const [showSpan, setShowSpan] = useState(false);
  const { data: filteredUser, error: filteredUserError } =
    useGetUsersListQueryQuery({
      value: id
    });
  const { data: partnersData, error: partnersError } = useGetRestaurantsV2Query(
    { from: offset, limit: 30, value: partnerSelected },
    { refetchOnMountOrArgChange: true }
  );
  const { data: containersData, error: containersError } =
    useGetContainerListQuery(
      {
        from: 0,
        field: 'currentOwnerId',
        operator: '=',
        value: id,
        limit: 1000000000
      },
      { refetchOnMountOrArgChange: true }
    );

  const getMore = useCallback(() => {
    if (partnersData?.pagination.length < partnersData?.pagination.limit) {
      return;
    }
    setOffset(partnersData?.pagination.from + 30);
  }, [partnersData?.pagination]);

  const getLess = useCallback(() => {
    if (partnersData?.pagination.length < partnersData?.pagination.limit) {
      return;
    }
    setOffset(partnersData?.pagination.from - 30);
  }, [partnersData?.pagination]);

  useEffect(() => {
    if (partnersError) {
      checkError(partnersError);
    }
  }, [partnersError]);

  useEffect(() => {
    if (filteredUserError) {
      checkError(filteredUserError);
    }
  }, [filteredUserError]);

  useEffect(() => {
    if (containersError) {
      checkError(containersError);
    }
  }, [filteredUserError]);

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>Transferir envases</Card.Title>
        </Card.Header>
        <Card.Body className="p-3 bg-light">
          <Form>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Dueño/a actual</Form.Label>
              <Form.Control
                readOnly
                onChange={() => {}}
                type="text"
                name="name"
                value={filteredUser?.data[0]?.name}
              />
            </Form.Group>
          </Form>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Destino</Form.Label>
              <Form.Control
                readOnly
                onChange={e => {
                  setUserSelected(e.target.value);
                }}
                value={userSelected?.name}
                type="text"
                name="name"
                placeholder="¿Adónde quieres transferir el envase?"
              />
              {showSpan && filteredUser?.data[0].type[0] !== 'restaurant' && (
                <Form.Text className="text-danger">
                  Debes seleccionar un restaurante
                </Form.Text>
              )}
              {showSpan && filteredUser?.data[0].type[0] === 'restaurant' && (
                <Form.Text className="text-danger">
                  Debes seleccionar un usuario o admin
                </Form.Text>
              )}
            </Form.Group>
          </Form>
          {userSelected ? (
            containersData && (
              <>
                <Card.Title>¿Qué envases quieres transferir?</Card.Title>
                <Card>
                  <AdvanceTableWrapper
                    selection
                    columns={containerColumnsBulkTransfer}
                    data={containersData?.data || []}
                    pagination
                    sortable
                    perPage={30}
                  >
                    <Card.Header className="d-flex justify-content-between">
                      <Col
                        lg={12}
                        className="d-flex flex-direction-row align-center justify-content-between"
                      >
                        <Col className="mb-2">
                          <Col className="d-flex align-center justify-content-between mb-2">
                            <Col>
                              <Card.Text className="m-0">
                                Envases ({containersData?.pagination.total})
                              </Card.Text>
                              <Card.Link
                                style={{ cursor: 'pointer' }}
                                onClick={() => setUserSelected()}
                              >
                                <Card.Text href="#">
                                  Volver a usuarios
                                </Card.Text>
                              </Card.Link>
                            </Col>
                            <TransferTableHeader
                              selectedUser={userSelected}
                              user={filteredUser?.data[0]}
                              table
                              containers={containersData?.data}
                            />
                          </Col>
                          <Col className="d-flex flex-direction-row justify-content-between">
                            <Col className="d-flex flex-direction-row">
                              <AdvanceTableFooter
                                rowCount={containersData?.pagination.total}
                                table
                                rowInfo
                                navButtons
                              />
                            </Col>
                            <Col className="d-flex justify-content-between">
                              <Col style={{ position: 'relative' }}>
                                <Form>
                                  <Form.Control
                                    type="search"
                                    placeholder="Search..."
                                    size="sm"
                                    aria-label="Search"
                                    className="rounded search-input ps-4"
                                  />
                                  <FontAwesomeIcon
                                    style={{ top: '15px', left: '-5px' }}
                                    icon="search"
                                    className="fs--1 text-400 text-400 position-absolute translate-middle-y ms-3"
                                  />
                                </Form>
                              </Col>
                            </Col>
                          </Col>
                        </Col>
                      </Col>
                    </Card.Header>
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </AdvanceTableWrapper>
                </Card>
              </>
            )
          ) : (
            <>
              <Card.Title>
                Usuarios ({partnersData ? partnersData?.pagination.total : 0})
              </Card.Title>
              <Card>
                <AdvanceTableWrapper
                  columns={userColumnsBulkTransfer(
                    filteredUser?.data[0],
                    setShowSpan,
                    setUserSelected
                  )}
                  data={partnersData?.data || []}
                  sortable
                  pagination
                  perPage={30}
                >
                  <Card.Header className="d-flex justify-content-between">
                    <Col>
                      <Button
                        disabled={partnersData?.pagination.from === 0}
                        variant="primary"
                        onClick={() => getLess()}
                      >
                        Anteriores
                      </Button>
                      <Button
                        disabled={
                          partnersData?.pagination.from + 20 >
                          partnersData?.pagination.total
                        }
                        variant="primary"
                        onClick={() => getMore()}
                      >
                        Siguientes
                      </Button>
                    </Col>
                    <Col className="d-flex justify-content-between">
                      <Col style={{ position: 'relative' }}>
                        <Form>
                          <Form.Control
                            type="search"
                            placeholder="Search..."
                            size="sm"
                            aria-label="Search"
                            className="rounded search-input ps-4"
                            onChange={({ target }) => {
                              setPartnerSelected(target.value);
                            }}
                          />
                          <FontAwesomeIcon
                            style={{ top: '15px', left: '-5px' }}
                            icon="search"
                            className="fs--1 text-400 text-400 position-absolute translate-middle-y ms-3"
                          />
                        </Form>
                      </Col>
                    </Col>
                  </Card.Header>
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                </AdvanceTableWrapper>
              </Card>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default UserBulkTransfer;
