import useError from 'hooks/useError';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetProductsQuery } from 'redux/endpoints/paymentApiV2';
import { useCreateNewPriceMutation } from 'redux/endpoints/pricesApi';
import { v4 as uuidv4 } from 'uuid';

const defaultValues = {
  name: '',
  productGroupId: '',
  currency: 'EUR',
  type: '',
  tiers: [],
  tax: 21
};

const CreatePrice = () => {
  const tierValue = {
    upTo: null,
    amount: 0
  };
  const [allowMoreTiers, setAlowMoreTiers] = useState(false);
  const navigate = useNavigate();
  const [tiers, setTiers] = useState([tierValue]);
  const [formData, setFormData] = useState(defaultValues);
  const { checkError } = useError();
  const { data: productsData, error: productsError } = useGetProductsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const [createPrice, { data: newPriceData, error: newPriceError }] =
    useCreateNewPriceMutation();

  if (productsError) {
    checkError(productsError);
  }

  const handleFormData = e => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData({ ...formData, id: uuidv4(), [e.target.name]: e.target.value });
    if (name === 'type' && value !== 'graduated') {
      newFormData.tier = [];
    }
  };

  const resetTiers = () => {
    setTiers([tierValue]);
    setFormData({ ...formData, tiers: [tierValue] });
  };

  const resetTiersOnChange = () => {
    if (formData.type !== '') {
      resetTiers();
    }
  };

  const addNewTier = () => {
    const newTier = { ...tierValue, upTo: 0, amount: 0 };
    setTiers([...tiers, newTier]);
    const newFormData = { ...formData };
    newFormData.tiers.push(newTier);
    setFormData(newFormData);
  };

  const handleTierChange = (index, fieldName, value) => {
    const newTiers = [...tiers];
    if (fieldName === 'amount') {
      newTiers[index][fieldName] = Math.floor(value * 100);
    }
    if (fieldName === 'upTo') {
      if (value === '' || value === '0') {
        newTiers[index][fieldName] = null;
      } else {
        newTiers[index][fieldName] = parseInt(value);
      }
    }
    setTiers(newTiers);
    const newFormData = { ...formData };
    newFormData.tiers[index] = newTiers[index];
    setFormData(newFormData);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (formData.type === 'graduated' && formData.tiers.length > 1) {
      formData.tiers[formData.tiers.length - 1].upTo = null;
    }
    createPrice(formData);
  };

  useEffect(() => {
    resetTiersOnChange();
  }, [formData.type]);

  useEffect(() => {
    if (newPriceData) {
      toast.success('Tarifa creada correctamente', { position: 'top-center' });
      navigate('/prices');
    }
  }, [newPriceData]);

  useEffect(() => {
    if (newPriceError) {
      checkError(newPriceError);
    }
  }, [newPriceError]);
  return (
    <Card>
      <Card.Header className="mt-4 ms-5" as="h5">
        Crear Tarifa
      </Card.Header>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col className="ps-lg-4 my-0 text-lg-left">
            <Form>
              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  required
                  name="name"
                  onChange={handleFormData}
                  type="text"
                  placeholder="Nombre"
                />
              </Form.Group>

              <Form.Group controlId="productGroupId" className="mb-3">
                <Form.Label className="me-5">Grupo de producto</Form.Label>
                <Form.Select name="productGroupId" onChange={handleFormData}>
                  <option>Selecciona un grupo de producto</option>
                  {productsData?.map(element => (
                    <option key={element.id} value={element.id}>
                      {element.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupCurrency">
                <Form.Label>Divisa</Form.Label>
                <Form.Control
                  disabled
                  onChange={handleFormData}
                  contentEditable={false}
                  name="currency"
                  type="text"
                  placeholder="Euros €"
                />
              </Form.Group>
              <Form.Group controlId="formGroupType" className="mb-3">
                <Form.Label className="me-5">Tipo de tarifa</Form.Label>
                <Form.Select
                  name="type"
                  onChange={e => {
                    if (e.target.value === 'graduated') {
                      setAlowMoreTiers(true);
                    }
                    if (e.target.value !== 'graduated') {
                      setAlowMoreTiers(false);
                      resetTiers();
                    }

                    handleFormData(e);
                  }}
                >
                  <option>Selecciona un tipo de tarifa</option>
                  <option value="fixed">Fija</option>
                  <option value="volume">Por uso</option>
                  <option value="graduated">Por tramos</option>
                </Form.Select>
              </Form.Group>

              {tiers.map((tierItem, index) => (
                <React.Fragment key={index}>
                  {allowMoreTiers && (
                    <Form.Group
                      className="mb-3"
                      controlId={`formGroupUpTo${index}`}
                    >
                      <Form.Label>Tramo hasta</Form.Label>
                      <Form.Control
                        required
                        name="upTo"
                        min={0}
                        value={tierItem.upTo}
                        onChange={e =>
                          handleTierChange(index, 'upTo', e.target.value)
                        }
                        type="number"
                        placeholder="Escribe el tramo"
                      />
                    </Form.Group>
                  )}

                  <Form.Group
                    className="mb-3"
                    controlId={`formGroupAmount${index}`}
                  >
                    <Form.Label>Precio</Form.Label>
                    <Form.Control
                      required
                      name="amount"
                      min={0}
                      value={
                        tierItem.amount === null || tierItem.amount === ''
                          ? 0
                          : tierItem.amount / 100
                      }
                      onChange={e =>
                        handleTierChange(index, 'amount', e.target.value)
                      }
                      type="number"
                      placeholder="Escribe la cantidad"
                    />
                  </Form.Group>
                </React.Fragment>
              ))}

              {allowMoreTiers && (
                <Link className="text-linkedin" onClick={addNewTier}>
                  <Card.Text>Añadir cantidad para siguiente tramo</Card.Text>
                </Link>
              )}

              <Form.Group className="mb-3" controlId="formGroupTaxes">
                <Form.Label>Impuestos</Form.Label>
                <Form.Control
                  disabled
                  onChange={handleFormData}
                  contentEditable={false}
                  name="tax"
                  type="number"
                  placeholder="21%"
                />
              </Form.Group>

              <Button
                onClick={handleFormSubmit}
                className="mb-6 mt-3"
                variant="primary"
                type="submit"
              >
                Guardar
              </Button>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CreatePrice;
