import React from 'react';
import { Col, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SubscriptionDates = ({
  handleDateChange,
  subscription,
  setSubscription,
  isSubscriptionFixed
}) => {
  return (
    <Col className="d-flex l justify-content-between mb-4">
      <Form.Group style={{ width: '200px' }}>
        <Col>
          <label className="form-label" htmlFor="datepicker">
            Fecha de inicio
          </label>
          <input
            onChange={handleDateChange}
            className="form-control datetimepicker"
            id="datepicker"
            type={isSubscriptionFixed ? 'date' : 'month'}
            placeholder={isSubscriptionFixed ? 'dd/mm/yyyy' : 'mm/yyyy'}
            data-options='{"disableMobile":true}'
          />
        </Col>
      </Form.Group>
      <Form.Group style={{ width: '200px' }}>
        <Col>
          <label className="form-label" htmlFor="datepicker">
            Inicio de facturación
          </label>
          {isSubscriptionFixed ? (
            <input
              onChange={handleDateChange}
              className="form-control datetimepicker"
              id="datepicker"
              type="date"
              placeholder="dd/mm/yyyy"
              data-options='{"disableMobile":true}'
            />
          ) : (
            <input
              contentEditable={false}
              readOnly
              value={subscription.startPeriodDate}
              className="form-control datetimepicker"
              id="datepicker"
              type="date"
              placeholder="dd/mm/yyyy"
              data-options='{"disableMobile":true}'
            />
          )}
        </Col>
      </Form.Group>
      <Form.Group style={{ width: '200px' }}>
        <Col>
          <label className="form-label" htmlFor="datepicker">
            Finalización
          </label>
          <input
            onChange={event => {
              setSubscription({
                ...subscription,
                endDate: event.target.value
              });
            }}
            className="form-control datetimepicker"
            id="datepicker"
            type="date"
            placeholder="d/m/y"
            data-options='{"disableMobile":true}'
          />
        </Col>
      </Form.Group>
    </Col>
  );
};

SubscriptionDates.propTypes = {
  subscription: PropTypes.object,
  setSubscription: PropTypes.func,
  handleDateChange: PropTypes.func,
  isSubscriptionFixed: PropTypes.bool
};

export default SubscriptionDates;
