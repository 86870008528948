import CustomModal from 'components/common/CustomModal';
import useError from 'hooks/useError';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetProductGroupsQuery } from 'redux/endpoints/productGroups';
import { useGetSingleRestaurantMutationMutation } from 'redux/endpoints/restaurantApi';
import {
  useCollectContainerInBulkMutation,
  useLendInBulkMutation,
  useMovementsInBulkMutation,
  useTransferContainersBetweenPartnersMutation
} from 'redux/endpoints/scanApi';
import { v4 as uuidv4 } from 'uuid';

const TransferTableHeader = ({
  selectedRowIds,
  containers,
  user,
  selectedUser
}) => {
  const { checkError } = useError();
  const [getRestaurant, { data: restaurantData, error: restaurantError }] =
    useGetSingleRestaurantMutationMutation();
  const { data: productGroups, error: productGroupsError } =
    useGetProductGroupsQuery({}, { refetchOnMountOrArgChange: true });
  const [openModal, setOpenModal] = useState(false);
  const navigation = useNavigate();
  const [collectContainer, { data: collectData, error: collectError }] =
    useCollectContainerInBulkMutation();
  const [lendInBulk, { data: lendData, error: lendError }] =
    useLendInBulkMutation();
  const [movementsInBulk, { data: movementsData, error: movementsError }] =
    useMovementsInBulkMutation();
  const [transferInBulk, { data: transferData, error: transferError }] =
    useTransferContainersBetweenPartnersMutation();

  const values = Object.keys(selectedRowIds);
  const containersSelected = [];
  const containersData = [];
  values.forEach(value => {
    if (!containersSelected.some(e => e.id === containers[value].id)) {
      containersSelected.push(containers[value].id);
      containersData.push(containers[value]);
    }
  });

  const getGroupName = groupId => {
    const group = productGroups?.find(group => group.id === groupId);
    return group?.name;
  };

  const caseSelector = userType => {
    if (
      userType === 'customer' &&
      restaurantData?.productGroupTypeList?.length > 0
    ) {
      handleCustomerAction();
    } else if (
      userType === 'admin' &&
      restaurantData?.productGroupTypeList?.length > 0
    ) {
      handleAdminAction();
    } else if (isValidUserType(userType[0])) {
      handleOtherUserAction();
    }
  };

  const handleCustomerAction = () => {
    const invalidContainer = containersData.find(container => {
      return !restaurantData?.productGroupTypeList?.includes(
        getGroupName(container?.productType.groupId)
      );
    });

    if (invalidContainer) {
      toast.error(
        'El usuario seleccionado no puede recibir envases de este tipo',
        {
          position: 'top-center'
        }
      );
      setOpenModal(false);
    } else {
      collectContainer({
        returnId: uuidv4(),
        collectorId: selectedUser?.userId,
        containerIdList: containersSelected
      });
    }
  };

  const handleAdminAction = () => {
    const invalidContainer = containersData.find(container => {
      return !restaurantData?.productGroupTypeList?.includes(
        getGroupName(container?.productType.groupId)
      );
    });
    console.log(selectedUser);
    if (invalidContainer) {
      toast.error(
        'El usuario seleccionado no puede recibir envases de este tipo',
        {
          position: 'top-center'
        }
      );
      setOpenModal(false);
    } else {
      movementsInBulk({
        requestId: uuidv4(),
        operatorId: user?.id,
        partnerId: selectedUser?.userId
          ? selectedUser?.userId
          : selectedUser?.id,
        containerIdList: containersSelected,
        status: 'refill'
      });
    }
  };

  const isValidUserType = userType => {
    const validUserTypes = [
      'restaurant',
      'canteen',
      'cafeteria',
      'delivery_service',
      'supermarket'
    ];
    return validUserTypes.includes(userType);
  };

  const handleOtherUserAction = () => {
    if (selectedUser?.type === 'customer') {
      lendInBulk({
        requestId: uuidv4(),
        lendeeId: selectedUser?.id,
        lenderId: user?.userId,
        containerIdList: containersSelected,
        inputMethod: 'api'
      });
    } else if (selectedUser?.type === 'admin') {
      movementsInBulk({
        requestId: uuidv4(),
        collectorId: selectedUser?.id,
        partnerId: user?.userId,
        containerIdList: containersSelected,
        status: 'retrieval'
      });
    } else if (
      selectedUser?.type === 'partner' &&
      restaurantData?.productGroupTypeList?.length > 0
    ) {
      const invalidContainer = containersData.find(container => {
        return !restaurantData?.productGroupTypeList?.includes(
          getGroupName(container?.productType.groupId)
        );
      });

      if (invalidContainer) {
        toast.error(
          'El usuario seleccionado no puede recibir envases de este tipo',
          {
            position: 'top-center'
          }
        );
        setOpenModal(false);
      } else {
        transferInBulk({
          partnerFromId: user?.userId,
          partnerToId: selectedUser?.id,
          containerIdList: containersSelected
        });
      }
    }
  };

  if (restaurantError) {
    checkError(restaurantError);
  }

  if (productGroupsError) {
    checkError(productGroupsError);
  }

  useEffect(() => {
    if (movementsData || collectData || lendData || transferData) {
      toast.success('Envase/s transferido/s correctamente', {
        position: 'top-center'
      });
      setTimeout(() => {
        containers.length > 1
          ? navigation(`/containers-list/1`)
          : navigation(`/container-details/${containers[0].id}`);
        setOpenModal(false);
      }, 1000);
    }
  }, [movementsData, collectData, lendData, transferData]);

  useEffect(() => {
    if (collectError) {
      checkError(collectError);
      setOpenModal(false);
    }

    if (lendError) {
      checkError(lendError);
      setOpenModal(false);
    }
    if (movementsError) {
      checkError(movementsError);
      setOpenModal(false);
    }

    if (transferError) {
      checkError(transferError);
      setOpenModal(false);
    }
  }, [movementsError, collectError, lendError, transferError]);

  useEffect(() => {
    if (selectedUser?.type !== 'customer' && selectedUser?.type !== 'admin') {
      getRestaurant(selectedUser?.id);
    }
  }, [selectedUser]);

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          {Object.keys(selectedRowIds).length} envases seleccionados
        </Col>
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0 me-4">
          <Button variant="primary" onClick={() => setOpenModal(true)}>
            Transferir
          </Button>
        </Col>
      </Row>
      <CustomModal
        setter={setOpenModal}
        showModal={openModal}
        userData={user}
        transferAction={caseSelector}
        selectedUser={selectedUser}
        containers={containersData}
      />
    </>
  );
};

TransferTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  containers: PropTypes.array,
  user: PropTypes.object,
  selectedUser: PropTypes.object
};

export default TransferTableHeader;
