import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserLoginMutation } from 'redux/endpoints/authApi';
import { useDispatch } from 'react-redux';
import { saveCurrentUser } from 'redux/reducers/userReducer';
import { doLogin } from 'redux/reducers/loginReducer';
import { toast } from 'react-toastify';
import useError from './useError';

const useLogin = () => {
  const [userLogin, { data, error, isLoading }] = useUserLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkError } = useError();

  useEffect(() => {
    if (data && !isLoading) {
      const user = data;
      if (user) {
        if (user.type === 'customer') {
          toast.error('Necesitas una cuenta de Admin o Partner');
        } else if (user.type === 'billing_group') {
          dispatch(saveCurrentUser(user));
          dispatch(
            doLogin({
              logged: true,
              accesToken: user.accessToken,
              remember: true
            })
          );
          navigate('/billing-group-dashboard');
          toast.success('Bienvenid@');
        } else {
          dispatch(saveCurrentUser(user));
          dispatch(
            doLogin({
              logged: true,
              accesToken: user.accessToken,
              remember: true
            })
          );
          navigate('/');
          toast.success('Bienvenid@');
        }
      } else {
        toast.error('Ha habido un error');
      }
    }
  }, [data, dispatch, isLoading]);

  useEffect(() => {
    if (error) {
      checkError(error);
    }
  }, [error]);

  return { userLogin, data: data && data, error: error && error };
};

export default useLogin;
