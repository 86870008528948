import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from 'env/env';

export const productGroupsApi = createApi({
  reducerPath: 'productGroups',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}productGroups`,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState().persistedReducer.user;
      headers.set('authorization', `Bearer ${accessToken}`);
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    getProductGroups: builder.query({
      query: params => {
        return {
          method: 'GET',
          url: '',
          params: {
            ...(params &&
              params.value &&
              params.value !== '' && {
                ['filters[0][field]']: 'query',
                ['filters[0][operator]']: 'icontains',
                ['filters[0][value]']: params.value
              })
          }
        };
      },
      transformResponse: response => response.data
    }),
    createProductGroup: builder.mutation({
      query: body => {
        return {
          method: 'POST',
          url: '',
          body
        };
      },
      transformResponse: () => 'No data response'
    })
  })
});

export const { useGetProductGroupsQuery, useCreateProductGroupMutation } =
  productGroupsApi;
