import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import AuthSimpleLayout from './AuthSimpleLayout';
import ErrorLayout from './ErrorLayout';

import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';

import AppContext from 'context/Context';
import Login from '../pages/Login';
import PartnersList from '../pages/PartnerList';
import BumerangsList from '../pages/BumerangsList';
import PartnerCreation from 'pages/PartnerCreation';
import EditPartner from 'pages/EditPartner';
import PartnerDetails from 'pages/PartnerDetails';
import ContainerDetails from 'pages/ContainerDetails';
import ContainerTransfer from 'pages/ContainerTransfer';
import BulkTransfer from 'pages/BulkTransfer';
import UserBulkTransfer from 'pages/UserBulkTransfer';
import UsersList from 'pages/UsersList';
import ContainersCreation from 'pages/ContainersCreation';
import AddSubscription from 'pages/AddSubscription';
import UserDetails from 'pages/UserDetails';
import UpdateSubscription from 'pages/UpdateSubscription';
import ProductGroup from 'pages/ProductGroup';
import ProductTypes from 'pages/ProductTypes';
import PriceList from 'pages/PriceList';
import CreatePrice from 'pages/CreatePrice';
import EditPrice from 'pages/EditPrice';
import SubscriptionList from 'pages/SubscriptionList';
import BillingList from 'pages/BillingList';
import CreateBillingGroup from 'pages/CreateBillingGroup';
import EditBillingGroup from 'pages/EditBillingGroup';
import BillingDetails from 'pages/BillingDetails';
import StockReportList from 'pages/StockReportList';
import StockReportDetails from 'pages/StockReportDetails';
import ProtectedRouteAdmin from './ProtectedRouteAdmin';
import BillingGroupDashboard from 'pages/BillingGroupDashboard';
import BillingGroupProfile from 'pages/BillingGroupProfile';
import ForgotPassword from 'pages/ForgotPassword';
import AssignContainers from 'pages/AssignContainers';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route element={<AuthSimpleLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
        <Route element={<AuthSimpleLayout />}>
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
        </Route>

        {/*- ------------- Authentication ---------------------------  */}

        <Route
          path="/billing-group-dashboard"
          element={<BillingGroupDashboard />}
        />
        <Route path="/profile" element={<BillingGroupProfile />} />

        {/* //--- MainLayout Starts  */}

        <Route element={<MainLayout />}>
          <Route element={<ProtectedRouteAdmin />}>
            <Route path="/" element={<Navigate to="/partners-list/1" />} />

            <Route path="/users-list/:page" element={<UsersList />} />
            <Route path="/user-details/:id" element={<UserDetails />} />

            <Route path="/product-groups" element={<ProductGroup />} />
            <Route path="/product-types" element={<ProductTypes />} />
            <Route path="/assign-containers" element={<AssignContainers />} />

            {/* Partners */}
            <Route path="/partners-list/:page" element={<PartnersList />} />
            <Route path="/partner-creation" element={<PartnerCreation />} />
            <Route path="/partner-details/:id" element={<PartnerDetails />} />
            <Route path="/edit-partner/:id" element={<EditPartner />} />

            {/* Subscriptions */}
            <Route path="subscriptions/:page" element={<SubscriptionList />} />
            <Route path="/prices" element={<PriceList />} />
            <Route path="/new-price" element={<CreatePrice />} />
            <Route path="/edit-price/:id" element={<EditPrice />} />
            <Route path="/add-subscription/:id" element={<AddSubscription />} />
            <Route
              path="/edit-subscription/:id"
              element={<UpdateSubscription />}
            />

            {/* Billing groups */}
            <Route path="/billing-list/:page" element={<BillingList />} />
            <Route path="/billing-group/new" element={<CreateBillingGroup />} />
            <Route path="/edit-billing/:id" element={<EditBillingGroup />} />
            <Route path="/billing-group/:id" element={<BillingDetails />} />

            {/*Containers*/}
            <Route path="/containers-list/:page" element={<BumerangsList />} />
            <Route
              path="/container-details/:id"
              element={<ContainerDetails />}
            />
            <Route
              path="/containers-creation"
              element={<ContainersCreation />}
            />
            <Route
              path="/container-transfer/:id"
              element={<ContainerTransfer />}
            />
            <Route path="/user-bulk/:id" element={<UserBulkTransfer />} />
            <Route path="/bulk-transfer/:id" element={<BulkTransfer />} />

            {/*Stock reports*/}
            <Route path="/stock-list/:page" element={<StockReportList />} />
            <Route path="/stock-report/:id" element={<StockReportDetails />} />
          </Route>
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>

      {/* Custom template settings
      <SettingsToggle />
      <SettingsPanel />
      */}

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.TOP_CENTER}
      />
    </>
  );
};

export default Layout;
