import React from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PartnersTableHeader from '../components/partners-table/PartnersTable';
import { useGetUsersListQueryQuery } from 'redux/endpoints/userApi';
import { userColumn } from 'components/user-list/userColumn';
import useError from 'hooks/useError';
import Pagination from 'components/pagination/Pagination';

const UsersList = () => {
  const { checkError } = useError();
  const params = useParams();
  const query = window.location.search;
  const queryValue = query.replace('?query=', '');
  const finalQuery = queryValue.split('%20').join(' ');
  const { data: userData, error: userError } = useGetUsersListQueryQuery(
    {
      from: (params.page - 1) * 10,
      value: finalQuery,
      type: 'customer',
      select: 'id,name,email,type,paymentStatus,containersAssigned'
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  if (userError) {
    checkError(userError);
  }

  return (
    <AdvanceTableWrapper
      columns={userColumn}
      data={userData?.data || []}
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <PartnersTableHeader table />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Pagination data={userData} />
      </Card>
    </AdvanceTableWrapper>
  );
};

export default UsersList;
