import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { Button, Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { useNavigate } from 'react-router-dom';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { useGetContainerListQuery } from 'redux/endpoints/containersApi';
import useError from 'hooks/useError';
import { columns } from './columns';
import { detailsStockColumn } from './detailsStockColumn';

const UserDetailsStock = ({ id, containersAssigned }) => {
  const navigation = useNavigate();
  const { checkError } = useError();
  const [showStock, setShowStock] = useState(false);
  const { data: containersData, error: containersError } =
    useGetContainerListQuery(
      {
        field: 'currentOwnerId',
        operator: '=',
        value: id,
        from: 0,
        limit: 1000
      },
      { refetchOnMountOrArgChange: true }
    );

  useEffect(() => {
    if (containersError) {
      checkError(containersError);
    }
  }, [containersError]);

  return (
    <>
      {containersAssigned?.length > 0 ? (
        <AdvanceTableWrapper
          columns={showStock ? detailsStockColumn : columns}
          data={
            showStock ? containersData?.data || [] : containersAssigned || []
          }
          pagination
          perPage={10}
          sortable
        >
          <Card className="mb-3 d-flex">
            <Card.Header className="d-flex justify-content-between">
              <Card.Title className="ms-2">Envases activos</Card.Title>
              {showStock ? (
                <Button
                  className="me-4"
                  style={{ width: '250px' }}
                  variant="primary"
                  onClick={() => navigation(`/user-bulk/${id}`)}
                >
                  Transferir
                </Button>
              ) : (
                <Button
                  className="me-4"
                  style={{ width: '250px' }}
                  variant="primary"
                  onClick={() => setShowStock(true)}
                >
                  Ver stock
                </Button>
              )}
            </Card.Header>
            <Card.Body className="p-0">
              {showStock ? (
                <>
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                  <Card.Footer className="mt-3">
                    <AdvanceTableFooter
                      rowCount={containersData?.pagination.total}
                      table
                      rowInfo
                      navButtons
                    />
                  </Card.Footer>
                </>
              ) : (
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    size: 'sm',
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
              )}
            </Card.Body>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <Card className="mb-3 d-flex">
          <Card.Header>
            <Card.Title>Sin envases asignados</Card.Title>
          </Card.Header>
        </Card>
      )}
    </>
  );
};

UserDetailsStock.propTypes = {
  id: PropTypes.string,
  containersAssigned: PropTypes.array
};

export default UserDetailsStock;
