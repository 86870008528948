import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BillingTableHeader = ({
  selectedRowIds,
  partners,
  id,
  linkToBillingGroup,
  setAssociated,
  searchFunction
}) => {
  const values = Object.keys(selectedRowIds);
  const partnerIds = [];
  values.forEach(value => {
    const partnerId = partners[value].id;
    partnerIds.push(partnerId);
  });
  const request = {
    body: {
      billingGroupId: id,
      userIds: partnerIds
    }
  };

  return (
    <Col
      lg={12}
      sm="auto"
      className="ms-auto ps-0 pb-2 d-flex justify-content-around align-items-center"
    >
      <Col>
        <Link onClick={() => setAssociated(false)}>Volver</Link>
        <Card.Text className="m-0 mt-1 me-3">
          {Object.keys(selectedRowIds).length} centros seleccionados
        </Card.Text>
      </Col>
      <Col className="d-flex justify-content-end">
        <Col className="me-2" lg={5} style={{ position: 'relative' }}>
          <Form>
            <Form.Control
              type="search"
              placeholder="Search..."
              size="sm"
              aria-label="Search"
              className="rounded search-input ps-4"
              onChange={({ target }) => {
                searchFunction(target.value);
              }}
            />
            <FontAwesomeIcon
              style={{ top: '15px', left: '-5px' }}
              icon="search"
              className="fs--1 text-400 text-400 position-absolute translate-middle-y ms-3"
            />
          </Form>
        </Col>

        <Button onClick={() => linkToBillingGroup(request)} size="sm">
          Guardar
        </Button>
      </Col>
    </Col>
  );
};

BillingTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  partners: PropTypes.array,
  billingGroupId: PropTypes.string,
  id: PropTypes.string,
  linkToBillingGroup: PropTypes.func,
  setAssociated: PropTypes.func,
  searchFunction: PropTypes.func
};

export default BillingTableHeader;
