import BillingTableHeader from 'components/billingGroups/BillingTableHeader';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { subscriptionStatusBadge } from 'components/utilities/badgeSelector';
import timeConverter from 'components/utilities/timeConverter';
import useBillingGroups from 'hooks/useBillingGroups';
import useError from 'hooks/useError';
import useSubscriptions from 'hooks/useSubscriptions';
import React, { useState } from 'react';
import { Button, Card, Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetUsersListQueryQuery } from 'redux/endpoints/userApi';

const columns = [
  {
    accessor: 'name',
    Header: 'Nombre del centro',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{name}</h5>
          </div>
        </Flex>
      );
    }
  }
];

const subsColumn = updateSubscription => [
  {
    accessor: 'status',
    Header: 'Estado',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      console.log(rowData.row.original);
      const { status } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{subscriptionStatusBadge(status)}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Tarifa',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { price } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{price?.name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'type',
    Header: 'Tipo de tarifa',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { price } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{price?.type}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'product',
    Header: 'Tipo de Producto',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { productGroup } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{productGroup?.name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'user',
    Header: 'Usuario',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { user, name } = rowData.row.original;
      console.log(name);
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{user?.name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'nextPaymentDate',
    Header: 'Siguiente cobro',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { nextPaymentDate } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{timeConverter(nextPaymentDate)}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <CardDropdown iconClassName="fs--1 z-6">
          <div className="py-2">
            <Dropdown.Item className="z-9" href={`/edit-subscription/${id}`}>
              Editar
            </Dropdown.Item>
            <Dropdown.Item
              className="z-9"
              onClick={() =>
                updateSubscription({ id: id, status: 'cancelled' })
              }
            >
              Cancelar
            </Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];

const BillingDetails = () => {
  const id = window.location.pathname.replace('/billing-group/', '');
  const navigate = useNavigate();
  const { subscriptions, updateSubscription } = useSubscriptions();
  const [search, setSearch] = useState('');
  const [associatedCenters, setAssociatedCenters] = useState(false);
  const { checkError } = useError();
  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError,
    refetch
  } = useGetUsersListQueryQuery(
    {
      type: 'partner',
      select: 'billingGroupId,name,id',
      billingGroupId: id,
      limit: 500
    },
    { refetchOnMountOrArgChange: true }
  );

  const { deleteBillingGroup, linkToBillingGroup, data, isLoading } =
    useBillingGroups({ refetch, setAssociatedCenters, setSearch });

  function searchObjectsByName(objects, name) {
    return objects.filter(obj => obj.name.toLowerCase().includes(name));
  }

  if (listError) {
    checkError(listError);
  }
  const activeSubs = subscriptions?.data.filter(
    subscription => subscription.status === 'active'
  );

  const canIdeleteABillingGroup = id => {
    if (listData?.data?.length > 0 || activeSubs?.length > 0) {
      return toast.error(
        'No puedes eliminar un grupo con centros y/o suscripciones activas'
      );
    }

    deleteBillingGroup(id);
  };

  return (
    <>
      <Card>
        <Card.Header
          className={
            isLoading
              ? 'd-flex justify-content-center fw-bold w-100 fs-2 p-0'
              : 'fw-bold w-100 fs-2 p-0'
          }
        >
          {data && (
            <>
              <Col lg={12} className="px-4 py-3">
                <Card.Title className="text-primary">
                  {data?.data.name} (
                  {
                    <a
                      className="text-linkedin"
                      href={`mailto:${data?.data.email}`}
                    >
                      {data?.data.email}
                    </a>
                  }
                  )
                </Card.Title>
                <Button variant="default">+ Añadir nota</Button>
              </Col>
              <Col
                lg={12}
                className="mt-2 border-top px-4 py-3 position-relative"
              >
                <div
                  style={{
                    position: 'absolute',
                    top: '20px',
                    left: '16px',
                    marginRight: 5,
                    height: '10px',
                    width: '10px',
                    backgroundColor: 'rgb(55, 126, 34)',
                    borderRadius: '50%'
                  }}
                />
                <Card.Text className="fs--1 fw-light d-flex flex-row position-relative">
                  Cuenta creada el
                </Card.Text>
                <Card.Subtitle className="fw-light">
                  {timeConverter(data?.data.createdAt)}
                </Card.Subtitle>
              </Col>
            </>
          )}
          {isLoading && <Spinner animation="border" variant="primary" />}
        </Card.Header>
      </Card>
      <Card className="mt-3">
        <Card.Header className="d-flex flex-row justify-content-between">
          <Card.Title className="mt-2">Detalles del grupo</Card.Title>
          <Col className="d-flex flex-row align-items-center justify-content-end">
            <Button
              className="me-3"
              style={{ width: '100px' }}
              size="sm"
              variant="primary"
              onClick={() => navigate(`/edit-billing/${id}`)}
            >
              Editar
            </Button>
            <CardDropdown iconClassName="fs--1">
              <div className="py-2">
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => {
                    canIdeleteABillingGroup(id);
                  }}
                >
                  Eliminar
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Col>
        </Card.Header>
        <Card.Body
          className="p-2 border-top"
          style={{ backgroundColor: 'rgb(249,	250, 253)' }}
        >
          <Row className="p-3">
            <Col lg={12} className="d-flex flex-row">
              <Col lg={5}>
                <Card.Title className="mb-3">Contacto</Card.Title>
              </Col>
              <Col className="ps-3">
                <Card.Title>Preferencias</Card.Title>
              </Col>
            </Col>
            <Col lg={5} className="">
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Nombre:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">{data?.data.name}</Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Teléfono:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.data.phone ? data?.data.phone : 'No proporcionado'}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={4}>
                  <Card.Text className="m-1">Email:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">{data?.data.email}</Card.Text>
                </Col>
              </Col>
            </Col>
            <Col lg={7}>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">Factura:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.data.billingMode}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">Proforma:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">
                    {data?.data.proformaInvoice ? 'Sí' : 'No'}
                  </Card.Text>
                </Col>
              </Col>
              <Col className="d-flex flex-direction-row justify-content-between">
                <Col lg={3}>
                  <Card.Text className="m-1">Plazo de vencimiento:</Card.Text>
                </Col>
                <Col>
                  <Card.Text className="m-1">-</Card.Text>
                </Col>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="my-3">
        <Card.Header
          className="p-3 d-flex align-items-center justify-content-between"
          style={{ width: '100%' }}
        >
          <Card.Title className="mt-3 ms-3">Suscripciones</Card.Title>
          <Button
            className="me-4"
            variant="primary"
            onClick={() => navigate(`/add-subscription/${id}`)}
          >
            +Asignar
          </Button>
        </Card.Header>

        <Card.Body
          className="mt-3"
          style={{ backgroundColor: 'rgb(249,	250, 253)' }}
        >
          {activeSubs?.length > 0 ? (
            <AdvanceTableWrapper
              columns={subsColumn(updateSubscription)}
              data={activeSubs || []}
              pagination
              perPage={10}
            >
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-5'
                }}
              />
              <AdvanceTableFooter
                navButtons
                table
                rowInfo
                rowCount={activeSubs?.length}
              />
            </AdvanceTableWrapper>
          ) : (
            <Card.Text>No hay suscripciones asignadas</Card.Text>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header className="d-flex flex-row justify-content-between">
          <Card.Title className="mt-2 ms-2">Centros asociados</Card.Title>
          <Col className="d-flex flex-row align-items-center justify-content-end">
            {!associatedCenters && (
              <Button
                className="me-3"
                variant="primary"
                style={{ width: '100px' }}
                size="sm"
                onClick={() => {
                  setAssociatedCenters(true);
                }}
              >
                Asociar
              </Button>
            )}
            <CardDropdown iconClassName="fs--1">
              <div className="py-2">
                <Dropdown.Item href={'/partner-creation'}>
                  Crear centro
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Col>
        </Card.Header>
        <Card.Body
          style={{ backgroundColor: 'rgb(249,	250, 253)' }}
          className="border-top"
        >
          {!associatedCenters && listData?.data?.length === 0 && (
            <>
              <Card.Title className="fs-1">No hay centros asociados</Card.Title>
              <Card.Text>
                "Recuerda asociar los centros a un grupo de facturación"
              </Card.Text>
            </>
          )}
          {!associatedCenters &&
            listData?.data?.length > 0 &&
            !listIsLoading && (
              <>
                <AdvanceTableWrapper
                  columns={columns}
                  data={listData?.data || []}
                  pagination
                  perPage={10}
                >
                  {listData && !listIsLoading && (
                    <AdvanceTable
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        size: 'sm',
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  )}
                  <AdvanceTableFooter
                    navButtons
                    table
                    rowInfo
                    rowCount={listData?.data?.length}
                  />
                </AdvanceTableWrapper>
              </>
            )}
          {associatedCenters &&
            listData?.data?.length > 0 &&
            !listIsLoading && (
              <AdvanceTableWrapper
                columns={columns}
                data={
                  search
                    ? searchObjectsByName(listData?.data, search)
                    : listData?.data || []
                }
                pagination
                selection={true}
                perPage={10}
              >
                <BillingTableHeader
                  table
                  partners={
                    search
                      ? searchObjectsByName(listData?.data, search)
                      : listData?.data || []
                  }
                  id={id}
                  linkToBillingGroup={linkToBillingGroup}
                  setAssociated={setAssociatedCenters}
                  searchFunction={setSearch}
                />
                {listData && !listIsLoading && (
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      size: 'sm',
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
                )}
                <AdvanceTableFooter
                  navButtons
                  table
                  rowInfo
                  rowCount={listData?.data?.length}
                />
              </AdvanceTableWrapper>
            )}
        </Card.Body>
      </Card>
    </>
  );
};

export default BillingDetails;
