import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '../../env/env';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().persistedReducer.user.accessToken;
      headers.set('authorization', `Bearer ${token}`);
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    getUser: builder.mutation({
      query: id => ({
        url: `users/${id}`,
        method: 'GET'
      }),
      transformResponse: response => response.data
    }),
    getUserQuery: builder.query({
      query: id => ({
        url: `users/${id}`,
        method: 'GET'
      }),
      transformResponse: response => response.data
    }),
    getCurrentUser: builder.query({
      query: () => ({
        url: `users/me`,
        method: 'GET'
      }),
      transformResponse: response => response.data
    }),
    deleteUser: builder.mutation({
      query: id => ({
        url: `users/${id}`,
        method: 'DELETE',
        body: {}
      }),
      transformResponse: () => 'No content success'
    }),
    getUsersListQuery: builder.query({
      query: params => ({
        url: `users`,
        method: 'GET',
        params: {
          ...(params.value &&
            params.value !== '' && {
              ['filters[0][field]']: 'query',
              ['filters[0][operator]']: 'icontains',
              ['filters[0][value]']: params.value
            }),
          offset: params.from,
          ...(params.type &&
            params.type !== '' && {
              ['filters[1][field]']: 'type',
              ['filters[1][operator]']: '=',
              ['filters[1][value]']: params.type,
              dir: 'ASC',
              order: 'name'
            }),
          ...(params.billingGroupId &&
            params.billingGroupId !== '' && {
              ['filters[1][field]']: 'billingGroupId',
              ['filters[1][operator]']: '=',
              ['filters[1][value]']: params.billingGroupId,
              dir: 'ASC',
              order: 'name'
            }),
          limit: params.limit,
          select: params.select
        }
      }),
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    }),
    getUsersListMutation: builder.mutation({
      query: params => ({
        url: `users`,
        method: 'GET',
        params: {
          ...(params.value &&
            params.value !== '' && {
              ['filters[0][field]']: 'query',
              ['filters[0][operator]']: 'icontains',
              ['filters[0][value]']: params.value
            }),
          offset: params.from,
          ...(params.type &&
            params.type !== '' && {
              ['filters[1][field]']: 'type',
              ['filters[1][operator]']: 'IN',
              ['filters[1][value]']: params.type
            }),
          ...(params.id &&
            params.id !== '' && {
              ['filters[2][field]']: 'id',
              ['filters[2][operator]']: '=',
              ['filters[2][value]']: params.id
            }),
          limit: params.limit,
          select: params.select
        }
      }),
      transformResponse: response => ({
        data: response.data,
        pagination: response.pagination
      })
    })
  })
});

export const {
  useGetUserMutation,
  useGetUserQueryQuery,
  useGetCurrentUserQuery,
  useGetUsersListQueryQuery,
  useGetUsersListMutationMutation,
  useDeleteUserMutation
} = userApi;
