import { useUpdateProductTypeMutation } from 'redux/endpoints/productTypesApi';
import useError from './useError';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const useProducts = () => {
  const { checkError } = useError();
  const [fetchedData, setFetchedData] = useState(null);
  const [
    updateProductType,
    { data: updateProductTypeData, error: updateProductTypeError }
  ] = useUpdateProductTypeMutation();

  useEffect(() => {
    if (updateProductTypeError) {
      checkError(updateProductTypeError);
    }
  }, [updateProductTypeError]);

  useEffect(() => {
    if (updateProductTypeData) {
      toast.success('Tipo de producto actualizado con éxito', {
        autoClose: 2000,
        position: 'top-center'
      });
      setFetchedData(updateProductTypeData);
    }
  }, [updateProductTypeData]);

  return {
    updateProductType,
    data: updateProductTypeData && fetchedData
  };
};

export default useProducts;
