import classNames from 'classnames';
import DropdownFilter from 'components/common/DropdownFilter';
import NavbarTopBillingGroupDashboard from 'components/navbar/top/NavBarTopBillingGroupDashboard';
import GroupPartnerTable from 'components/partner-dashboard/GroupPartnersTable';
import LendProductChart from 'components/partner-dashboard/LendProductChart';
import PartnerStats from 'components/partner-dashboard/PartnerStats';
import { getDateFromFilter, getMonthNameFromFilter } from 'helpers/utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Spinner } from 'react-bootstrap';
import {
  useGetBillingGroupDashboardQuery,
  useGetBillingGroupDashboardUnfilteredQuery
} from 'redux/endpoints/billingGroupsApi';
import { t } from 'i18next';

/* const columns = [
  {
    accessor: 'productType',
    Header: 'Tipo de producto',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { productType } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <Card.Text>
              {svgSelectorDetailed(productType.code)} {productType.name}
            </Card.Text>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Nombre del envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'id',
    Header: 'Id de envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <p className="mb-0 fs--1">{id}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Estado',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{subscriptionStatusBadge(status)}</h5>
          </div>
        </Flex>
      );
    }
  }
];
const productTypeColumns = [
  {
    accessor: 'name',
    Header: 'Nombre del envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, productType } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <Card.Text>
              {svgSelectorDetailed(productType.code)} {name}
            </Card.Text>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'id',
    Header: 'Id de envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <p className="mb-0 fs--1">{id}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Estado',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{subscriptionStatusBadge(status)}</h5>
          </div>
        </Flex>
      );
    }
  }
]; */

const BillingGroupDashboard = () => {
  const [currentFilter, setCurrentFilter] = useState(t('currentMonth'));
  const { data: dashboardData, isFetching } = useGetBillingGroupDashboardQuery(
    getDateFromFilter(currentFilter)
  );
  const { data: dashboardUnfilteredData } =
    useGetBillingGroupDashboardUnfilteredQuery();
  const user = useSelector(state => state.persistedReducer.user);

  /*  const performanceData = [
    {
      id: 0,
      title: 'Alto',
      color: 'primary',
      courses: 13,
      badge: {
        type: 'success',
        content: '2.1%',
        icon: 'caret-up'
      }
    },
    {
      id: 1,
      title: 'Medio',
      color: 'warning',
      courses: 10,
      badge: {
        type: 'primary',
        content: '3.5%',
        icon: 'caret-up'
      }
    },
    {
      id: 2,
      title: 'Bajo',
      color: 'success',
      courses: 7,
      badge: {
        type: 'secondary',
        content: '0.00%'
      }
    }
  ]; */

  return (
    <div className={classNames('content')}>
      <NavbarTopBillingGroupDashboard />
      <div className="px-4">
        <Card className="mt-3">
          <Card.Body className="d-flex flex-row justify-content-between">
            <div>
              <Card.Text
                className="fw-bold"
                style={{
                  color: '#0d6efd',
                  fontSize: '0.7rem',
                  lineHeight: '0.2'
                }}
              >{`Grupo`}</Card.Text>
              <Card.Text
                className="fw-bold"
                style={{ fontSize: '1.1rem', lineHeight: '0.3' }}
              >
                {user.name}
              </Card.Text>
            </div>
            <DropdownFilter
              currentFilter={currentFilter}
              filters={[
                t('currentMonth'),
                t('previousMonth'),
                t('currentYear'),
                t('previousYear')
              ]}
              icon="filter"
              handleFilter={e => {
                setCurrentFilter(e);
              }}
            />
          </Card.Body>
        </Card>
        <Card className="mt-3">
          <Card.Header className="bg-secondary bg-opacity-10">
            <Card.Text className="fw-bold">{`${t(
              'activity'
            )} ${getMonthNameFromFilter(currentFilter)}`}</Card.Text>
          </Card.Header>
          {dashboardData && !isFetching ? (
            <PartnerStats data={dashboardData.items} />
          ) : (
            <Card>
              <Card.Body className="py-5 py-sm-3">
                <Row className="g-5 g-sm-0 justify-content-center">
                  <Spinner />
                </Row>
              </Card.Body>
            </Card>
          )}
        </Card>
        {dashboardUnfilteredData ? (
          <LendProductChart data={dashboardUnfilteredData} />
        ) : (
          <Card className="mt-3 align-items-center">
            <Card.Body>
              <Spinner />
            </Card.Body>
          </Card>
        )}
        <Row className="g-3 mt-3">
          {/*  <Col>
            {dashboardData ? (
              <ActiveCenters
                data={aggregateDataByPartnerId(dashboardData.items)}
              />
            ) : (
              <Card className="h-100 justify-content-center align-items-center">
                <Spinner />
              </Card>
            )}
          </Col> */}
          {/*  <Col>
            <PartnerPerformanceChart data={performanceData} />
          </Col> */}
        </Row>
        <Card className="mt-3">
          {dashboardData ? (
            <GroupPartnerTable
              currentFilter={currentFilter}
              tableData={dashboardData}
            />
          ) : (
            <Card className="mt-3 align-items-center">
              <Card.Body>
                <Spinner />
              </Card.Body>
            </Card>
          )}
        </Card>
      </div>
    </div>
  );
};

export default BillingGroupDashboard;
