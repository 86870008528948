import CardDropdown from 'components/common/CardDropdown';
import ProductModal from 'components/product/ProductModal';
import UpdateModal from 'components/product/UpdateModal';
import useError from 'hooks/useError';
import useProducts from 'hooks/useProducts';
import React, { useEffect, useState } from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useGetProductGroupsQuery } from 'redux/endpoints/productGroups';
import {
  useCreateProductTypeMutation,
  useGetProductTypesQuery
} from 'redux/endpoints/productTypesApi';

const ProductTypes = () => {
  const { checkError } = useError();
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [typeData, setTypeData] = useState(null);
  const { updateProductType, data: updateProductTypeData } = useProducts();
  const [
    createProductType,
    { data: productTypeData, error: productTypeError }
  ] = useCreateProductTypeMutation();
  const { data, error } = useGetProductGroupsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );
  const {
    data: productTypes,
    error: productTypesError,
    refetch
  } = useGetProductTypesQuery({}, { refetchOnMountOrArgChange: true });

  if (error) {
    checkError(error);
  }

  if (productTypesError) {
    checkError(productTypesError);
  }

  useEffect(() => {
    if (productTypeData) {
      toast.success('Tipo de producto creado con éxito');
      setShowModal(false);
      refetch();
    }
  }, [productTypeData]);

  useEffect(() => {
    if (productTypeError) {
      checkError(productTypeError);
    }
  }, [productTypeError]);

  useEffect(() => {
    if (updateProductTypeData) {
      setShowUpdateModal(false);
      refetch();
    }
  }, [updateProductTypeData]);

  return (
    <>
      <Card className="container p-3">
        <Card.Header className="d-flex align-items-center flex-row justify-content-between mt-2">
          <Card.Title as="h2">Product Type</Card.Title>
          <Button onClick={() => setShowModal(true)} variant="primary">
            Crear tipo
          </Button>
        </Card.Header>
        <Card.Body className="d-flex flex-wrap row justify-content-between">
          {productTypes?.map(productType => (
            <Card
              style={{ backgroundColor: 'rgb(249,	250, 253)' }}
              key={productType.id}
              className="m-3 col-md-3"
            >
              <Card.Header className="d-flex flex-row justify-content-between align-items-center border-bottom px-3 pt-3 m-0">
                <Card.Title className="m-0" as="h6">
                  {productType.name}
                </Card.Title>
                <CardDropdown backGroundColor="white" iconClassName="fs--1">
                  <Dropdown.Item
                    className="text-danger"
                    onClick={() => {
                      setTypeData(productType);
                      setShowUpdateModal(true);
                    }}
                  >
                    Editar
                  </Dropdown.Item>
                </CardDropdown>
              </Card.Header>
              <Card.Body className="d-flex flex-column">
                <Card.Text>
                  Código: <strong>{productType.code}</strong>
                </Card.Text>
                <Card.Text>
                  Grupo de producto: <strong>{productType.group.name}</strong>
                </Card.Text>
                <Card.Text>
                  Tamaño: <strong>{productType.size}</strong>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Card.Body>
        <ProductModal showModal={showModal} setter={setShowModal} />
      </Card>
      <ProductModal
        showModal={showModal}
        setter={setShowModal}
        productGroups={data}
        createProductType={createProductType}
      />
      <UpdateModal
        showModal={showUpdateModal}
        setter={setShowUpdateModal}
        data={typeData}
        updateProductType={updateProductType}
        productGroups={data}
      />
    </>
  );
};

export default ProductTypes;
