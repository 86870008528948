import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from 'env/env';

export const pricesApi = createApi({
  reducerPath: 'pricesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV.ENDPOINT}prices`,
    prepareHeaders: (headers, { getState }) => {
      const { accessToken } = getState().persistedReducer.user;
      headers.set('authorization', `Bearer ${accessToken}`);
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  endpoints: builder => ({
    getPrices: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: '',
          params: {
            dir: 'ASC',
            order: 'name',
            limit: 1000
          }
        };
      },
      transformResponse: response => response.data
    }),
    getPriceById: builder.query({
      query: id => {
        return {
          method: 'GET',
          url: `/${id}`
        };
      },
      transformResponse: response => response.data
    }),
    createNewPrice: builder.mutation({
      query: body => {
        return {
          method: 'POST',
          url: '',
          body
        };
      },
      transformResponse: () => 'No data response'
    }),
    updatePrice: builder.mutation({
      query: ({ body, id }) => {
        return {
          method: 'PUT',
          url: `/${id}`,
          body
        };
      },
      transformResponse: () => 'No data response'
    })
  })
});

export const {
  useGetPricesQuery,
  useGetPriceByIdQuery,
  useCreateNewPriceMutation,
  useUpdatePriceMutation
} = pricesApi;
