import useError from 'hooks/useError';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, FormCheck, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useEditBillingGroupMutation,
  useGetSingleBillingGroupQuery
} from 'redux/endpoints/billingGroupsApi';

const EditBillingGroup = () => {
  const id = window.location.pathname.replace('/edit-billing/', '');
  const { checkError } = useError();
  const navigate = useNavigate();
  const { data, isLoading, error } = useGetSingleBillingGroupQuery(id, {
    refetchOnMountOrArgChange: true
  });
  const [editBillingGroup, { data: editData, error: editError }] =
    useEditBillingGroupMutation();

  const [formData, setFormData] = useState({});

  if (error) {
    checkError(error);
  }

  const handleFormChange = ({ target }) => {
    if (target.name === 'billingCycle') {
      setFormData({
        ...formData,
        [target.name]: parseInt(target.value)
      });
    } else if (target.name === 'proformaInvoice') {
      setFormData({
        ...formData,
        [target.name]: !formData.proformaInvoice
      });
    } else {
      setFormData({ ...formData, [target.name]: target.value });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const request = { id: id, body: formData };
    editBillingGroup(request);
  };

  useEffect(() => {
    if (editError) {
      checkError(editError);
    }
  }, [editError]);

  useEffect(() => {
    if (editData) {
      toast.success('Grupo de facturación editado correctamente');
      navigate(`/billing-group/${id}`);
    }
  }, [editData]);

  return (
    <Form className="p-2" onSubmit={handleSubmit}>
      <Card>
        <Card.Header>
          <Card.Title className="py-2 text-primary" as="h3">
            Contacto
          </Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Col lg={12} className="d-flex flex-row justify-content-between">
            <Col lg={6} className="d-flex flex-column me-1">
              <Form.Group className="mb-3" controlId="Name">
                <Form.Label>Nombre</Form.Label>
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <Form.Control
                    required
                    defaultValue={data ? data?.data.name : ''}
                    type="text"
                    name="name"
                    placeholder="Nombre"
                    onChange={handleFormChange}
                  />
                )}
              </Form.Group>
            </Col>
            <Col lg={6} className="d-flex flex-column">
              <Form.Group controlId="formContactPhone">
                <Form.Label>Teléfono</Form.Label>
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <Form.Control
                    required
                    defaultValue={
                      data
                        ? data?.data.phone === undefined ||
                          data?.data.phone === null
                          ? ''
                          : data?.data.phone
                        : ''
                    }
                    name="phone"
                    type="text"
                    placeholder="Teléfono"
                    onChange={handleFormChange}
                  />
                )}
              </Form.Group>
            </Col>
          </Col>

          <Col lg={12} className="d-flex flex-row justify-content-between">
            <Col lg={6} className="d-flex flex-column me-1">
              <Form.Group controlId="formContactEmail">
                <Form.Label>Email</Form.Label>
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <Form.Control
                    defaultValue={data ? data?.data.email : ''}
                    name="email"
                    type="email"
                    placeholder="email"
                    onChange={handleFormChange}
                  />
                )}
              </Form.Group>
            </Col>
            <Col lg={6} className="d-flex flex-column">
              <Form.Group controlId="formContactPassword">
                <Form.Label id="formContactPassword">Contraseña</Form.Label>
                {isLoading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Contraseña"
                    onChange={handleFormChange}
                  />
                )}
              </Form.Group>
            </Col>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header className="pb-0">
          <Card.Title className="py-2 text-primary fs-2" as="h3">
            Preferencias de facturación
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group
            className="mb-3 d-flex flex-row"
            controlId="proformaInvoice"
          >
            <FormCheck
              className="align-self-center me-2"
              type="switch"
              defaultChecked={data?.data.billingMode}
              onClick={e => {
                setFormData({
                  ...formData,
                  proformaInvoice: e.target.checked
                });
              }}
              name="proformaInvoice"
            />
            <Form.Label className="text-primary m-0 ms-1">
              Factura proforma
            </Form.Label>
          </Form.Group>
          <Col lg={6} className="ms-4">
            <Card.Text className="text-primary">
              Se enviará una pre factura para que el centro correspondiente
              añada el número de pedido
            </Card.Text>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Body>
          <Form.Group className="mb-3 d-flex flex-row" controlId="grouped">
            {data && (
              <FormCheck
                className="align-self-center me-2"
                type="radio"
                defaultChecked={data?.data.billingMode == 'grouped'}
                onChange={() =>
                  setFormData({
                    ...formData,
                    billingMode: 'grouped'
                  })
                }
                name="billingMode"
              />
            )}
            <Form.Label className="text-primary m-0 ms-1">
              Facturas conjuntas a la Cuenta de facturación
            </Form.Label>
          </Form.Group>
          <Col lg={6} className="ms-4 mb-3">
            <Card.Text className="text-primary">
              Se enviará una factura al mail indicado en el apartado Detalles
              con el desglose de usos de cada unos de los centros de coste
              asociados.
            </Card.Text>
          </Col>
          <Form.Group className="mb-3 d-flex flex-row" controlId="billingMode">
            {data && (
              <FormCheck
                className="align-self-center me-2"
                type="radio"
                defaultChecked={data?.data.billingMode === 'individual'}
                onChange={() =>
                  setFormData({
                    ...formData,
                    billingMode: 'individual'
                  })
                }
                name="billingMode"
              />
            )}
            <Form.Label className=" text-primary m-0 ms-1">
              Facturas individuales
            </Form.Label>
          </Form.Group>
          <Col lg={6} className="ms-4">
            <Card.Text className="text-primary">
              Se enviará una factura a cada uno de los centros. Únicamente
              aparecerán los usos correspondientes al centro.
            </Card.Text>
          </Col>
        </Card.Body>
      </Card>
      <Card className="mt-4">
        <Card.Header className="pb-0">
          <Card.Title className="py-2 text-primary fs-2" as="h3">
            Ciclo de facturación
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group controlId="billingCycle">
            <Form.Label className=" text-primary m-0 ms-1 mb-2">
              Plazo de vencimiento
            </Form.Label>
            <Col lg={4}>
              {isLoading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Form.Select name="billingCycle" onChange={handleFormChange}>
                  <option value={30}>Vencimiento a 30 días</option>
                  <option value={60}>Vencimiento a 60 días</option>
                  <option value={90}>Vencimiento a 90 días</option>
                </Form.Select>
              )}
            </Col>
          </Form.Group>
        </Card.Body>
        <Card.Footer>
          <Button
            size="lg"
            style={{ width: '150px' }}
            variant="primary"
            type="submit"
          >
            Guardar
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default EditBillingGroup;
