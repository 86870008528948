import Flex from 'components/common/Flex';
import { Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React from 'react';
import { businessTypeBadge } from 'components/utilities/badgeSelector';
import timeConverter from 'components/utilities/timeConverter';
import CardDropdown from 'components/common/CardDropdown';

export const columns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return (
        <Link to={`/partner-details/${id}`}>
          <Flex alignItems="center">
            <div
              style={{ maxWidth: '10vw', overflow: 'hidden' }}
              className="flex-1"
            >
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: rowData => {
      const { email } = rowData.row.original;
      return (
        <Flex>
          <Card.Text style={{ maxWidth: '15vw', overflow: 'hidden' }}>
            {email}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'phone',
    Header: 'Teléfono',
    Cell: rowData => {
      const { phone } = rowData.row.original;
      return (
        <Flex>
          <Card.Text
            style={{
              maxWidth: '10vw',
              overflow: 'hidden',
              marginLeft: '8px',
              marginRight: '8px'
            }}
          >
            {phone}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'address',
    Header: 'Dirección',
    Cell: rowData => {
      const { address } = rowData.row.original;
      return (
        <Flex>
          <Card.Text style={{ maxWidth: '15vw', overflow: 'hidden' }}>
            {address}
          </Card.Text>
        </Flex>
      );
    }
  },
  {
    accessor: 'type',
    Header: 'Tipo de negocio',
    Cell: rowData => {
      const { type } = rowData.row.original;
      return <Flex className="me-2 ms-2">{businessTypeBadge(type[0])}</Flex>;
    }
  },
  {
    accessor: 'createdAt',
    Header: 'Fecha de creación',
    Cell: rowData => {
      const { createdAt } = rowData.row.original;
      return <Flex className="ms-3">{timeConverter(createdAt)}</Flex>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item href={`/edit-partner/${id}`}>Edit</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];
