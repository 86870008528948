import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { doLogout } from 'redux/reducers/loginReducer';
import { cleanUser } from 'redux/reducers/userReducer';
import { useNavigate } from 'react-router-dom';

const useError = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkError = error => {
    if (error.status === 401) {
      dispatch(doLogout());
      dispatch(cleanUser());
      navigate('/login');
      toast.error(
        error.data?.message ? error.data.message : 'Sesión expirada',
        {
          position: 'top-center'
        }
      );
    } else {
      toast.error(
        error.data?.message
          ? error.data?.message
          : 'Error al obtener los datos',
        { position: 'top-center' }
      );
    }
  };
  return { checkError };
};
export default useError;
