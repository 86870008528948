import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import PartnersTableHeader from 'components/partners-table/PartnersTable';
import StockStats from 'components/stock-reports/StockStats';
import { subscriptionStatusBadge } from 'components/utilities/badgeSelector';
import { svgSelectorDetailed } from 'components/utilities/svgSelector';
import timeConverter from 'components/utilities/timeConverter';
import useError from 'hooks/useError';
import useProductTypes from 'hooks/useProductTypes';
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Spinner } from 'react-bootstrap';
import { useGetStockReportQuery } from 'redux/endpoints/stockReportsApi';

const columns = [
  {
    accessor: 'productType',
    Header: 'Tipo de producto',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { productType } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <Card.Text>
              {svgSelectorDetailed(productType.code)} {productType.name}
            </Card.Text>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'name',
    Header: 'Nombre del envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'id',
    Header: 'Id de envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <p className="mb-0 fs--1">{id}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Estado',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{subscriptionStatusBadge(status)}</h5>
          </div>
        </Flex>
      );
    }
  }
];
const productTypeColumns = [
  {
    accessor: 'name',
    Header: 'Nombre del envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, productType } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <Card.Text>
              {svgSelectorDetailed(productType.code)} {name}
            </Card.Text>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'id',
    Header: 'Id de envase',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { id } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <p className="mb-0 fs--1">{id}</p>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Estado',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { status } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{subscriptionStatusBadge(status)}</h5>
          </div>
        </Flex>
      );
    }
  }
];

const StockReportDetails = () => {
  const id = window.location.pathname.replace('/stock-report/', '');
  const [report, setReport] = useState();
  const [productTypes, setProductTypes] = useState([]);
  const { checkError } = useError();
  const {
    data: listData,
    isLoading: listIsLoading,
    error: listError
  } = useGetStockReportQuery(id);
  const { data } = useProductTypes();

  useEffect(() => {
    if (data) {
      console.log(data);
      setProductTypes(data);
    }
  }, [data]);
  useEffect(() => {
    if (listData) {
      setReport(listData);
    }
  }, [listData]);

  if (listError) {
    checkError(listError);
  }

  return (
    <>
      <Card>
        <Card.Header
          className={
            listIsLoading
              ? 'd-flex justify-content-center fw-bold w-100 fs-2 p-0'
              : 'fw-bold w-100 fs-2 p-0'
          }
        >
          {listIsLoading && <Spinner animation="border" variant="primary" />}
        </Card.Header>
      </Card>
      <Card className="mt-3">
        <Card.Header className="d-flex flex-row justify-content-between">
          <Card.Title className="mt-2">{`Reporte detallado > ${
            report?.partner.name
          } > ${timeConverter(report?.createdAt)}`}</Card.Title>
          <PartnersTableHeader table />
        </Card.Header>
        <Card.Body
          className="p-2 border-top"
          style={{ backgroundColor: 'rgb(249,	250, 253)' }}
        >
          {' '}
          <StockStats report={report}></StockStats>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header className="d-flex flex-row justify-content-between">
          <Card.Title className="mt-2">
            Resultados por tipo de producto
          </Card.Title>
        </Card.Header>
        <Card.Body
          className="p-2 border-top"
          style={{ backgroundColor: 'rgb(249,	250, 253)' }}
        >
          <Accordion>
            {productTypes.map((productType, index) => {
              const containerQuantity =
                report?.scannedContainersRealTime.filter(
                  container => container.productTypeId === productType.id
                ).length;
              return (
                containerQuantity > 0 && (
                  <Accordion.Item key={index} eventKey={index}>
                    <Accordion.Header>{`${productType.name} (${containerQuantity})`}</Accordion.Header>
                    <Accordion.Body>
                      <AdvanceTableWrapper
                        columns={productTypeColumns}
                        data={report.scannedContainersRealTime.filter(
                          container =>
                            container.productTypeId === productType.id
                        )}
                        pagination
                        perPage={10}
                      >
                        <AdvanceTable
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-5'
                          }}
                        />
                        <AdvanceTableFooter
                          navButtons
                          table
                          rowInfo
                          rowCount={report.scannedContainersRealTime.length}
                        />
                      </AdvanceTableWrapper>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              );
            })}
          </Accordion>
        </Card.Body>
      </Card>
      <Card className="my-3">
        <Card.Header
          className="p-3 d-flex align-items-center justify-content-between"
          style={{ width: '100%' }}
        >
          <Card.Title className="mt-3 ms-3">Stock Faltante</Card.Title>
        </Card.Header>

        <Card.Body
          className="mt-3"
          style={{ backgroundColor: 'rgb(249,	250, 253)' }}
        >
          {report?.missingContainersRealTime?.length > 0 ? (
            <AdvanceTableWrapper
              columns={columns}
              data={report.missingContainersRealTime}
              pagination
              perPage={10}
            >
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  striped: true,
                  className: 'fs--1 mb-5'
                }}
              />
              <AdvanceTableFooter
                navButtons
                table
                rowInfo
                rowCount={report.missingContainersRealTime.length}
              />
            </AdvanceTableWrapper>
          ) : (
            <Card.Text>No hay suscripciones asignadas</Card.Text>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default StockReportDetails;
