import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import IconItem from 'components/common/icon/IconItem';

const StatsItem = ({ stat }) => {
  const { icon, color, title, amount } = stat;
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        className="mb-3 position-static"
      >
        <IconItem
          tag="div"
          icon={icon}
          bg={`soft-${color}`}
          color={color}
          size="sm"
          iconClass="fs--2"
          className="me-2 shadow-none"
        />
        <h6 className="mb-0 flex-1">{title}</h6>
        {/*   <div>
          <CardDropdown />
        </div> */}
      </Flex>
      <Flex>
        <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{amount}</p>
      </Flex>
    </>
  );
};

const StockStats = ({ report }) => {
  const statsData = [
    {
      id: 1,
      title: 'Stock Teórico',
      amount:
        report?.scannedContainersRealTime.length +
        report?.missingContainersRealTime.length,
      icon: 'user',
      color: 'info'
    },
    {
      id: 2,
      title: 'Stock Reportado',
      amount: report?.scannedContainersRealTime.length,
      icon: 'phone',
      color: 'success'
    },
    {
      id: 3,
      title: 'Stock Faltante',
      amount: report?.missingContainersRealTime.length,
      icon: 'bolt',
      color: 'danger'
    }
  ];
  return (
    <Card>
      <Card.Body>
        <Row>
          {report &&
            statsData.map((stat, index) => (
              <Col
                lg={4}
                key={stat.id}
                className={classNames({
                  'border-bottom border-lg-0 border-lg-end':
                    index !== statsData.length - 1,
                  'pb-3 pb-lg-0': index === 0,
                  'py-3 py-lg-0': index === 1,
                  'pt-3 pt-lg-0': index === 2
                })}
              >
                <StatsItem stat={stat} />
              </Col>
            ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

StatsItem.propTypes = {
  stat: PropTypes.shape({
    amount: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};

StockStats.propTypes = {
  report: PropTypes.shape(StatsItem.propTypes.stat)
};

export default StockStats;
